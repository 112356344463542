import { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';

import Loader from 'components/Loader';

import {
  DateGroup,
  MutationType, SortDirection,
  WorksheetSignalMessageEventTypes,
  WorksheetStores,
} from './Models/Enums';
import {
  useAllWorksheets,
  useNukeWorksheet,
  useUpdateLocalWorksheetList,
} from './Services/WorksheetHooks';
import {
  WorksheetAction,
  WorkSheetName,
} from './Templates';

import { getValueCollection } from 'helpers/Utils/enum';
import { stripQuotes } from 'helpers/Utils/string';

import { eventBus } from 'server/EventBus';

import type { WorksheetResponse, WorksheetResponseWithGroup } from './Models/WorksheetResponse';
import type { KeyedMutator } from 'swr';

import styles from './Worksheets.module.scss';

interface IWorksheetsHistoryProps {
  activeWorksheetId: string | null | undefined;
  setActiveWorksheetId: (arg: string | null | undefined) => void;
  store: WorksheetStores;
  currentRoute: string;
  preventDelete?: boolean;
  setActiveWorksheetName?: (arg: string | null | undefined) => void;
  workSheetResultsFilter?: (item: WorksheetResponse) => boolean;
  worksheetUpdatedHandler?: (
    updatedWorksheet: Partial<WorksheetResponse>,
    storedWorksheets: WorksheetResponse[],
    updateSheetsLocally: KeyedMutator<WorksheetResponse[]>,
    reloadWorksheetsList: KeyedMutator<WorksheetResponse[]>
  ) => void;
  getSubtitle?: (item: WorksheetResponse) => ReactNode;
}

export const WorksheetsHistory = (props: IWorksheetsHistoryProps): ReactElement => {
  const {
    activeWorksheetId,
    setActiveWorksheetId,
    setActiveWorksheetName,
    store,
    currentRoute,
    preventDelete = false,
    workSheetResultsFilter,
    worksheetUpdatedHandler,
    getSubtitle,
  } = props;

  const navigate: NavigateFunction = useNavigate();

  const workSheetResultsMap = (item: WorksheetResponse): WorksheetResponse =>
    ({ ...item, name: stripQuotes(item.name) });

  const {
    sheets,
    error,
    isLoading,
    mutate: reloadWorksheets,
  } = useAllWorksheets(store, undefined, workSheetResultsFilter, SortDirection.Descending, workSheetResultsMap);
  const { updateSheets } = useUpdateLocalWorksheetList(store);
  const { deleteWorksheet } = useNukeWorksheet(store);

  const [expandedRows, setExpandedRows] = useState<WorksheetResponse[]>([]);
  const [contextMenuItems, setContextMenuItems] = useState<MenuItem[]>([]);

  const cm = useRef(null);
  const dt = useRef<DataTable<WorksheetResponseWithGroup[]>>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const sheetsWithGroups = useMemo((): WorksheetResponseWithGroup[] | undefined => {
    const now = DateTime.utc();
    return sheets?.map(sheet => {
      const createdDate = DateTime.fromISO(`${ sheet.createdUtc }`, { zone: 'utc' });
      let group: DateGroup;

      switch (true) {
      case createdDate.hasSame(now, 'day'):
        group = DateGroup.Today;
        break;
      case createdDate.hasSame(now.minus({ days: 1 }), 'day'):
        group = DateGroup.Yesterday;
        break;
      case createdDate >= now.minus({ days: 7 }):
        group = DateGroup.Previous7Days;
        break;
      case createdDate >= now.minus({ days: 30 }):
        group = DateGroup.Previous30Days;
        break;
      default:
        group = DateGroup.Older;
        break;
      }

      return { ...sheet, group };
    });
  }, [sheets]);

  const getFirstItemsInGroups = (data: WorksheetResponseWithGroup[] ): WorksheetResponseWithGroup[] => {
    const firstItemsInGroups: WorksheetResponseWithGroup[] = [];
    getValueCollection(DateGroup).forEach(i => {
      const firstItemInGroup = data.find(c => c.group === i.key);
      firstItemInGroup && firstItemsInGroups.push(firstItemInGroup);
    });

    return firstItemsInGroups;
  };

  useEffect(() => {
    if (!sheetsWithGroups) {
      return;
    }

    setExpandedRows(getFirstItemsInGroups(sheetsWithGroups));
  }, [sheetsWithGroups]);

  useEffect(() => {
    const onWorksheetUpdated = (
      payload: CustomEvent<Partial<WorksheetResponse>>
    ): void => {
      if (!sheets) {
        return;
      }

      if (worksheetUpdatedHandler) {
        worksheetUpdatedHandler(
          payload.detail,
          sheets,
          updateSheets,
          reloadWorksheets,
        );
      } else {
        const isSheetExists = Boolean(
          sheets.find(
            sheet => sheet.worksheetId === payload.detail.worksheetId
          )
        );
        const isDeleted = payload.detail.isDeleted;

        if (isSheetExists) {
          if (!isDeleted) {
            updateSheets(
              sheets.map(s =>
                s.worksheetId === payload.detail.worksheetId
                  ? { ...s, ...payload.detail }
                  : s
              ),
              { revalidate: false }
            );
          } else {
            updateSheets(
              sheets.filter(
                s => s.worksheetId !== payload.detail.worksheetId
              ),
              { revalidate: false }
            );
          }
        } else {
          reloadWorksheets();
        }
      }
    };
    
    eventBus.on(
      WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
      onWorksheetUpdated
    );
    eventBus.on(
      WorksheetSignalMessageEventTypes.WORKSHEET_DELETED,
      onWorksheetUpdated
    );

    return ():void => {
      eventBus.remove(
        WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
        onWorksheetUpdated
      );
      eventBus.remove(
        WorksheetSignalMessageEventTypes.WORKSHEET_DELETED,
        onWorksheetUpdated
      );
    };
    // eslint-disable-next-line
  }, [sheets]);

  useEffect(() => {
    const updatedWorksheet = sheets?.find(
      sheet => sheet.worksheetId === activeWorksheetId
    );
    if (setActiveWorksheetName) {
      setActiveWorksheetName(updatedWorksheet?.name);
    }
  }, [activeWorksheetId, sheets, setActiveWorksheetName]);

  const updateRowTabIndexes = (): void => {
    if (dt.current) {
      const table = dt.current.getTable();

      // DataTable sets tabindex="0" attribute on clicked row by default.
      // Restore proper tabindexes once the row is selected from the outside.
      table?.querySelector('[tabindex = "0"]')?.setAttribute('tabindex', '-1');
      table?.querySelector(`.${ styles.active }`)?.setAttribute('tabindex', '0');
    }
  };

  useEffect(() => {
    updateRowTabIndexes();
    // eslint-disable-next-line
  }, [dt.current]);

  const handleItemClick = (id: string): void => {
    navigate(`${ currentRoute }/${ id }`);
    updateSheets(
      sheets?.map(s =>
        s.worksheetId === id ? {...s, currentlyOpen: true} : s
      ),
      {revalidate: false}
    );
    setActiveWorksheetId(id);
  };

  const handleDeleteWorksheet = async (worksheetId: string):Promise<void> => {
    if (!dt.current) {
      return;
    }

    await deleteWorksheet({store, worksheetId});

    // handleRowInteraction(dt.current.getTable().querySelector('.hover'));
    dt.current.getTable().querySelector('.hover')?.classList.remove('hover');

    eventBus.dispatch(WorksheetSignalMessageEventTypes.WORKSHEET_REMOVED_FROM_LIST, {
      worksheetId,
      mutation: MutationType.Delete,
    });

    // If it's currently active WS -> check and navigate to first available element
    if (sheets && sheets.length > 0 &&
      worksheetId === activeWorksheetId) {
      const available = sheets?.filter(s =>
        !s.isDeleted && s.worksheetId !== worksheetId);

      if (available?.length > 0) {
        handleItemClick(available[0].worksheetId);
      }

      updateSheets(available, { revalidate: false });
    }
  };

  const handleRenameWorksheet = (worksheetId: string): void => {
    if (!dt.current) {
      return;
    }

    eventBus.dispatch(
      WorksheetSignalMessageEventTypes.WORKSHEET_START_NAME_CHANGE,
      {worksheetId}
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <>Something went wrong when loading history</>;
  }

  return (
    <div className={ styles.panel }>
      { sheetsWithGroups && (
        <>
          <ContextMenu
            ref={ cm }
            model={ contextMenuItems } 
            appendTo={ isMobile ? 'self' : undefined }
          />
          <DataTable
            value={ sheetsWithGroups }
            ref={ dt }
            groupRowsBy='group'
            rowGroupMode='subheader'
            sortMode='single'
            sortField='createdUtc'
            sortOrder={-1}
            emptyMessage=' '
            className={ clsx(
              'grow-to-fill',
              'row--no-border',
              'row--narrow',
              styles.worksheettable,
              styles.historyTable,
            ) }
            rowClassName={ (data: WorksheetResponse) =>
              data.worksheetId === activeWorksheetId ? styles.active : ''
            }
            expandableRowGroups
            showHeaders={ false }
            selectionMode='single'
            selectOnEdit={ false }
            scrollable
            style={{ height: '100%' }}
            onRowClick={ () => {
              // Wait until table is re-rendered and set proper tabIndexes.
              // That is helpful when ex. clicking inside the row but
              // outside of the `name` column (where 'handleItemClick' is not called).
              setTimeout(updateRowTabIndexes, 0);
            } }
            onRowToggle={ e => {
              setExpandedRows(e.data as WorksheetResponse[]);
            } }
            expandedRows={ expandedRows }
            expandedRowIcon='iconoir-nav-arrow-down icon--small icon--secondary'
            collapsedRowIcon='iconoir-nav-arrow-right icon--small icon--secondary'
            rowGroupHeaderTemplate={ data => (
              <div>{ data.group }</div>
            ) }
            rowGroupFooterTemplate={ <></> }
          >
            <Column
              className={styles.name}
              header='Worksheet'
              body={ (data, config) => (
                <WorkSheetName
                  data={ data }
                  config={ config }
                  store={ store }
                  handler={ handleItemClick }
                  getSubtitle={ getSubtitle }
                />
              ) }
            ></Column>
            <Column
              body={ data => (
                <WorksheetAction
                  contextMenu={ cm.current }
                  setMenuItems={ setContextMenuItems }
                  data={ data }
                  actions={ {
                    deleteSheet: handleDeleteWorksheet,
                    renameSheet: handleRenameWorksheet,
                  } }
                  preventDelete={ preventDelete }
                  preventDeleteByNonOwners={ true }
                  preventSharing={ true }
                />
              ) }
            />
          </DataTable>
        </>
      ) }
    </div>
  );
};

export default WorksheetsHistory;
