import React, { useState, useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import useSWR from 'swr';
import clsx from 'clsx';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useMountEffect, useUnmountEffect } from 'primereact/hooks';

import { useSignalR } from 'App';

import { getValueCollection } from 'helpers/Utils/enum';

import SecondaryNavigation from 'components/SecondaryNavigation';
import ToggleSwitch from 'components/ToggleSwitch';
import ErrorToastService from 'components/Errors/ErrorToast/Services';
import {
  useSaveUserSetting,
  useLoadUserSettings,
  useLoggedInUser,
} from 'components/OBXUser/Services/ProfileHooks';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { getAvailableRatesRoutes } from 'components/TopMenu/Models/TopMenuRoutes';

import NotificationSubscription from './Components/NotificationSubscription';
import { AvailableRateGridsResponse } from './Models/RateGridResponse';
import { RatesGridSignalRMessages } from './Models/Enums';
import RateGridTable from './Components/RatesGridDataTable';
import { RateGridApi } from './Services/RateGridsAPI';
import TertiaryNavigation from './Components/TertiaryNavigation';

import './RateGrids.scss';

const AUDIT_DATA_SETTING: string = 'rate-grid-audit-visibility';

export function RateGrids(args: { items: any }) {
  const { items } = args;
  const { grid } = useParams();
  const navigate: NavigateFunction = useNavigate();
  const { signal } = useSignalR();

  const { trigger } = useSaveUserSetting();
  const { getSetting } = useLoadUserSettings();

  const { data: availableGrids, error } = useSWR<
    Pick<AvailableRateGridsResponse, 'id' | 'name'>[]
  >('rategrids', RateGridApi.getAvailableGrids);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const { obxuser } = useLoggedInUser();

  const [selectedRateGrid, setSelectedRateGrid] =
    useState<AvailableRateGridsResponse | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [showAuditData, setShowAuditData] = useState<boolean>(
    getSetting(AUDIT_DATA_SETTING) ?? false
  );

  const lastVisitedGrid = getSetting(UISettings.LAST_VISITED_RATE_GRID) as
    | AvailableRateGridsResponse
    | undefined;

  useMountEffect(() => {
    console.log('mounted');
    //	Set the signalR socket up to transpose inocming messages into eventBus events
    getValueCollection(RatesGridSignalRMessages).map(r =>
      signal.dispatchEventOnMessage(r.key)
    );
  });

  useUnmountEffect(() => {
    //	Tear down the publishing of rates grids events as we dont need
    //	them anymore
    console.log('un-mounted');
  });

  useEffect(() => {
    trigger({
      setting: UISettings.AUDIT_DATA_SETTING,
      data: showAuditData,
    });
  }, [showAuditData, trigger]);

  const switchDisplayedRate = (grid: any) => {
    // cache.delete(selectedRateGrid?.id ?? '');

    setSelectedRateGrid(grid);
    navigate(`/rates/rate-grids/${grid.name}`);

    if (grid.id !== selectedRateGrid?.id) {
      trigger({
        setting: UISettings.LAST_VISITED_RATE_GRID,
        data: grid,
      });
    }
  };

  useEffect(() => {
    if (!grid || !availableGrids) return;

    const gridmatch = availableGrids.find(i => i.name === grid);

    if (gridmatch) setSelectedRateGrid(gridmatch as AvailableRateGridsResponse);
  }, [grid, availableGrids]);

  //  runs when either data first loads
  React.useEffect(() => {
    if (!availableGrids) return;

    //  List of available grids has been returned from server
    //	So if there is a passed param but nothing selected
    //	then use the url parm to select the currently
    //	selected grid
    if (availableGrids && grid && !selectedRateGrid) {
      const gridmatch = availableGrids.find(i => i.name === grid);

      if (gridmatch) {
        setSelectedRateGrid(gridmatch as AvailableRateGridsResponse);
        return;
      }
    }

    let rateGrid = availableGrids[0];

    if (
      lastVisitedGrid &&
      availableGrids &&
      availableGrids.findIndex(item => item.id === lastVisitedGrid.id) >= 0
    ) {
      rateGrid = lastVisitedGrid;
    }

    if (availableGrids && !grid && !selectedRateGrid) {
      switchDisplayedRate(availableGrids[0]);
      return;
    }

    // eslint-disable-next-line
  }, [availableGrids]);

  const downloadFile = () => {
    if (!selectedRateGrid) return;

    setIsDownloading(true);

    axios({
      url: `rates/grid/download/${selectedRateGrid.id}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then(r => {
        setIsDownloading(false);
        fileDownload(
          r.data,
          'rates.xlsx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      })
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);

        // handle error state
        throw e;
      });
  };

  return (
    <>
      {error && <>Sorry - there has been a problem…</>}
      {availableGrids && (
        <>
          <nav className='tabbed-navigation-set__container'>
            <SecondaryNavigation
              items={getAvailableRatesRoutes(
                items,
                obxuser?.assignedSecurityRights
              )}
            />
            {availableGrids && (
              <TertiaryNavigation
                items={availableGrids as AvailableRateGridsResponse[]}
                changeCallback={switchDisplayedRate}
                currentSelection={selectedRateGrid}
                labelKey='name'
                isMobile={isTabletOrMobile}
              />
            )}
          </nav>
          <div className='rate-grids--functions'>
            <ToggleSwitch
              checked={showAuditData}
              callback={(e: any) => {
                setShowAuditData(e);
              }}
            >
              {!showAuditData ? 'Show' : 'Hide'} Audit Details
            </ToggleSwitch>
            <NotificationSubscription
              type='expiredrategrids'
              name={selectedRateGrid?.name ?? ''}
            />
            <Button
              size='small'
              icon='iconoir-download icon--small icon--ob-orange'
              loading={isDownloading}
              outlined
              onClick={e => downloadFile()}
            >
              Download
            </Button>
          </div>
          <main className='grow-to-fill'>
            <section className='grow-to-fill overflow--hidden'>
              {selectedRateGrid && (
                <RateGridTable
                  rateGridId={selectedRateGrid.id}
                  showAuditData={showAuditData}
                />
              )}
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default RateGrids;
