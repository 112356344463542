import { useMemo, type ChangeEvent } from 'react';
import { type DropdownChangeEvent } from 'primereact/dropdown';
import clsx from 'clsx';

import SwitchableUnitField from 'components/SwitchableUnitField';
import { replaceItemAt } from 'helpers/Utils/collections';
import { getFixedValue } from 'helpers/Utils/formatters';
import { PRICE_UNITS, QUANTITY_UNITS } from 'modules/Blotter/Models/Consts';

import type { SectionProps } from 'modules/Blotter/Models/SectionProps';
import type { TradeLeg, TradeQuantity, TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';

interface GeneralSectionProps extends SectionProps {
  leg: TradeLeg;
  legIndex: number;
  originalData: TradesDataResponse | null;
}

export function GeneralSection(props: GeneralSectionProps): JSX.Element {
  const { leg, legIndex, mutate, request, originalData, errors, shouldShowError } = props;

  const quantityOptions = useMemo(() => {
    if (!originalData || QUANTITY_UNITS.find(u => u === originalData.quantity.unit)) {
      return QUANTITY_UNITS;
    }

    // add original unit as an option
    return [...QUANTITY_UNITS, originalData.quantity.unit];
  }, [originalData]);

  const onQuantityChange = (value: string | number, propName: keyof TradeQuantity): void => {
    let legs;

    if (legIndex === 0) { // populate value to other legs if 1st leg has been changed
      legs = request.legs.map((leg) => ({
        ...leg,
        quantity: {
          ...leg.quantity,
          [propName]: value
        }
      }));
    } else { // update only changed leg
      legs = replaceItemAt(request.legs, {
        ...leg,
        quantity: {
          ...leg.quantity,
          [propName]: value
        }
      }, legIndex);
    }

    mutate({ legs }, `legs.${legIndex}.quantity.${propName}`);
  };

  const onQuantityAmountChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onQuantityChange(getFixedValue(e.target.value)!, 'amount');
  };

  const onQuantityUnitChange = (e: DropdownChangeEvent): void => {
    onQuantityChange(e.target.value, 'unit');
  }

  return <section>
    <div className='section--general'>
      <SwitchableUnitField
        key='trade__price'
        className='form-input__container trade__price'
        inputType='number'
        label='Price*'
        inputProps={{
          className: clsx({ 'p-invalid': shouldShowError(`legs.${legIndex}.price.amount`) }),
          showError: shouldShowError(`legs.${legIndex}.price.amount`),
          keyfilter: 'num',
          type: 'number',
          value: `${leg.price.amount ?? ''}`,
          placeholder: 'Enter Price',
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            mutate({
              legs: replaceItemAt(request.legs, {
                ...leg,
                price: {
                  ...leg.price,
                  amount: getFixedValue(e.target.value)
                }
              }, legIndex)
            }, `legs.${legIndex}.price.amount`);
          }
        }}
        dropdownProps={{
          className: clsx({
            'p-invalid': shouldShowError(`legs.${legIndex}.price.unit`)
          }),
          showError: shouldShowError(`legs.${legIndex}.price.unit`),
          error: errors?.[`legs.${legIndex}.price.unit`],
          onChange: (e: DropdownChangeEvent) => mutate({
            legs: replaceItemAt(request.legs, {
              ...leg,
              price: {
                ...leg.price,
                unit: e.target.value
              }
            }, legIndex)
          }, `legs.${legIndex}.price.unit`),
          options: PRICE_UNITS,
          value: leg.price.unit,
        }}
      />
      <SwitchableUnitField
        className='form-input__container'
        inputType='number'
        label='Quantity*'
        inputProps={{
          className: clsx({ 'p-invalid': shouldShowError(`legs.${legIndex}.quantity.amount`) }),
          showError: shouldShowError(`legs.${legIndex}.quantity.amount`),
          error: errors?.[`legs.${legIndex}.quantity.amount`],
          keyfilter: 'pnum',
          type: 'number',
          value: `${leg.quantity.amount ?? ''}`,
          placeholder: 'Enter Value',
          onChange: onQuantityAmountChange,
        }}
        dropdownProps={{
          className: clsx({ 'p-invalid': shouldShowError(`legs.${legIndex}.quantity.unit`) }),
          showError: shouldShowError(`legs.${legIndex}.quantity.unit`),
          error: errors?.[`legs.${legIndex}.quantity.unit`],
          onChange: onQuantityUnitChange,
          options: quantityOptions,
          value: leg.quantity.unit,
        }}
      />
    </div>
  </section>;
}