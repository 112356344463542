import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { RATES_ROUTE_CONFIG } from 'routes';

import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import { getFirstAvailableRatesRoute, hasUserAccess } from 'components/TopMenu/Models/TopMenuRoutes';

export default function Rates(): JSX.Element {
  const { obxuser } = useLoggedInUser();
  const outletContext = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect to 1st available item (Baltic, Vortexa, etc.) on entering the `/rates` page.
    // This component shouldn't be rendered at all if no access code has been found, but if you see
    // an empty page, see if '/rates' has all the possible `accessCode`s (for each sub-module) included.
    const firstAvailable = getFirstAvailableRatesRoute(RATES_ROUTE_CONFIG, obxuser?.assignedSecurityRights);
    const currentModule = RATES_ROUTE_CONFIG.find(route => location.pathname.includes(`/rates/${ route.path }`))

    // If route is /rates or user lost access to this submodule -> redirect to first available
    if (firstAvailable && (location.pathname === '/rates' ||
      !hasUserAccess(currentModule?.accessCode, obxuser?.assignedSecurityRights))) {
      navigate(`/rates/${ firstAvailable?.path ?? '' }`);
    }
    // eslint-disable-next-line
  }, [obxuser?.assignedSecurityRights]);

  // render children routes
  return <Outlet context={outletContext} />;
}
