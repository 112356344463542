import {
  Dispatch,
  forwardRef,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import { clsx } from 'clsx';
import { Options, SeriesOptionsType, XAxisOptions } from 'highcharts';
import * as Highcharts from 'highcharts';
import exportingModule from 'highcharts/modules/exporting';
import HighchartsReact, {
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';

import BorealisBar from 'components/BorealisBar';
import ChartActionsMenu from 'components/ChartActionsMenu';
import { ChartingDisplayState } from 'components/ColumnLayoutSelector';
import ErrorToastService from 'components/Errors/ErrorToast/Services/ErrorToastService';
import { ToastMessageRef } from 'components/ToastMessage';

import { BasicColumn } from '../../CommoditiesData';
import { Frequency } from '../../Models/Enums';
import {
  HistoricalDataRow,
  HistoricalSearchRequest,
} from '../../Models/Historical';
import { CommoditiesDataAPI } from '../../Services/CommoditiesDataAPI';
import { useHistoricalData } from '../../Services/hooks';
import { BasicRow } from '../Grids/SimpleGrid';
import SelectedColumns from '../SelectedColumns';
import SelectedRows from '../SelectedRows';

import { notNil } from 'helpers/Utils/misc';

import styles from './Chart.module.scss';

type ChartingProps = {
  columns: BasicColumn[] | null;
  rows: BasicRow[] | null;
  source: any;
  sourceName: string;
  feed: any;
  frequency: Frequency;
  size: ChartingDisplayState;
  setSelectedColumns: Dispatch<SetStateAction<BasicColumn[] | null>>;
  setSelectedRows: Dispatch<SetStateAction<BasicRow[]>>;
  query: string | undefined;
  toastRef?: RefObject<ToastMessageRef>;
};

export type ChartReferenceProps = {
  resetRequest: () => void;
};

enum MutationType {
  Frequency = 1,
  Period = 2,
  Columns = 3,
  Rows = 4,
  All = 5,
}

type MutationAction = {
  type: MutationType;
  payload?: number | string[] | HistoricalDataRow[];
};

type PeriodOption = {
  label: string;
  period: number;
};

type PeriodMenuProps = {
  option: { items: PeriodOption[]; default: number };
  callback: (n: number) => void;
  period: number;
};

exportingModule(Highcharts);

const PeriodMenu = (props: PeriodMenuProps): JSX.Element => {
  const { callback, period } = props;
  const { items } = props.option;

  return (
    <div className={styles.periodContainer}>
      {items.map((item, index) => (
        <Button
          key={`period--${index}`}
          size='small'
          className={clsx(
            'p-button--tab-style text--uppercase',
            period === item.period && 'active'
          )}
          onClick={() => callback(item.period)}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};

const Chart = forwardRef<ChartReferenceProps | null | undefined, ChartingProps>(
  (props, ref): JSX.Element => {
    const {
      feed,
      source,
      sourceName,
      columns = [],
      rows = [],
      frequency,
      size,
      setSelectedColumns,
      setSelectedRows,
      query,
      toastRef,
    } = props;

    const [isDataFullfilled, setIsDataFullfilled] = useState(false);

    const hc = useRef<HighchartsReactRefObject>(null);

    useImperativeHandle(ref, () => ({
      resetRequest: () => dispatcher({ type: MutationType.All }),
    }));

    const periods = useRef([
      {
        default: 6,
        items: [
          { label: '6m', period: 6 },
          { label: '1y', period: 12 },
          { label: '3y', period: 36 },
          { label: '5y', period: 60 },
          { label: '10y', period: 120 },
        ],
      },
      {
        default: 12,
        items: [
          { label: '1y', period: 12 },
          { label: '3y', period: 36 },
          { label: '5y', period: 60 },
          { label: '10y', period: 120 },
        ],
      },
      {
        default: 60,
        items: [
          { label: '3y', period: 36 },
          { label: '5y', period: 60 },
          { label: '10y', period: 120 },
          { label: '20y', period: 240 },
        ],
      },
    ]);

    const [options, setOptions] = useState<Options | null>();

    const initRequestState = () => ({
      frequency: frequency,
      period: periods.current[frequency].default,
      category: [],
      data: [],
    });

    const mutator = (
      state: HistoricalSearchRequest,
      action: MutationAction
    ): HistoricalSearchRequest => {
      switch (action.type) {
        case MutationType.Frequency:
          //	Because the frequency changed - we also need to change the
          //	period value to the default
          return {
            ...state,
            frequency: action.payload as Frequency,
            period: periods.current[action.payload as Frequency].default,
          };
        case MutationType.Rows:
          return { ...state, data: action.payload as HistoricalDataRow[] };
        case MutationType.Columns:
          return { ...state, category: action.payload as string[] };
        case MutationType.Period:
          return { ...state, period: action.payload as number };
        case MutationType.All:
          const period: number =
            (state.frequency as Frequency) === frequency
              ? state.period
              : periods.current[frequency].default;

          return {
            frequency,
            period,
            category: [],
            data: [],
          };
      }
    };

    const [request, dispatcher] = useReducer(mutator, initRequestState());

    const {
      data: history,
      error,
      isLoading,
    } = useHistoricalData(feed, source, request);

    const getExportFileName = useCallback(
      () =>
        `${feed.toUpperCase()} - ${sourceName} - ${DateTime.now().toFormat(
          'yyyy-dd-MM HH:mm'
        )}`,
      [feed, sourceName]
    );

    const calcSteps = (p: number, f: Frequency, s: number) => {
      //	vector array contains xaxis label steps based on [frequency[period, step]]
      const vectors = [
        [
          [6, 3],
          [6, 1],
          [6, 1],
          [12, 6],
          [12, 3],
          [12, 2],
          [36, 12],
          [36, 6],
          [36, 3],
          [60, 16],
          [60, 8],
          [60, 4],
          [120, 28],
          [120, 12],
          [120, 6],
        ],
        [
          [12, 2],
          [12, 2],
          [12, 1],
          [36, 6],
          [36, 2],
          [36, 1],
          [60, 6],
          [60, 3],
          [60, 1],
          [120, 10],
          [120, 4],
          [120, 3],
        ],
        [
          [36, 1],
          [36, 1],
          [36, 1],
          [60, 1],
          [60, 1],
          [60, 1],
          [120, 2],
          [120, 1],
          [120, 1],
          [240, 4],
          [240, 4],
          [240, 1],
        ],
      ];

      //	return the appropriate number of steps based on the passed
      //	period, frequency and panel size values
      const [, result] = (vectors[f].filter(i => i[0] === p) ?? [])[s] as [
        number,
        number
      ];
      return result;
    };

    const onExport = useCallback(() => {
      CommoditiesDataAPI.exportHistoricalData(feed, source, request)
        .then(data => {
          fileDownload(
            data,
            `${getExportFileName()}.xlsx`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
        })
        .catch(e => {
          ErrorToastService.handleError(e, [500, 503]);

          // handle error state
          throw e;
        });
    }, [feed, request, source, getExportFileName]);

    useEffect(() => {
      if (!history?.length) {
        setOptions(null);
        return;
      }

      const series = history.map(h => {
        const { name, data } = h;
        return { name, data, type: 'spline', marker: { enabled: false } };
      }) as SeriesOptionsType[];

      setOptions({
        chart: {
          backgroundColor: 'transparent',
          className: 'chart__container',
          styledMode: true,
          style: {
            fontFamily: 'OpenSans',
          },
        },
        title: {
          text: undefined,
        },
        yAxis: {
          title: { text: undefined },
        },
        xAxis: {
          type: 'category',
          className: 'chart__axis',
          tickWidth: 1,
          tickmarkPlacement: 'on',
          tickPixelInterval: calcSteps(request.period, frequency, size),
          labels: {
            autoRotation: [0],
            staggerLines: 1,
            step: calcSteps(request.period, frequency, size),
          },
        },
        series,
        exporting: {
          enabled: false,
          fallbackToExportServer: false,
        },
      });
    }, [history]);

    useEffect(() => {
      //	size of display area for the chart has changed so the x-axis labels can
      //	be adjusted
      setOptions(curr => {
        if (!curr) return;
        let { xAxis } = curr;
        let { labels } = curr.xAxis as XAxisOptions;

        xAxis = {
          ...xAxis,
          labels: {
            ...labels,
            step: calcSteps(request.period, frequency, size),
          },
          tickPixelInterval: calcSteps(request.period, frequency, size),
        };
        return { ...curr, xAxis };
      });
    }, [size]);

    useEffect(() => {
      if (!rows) return;

      const payload: HistoricalDataRow[] = rows?.map(r => {
        const { location, shortName: product } = r;

        return product.length ? { location, product } : { location };
      });

      dispatcher({ type: MutationType.Rows, payload });
    }, [rows]);

    useEffect(() => {
      if (!columns) return;
      const payload = columns.map(c => c.name);
      dispatcher({ type: MutationType.Columns, payload });
    }, [columns]);

    useEffect(() => {
      if (!notNil(frequency)) return;
      dispatcher({ type: MutationType.Frequency, payload: frequency });
    }, [frequency]);

    useEffect(() => {
      setIsDataFullfilled(Boolean(options && !error));
    }, [options, error]);

    return (
      <div className={styles.container}>
        <div className={styles.columnContainer}>
          <div className={styles.actionsContainer}>
            <SelectedRows
              selectedRows={rows ?? []}
              setSelectedRows={setSelectedRows}
            />
            <ChartActionsMenu
              fileName={getExportFileName}
              highchartRef={hc}
              isDisabled={!isDataFullfilled}
              onExportClick={onExport}
              toastRef={toastRef}
            />
          </div>
          <SelectedColumns
            source={source}
            feed={feed}
            query={query}
            columns={columns}
            setSelectedColumns={setSelectedColumns}
          />
          <PeriodMenu
            option={periods.current[frequency]}
            callback={v =>
              dispatcher({ type: MutationType.Period, payload: v })
            }
            period={request.period}
          />
        </div>
        {error && (
          <div className={styles.error}>
            <div className='iconoir-warning-circle icon--small' />
            Sorry, we dont have data for this combination of location, product
            and category
          </div>
        )}
        {isLoading && <BorealisBar styleOverrides={styles.bar} />}
        {options && (
          <>
            <HighchartsReact
              ref={hc}
              highcharts={Highcharts}
              options={options}
              allowChartUpdate={true}
              {...props}
            />
          </>
        )}
      </div>
    );
  }
);

export default Chart;
