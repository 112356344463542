import type { SearchField } from 'components/EntitySearch/Models/SearchEntities';

export type DateTimeSearchRequest = {
  fromDate: string;
  toDate: string;
  original?: string;
};

export type SearchRequest = {
  searchRequestFields?: SearchField[];
  date?: DateTimeSearchRequest;
  onlyAttachments?: boolean | null;
  onlyEmpty?: boolean | null;
  worksheetId?: string | null;
  batchId?: string | null;
  updatedAt?: string | null;
};

export type SearchRequestPaged = SearchRequest & {
  pageSize: number;
  pageNumber: number;
  cldd?: string;
};

export type SearchRequestBatch = {
  pageSize: number;
  page: number;
};

export const DEFAULT_SEARCH_ITEMS: SearchRequest = {
  searchRequestFields: [],
  date: undefined,
  onlyAttachments: null,
  onlyEmpty: null,
  worksheetId: null,
};
