import { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSignalR } from 'App';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';

import { useLoadUserSettings, useSaveUserSetting } from 'components/OBXUser/Services/ProfileHooks';
import NoLongerAvailable from 'components/Worksheets/Components/NoLongerAvailable';
import type { WorksheetResponse } from 'components/Worksheets/Models/WorksheetResponse';
import WorksheetsHistory from 'components/Worksheets/WorksheetsHistory';

import Conversation from './Components/Conversation';
import QuestionBar from './Components/QuestionBar';
import { getModuleGlyph } from './Models/Common';
import { ERROR_TEXT, UI_CONFIG, WS_STORE } from './Models/Consts';
import { CONV_CONFIG, ConversationModuleContext, MessageRole } from './Models/Enums';
import { MarketAssistConversationMessageResponse } from './Models/MarketAssistResponse';
import { MarketAssistApiService, useGetConversation } from './Services/MarketAssistService';
import { MarketAssistSocket } from './Services/SignalRSocket';

import { notNil } from 'helpers/Utils/misc';
import { removeTrailingSlash, stripParams, stripUUID } from 'helpers/Utils/string';

import './MarketAssist.scss';

const MarketAssist = ():ReactElement => {
  const { worksheetparam } = useParams();
  const { trigger } = useSaveUserSetting();
  const { getSetting } = useLoadUserSettings();
  const navigate = useNavigate();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { signal } = useSignalR();

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const [leftColumn, setLeftColumn] = useState<boolean>(!isMobile);
  const [activeWorksheetId, setActiveWorksheetId] = useState<
    string | null | undefined
  >(
    worksheetparam ||
    getSetting(UI_CONFIG)?.activeWorksheetId
  );
  const [isLoadingAnswer, setIsLoadingAnswer] = useState<boolean>(false);
  const [isFirstMessage, setIsFirstMessage] = useState<boolean>(false);
  const [conversationContext, setConversationContext] = useState<ConversationModuleContext>();
  const [isWSNotFound, setIsWSNotFound] = useState(false);
  const [messageList, setMessageList] = useState<MarketAssistConversationMessageResponse[]>([]);
  const [searchInputQuestion, setSearchInputQuestion] = useState<string>('');

  useEffect(() => {
    if (notNil(worksheetparam) && worksheetparam !== activeWorksheetId && activeWorksheetId !== null) {
      setActiveWorksheetId(worksheetparam);
    }
  }, [activeWorksheetId, worksheetparam]);

  const { conversationData, conversationIsLoading, conversationError } = useGetConversation(isFirstMessage ? null : activeWorksheetId);

  const saveActiveWS = useCallback((id?: string | null):void => {
    const storedId = id || activeWorksheetId;
    (storedId !== null && storedId !== '') && trigger({
      setting: UI_CONFIG,
      data: {
        activeWorksheetId: storedId
      },
    });
  }, [activeWorksheetId, trigger]);
  
  useEffect(() => {
    if (conversationData && conversationData.messages.length > 0) {
      setMessageList(conversationData.messages);
      saveActiveWS(); // Store WS only when there is not empty response
    }
  }, [conversationData, saveActiveWS]);

  const initNewConversation = useCallback((): void => {
    setSearchInputQuestion('');
    setActiveWorksheetId(null);
    setMessageList([]);
    if (worksheetparam) {
      navigate(location.pathname.replace(`/${ worksheetparam }`, ''));
    }
  }, [navigate, worksheetparam]);

  const updateMessages = useCallback((
    content?: string | null,
    data?: MarketAssistConversationMessageResponse | null,
  ) => {
    setMessageList(previousMessages => [
      ...previousMessages,
      ...((content || data === null) ? [{
        id: '',
        role: data === null ? MessageRole.Assistant : MessageRole.User,
        content: data === null ? ERROR_TEXT : (content ?? ''),
        dateUtc: DateTime.now().toUTC(),
      }] : []),
      ...(data ? [data] : [])
    ]);
  }, []);


  const startConversation = useCallback(async (content: string, moduleContext: number): Promise<void> => {
    updateMessages(content);
    setIsLoadingAnswer(true);
    setActiveWorksheetId('');
    setConversationContext(moduleContext);
    const data = await MarketAssistApiService.startConversation({ content, moduleContext });
    if (!notNil(data) || !data?.conversationDetails.worksheetId) {
      updateMessages(null, null);
      setIsLoadingAnswer(false);
      setConversationContext(undefined);
      return;
    }

    const newId = data?.conversationDetails.worksheetId;

    setActiveWorksheetId(newId);
    saveActiveWS(newId);
    updateMessages(null, data?.message);

    console.info('Market Assistant conversation added:', newId);
    if (worksheetparam && newId) {
      navigate(location.pathname.replace(worksheetparam, newId));
    }
    setIsLoadingAnswer(false);
    setIsFirstMessage(true); // Not load conversation history after first message is loaded
  }, [updateMessages, saveActiveWS, worksheetparam, navigate]);

  const continueConversation = useCallback(async (content: string): Promise<void> => {
    if (!activeWorksheetId) {
      return;
    }
    updateMessages(content);
    setIsLoadingAnswer(true);
    const data = await MarketAssistApiService.askInConversation({ content }, activeWorksheetId);
    updateMessages(null, data);
    setIsLoadingAnswer(false);
  }, [activeWorksheetId, updateMessages]);

  useEffect(() => {
    isMobile && setLeftColumn(false);
    console.info('Market Assistant active conversation:', activeWorksheetId ?? '-');
  }, [activeWorksheetId, isMobile]);

  useEffect(() => {
    setLeftColumn(!isMobile);
  }, [isMobile]);

  // Scroll to bottom when there are new messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messageList]);

  const switchWorksheet = (id: string | null | undefined):void => {
    if (id !== activeWorksheetId) {
      setMessageList([]); // Clear messages
      setIsFirstMessage(false);
      setSearchInputQuestion('');
      setActiveWorksheetId(id);
    }
  };

  // Show error as message when we get null in response. Run this also on WS switch.
  useEffect(() => {
    if (conversationData === null) {
      updateMessages(null, null);
    }
  }, [activeWorksheetId, conversationData, updateMessages]);

  useEffect(() => {
    if (conversationError) {
      if (conversationError.response?.status === 404) {
        setIsWSNotFound(true);
      } else {
        updateMessages(null, null);
      }
    } else {
      setIsWSNotFound(false);
    }
  }, [conversationError, updateMessages]);

  useEffect(() => {
    const socket = MarketAssistSocket.instance;
    socket.init(signal);
  }, [signal]);

  useEffect(() => {
    setConversationContext(conversationData?.moduleContext);
  }, [conversationData]);

  const getSubtitle = (data: WorksheetResponse): ReactNode => {
    const moduleContext = data.additionalSearchProperties.find(item => item.key === 'ModuleContext');
    const context = moduleContext?.value as ConversationModuleContext || ConversationModuleContext.General;
    const moduleGlyph: string = getModuleGlyph(context);
    return <>
      <i className={`iconoir-${ moduleGlyph } icon--tiny`}></i>
      {CONV_CONFIG[context].label}
    </>;
  };

  return <>
    <div className='ma-container grow-to-fill'>
      <aside
        className={clsx('ma-conversation-list position--relative', {
          hidden: !leftColumn,
        })}
      >
        {isMobile && <Button
          size='small'
          className='ma-toggle-left-panel'
          text
          icon='iconoir-sidebar-collapse icon--tiny'
          onClick={(): void => setLeftColumn(false)}
        />}
        <WorksheetsHistory
          activeWorksheetId={activeWorksheetId}
          setActiveWorksheetId={switchWorksheet}
          store={WS_STORE}
          currentRoute={removeTrailingSlash(stripParams(stripUUID(location.pathname), worksheetparam))}
          getSubtitle={getSubtitle}
        />
      </aside>
      <div className='ma-content grow-to-fill'>
        <header>
          <Button
            size='small'
            className='ma-toggle-left-panel'
            text
            icon={`iconoir-sidebar-${
              leftColumn ? 'collapse' : 'expand'
            } icon--tiny`}
            onClick={(): void => setLeftColumn(!leftColumn)}
            tooltip={!isMobile ? `${ leftColumn ? 'Close' : 'Open' } conversation list` : undefined}
            tooltipOptions={{
              position: 'right',
            }}
          />
          <Button
            size='small'
            onClick={initNewConversation}
            disabled={!notNil(activeWorksheetId)}
          >
            New Conversation
          </Button>
        </header>
        <main>
          {isWSNotFound && (
            <NoLongerAvailable
              title='Conversation'
              subtitle='Please select a conversation from the panel on the left side or start a new one'
              addNewWorksheet={initNewConversation}
              buttonText='Start new conversation'
            />
          )}
          {notNil(activeWorksheetId) && !isWSNotFound &&
            <Conversation
              messages={messageList}
              messagesEndRef={messagesEndRef}
              isLoadingAnswer={isLoadingAnswer}
              conversationIsLoading={conversationIsLoading}
            />
          }
          {!isWSNotFound && <QuestionBar
            activeWorksheetId={activeWorksheetId}
            startConversation={startConversation}
            continueConversation={continueConversation}
            inputQuestion={searchInputQuestion}
            isLoadingAnswer={isLoadingAnswer}
            setInputQuestion={setSearchInputQuestion}
            context={conversationContext}
          />}
        </main>
      </div>
    </div>
  </>;
};

export default MarketAssist;
