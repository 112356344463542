import React, { ReactNode } from 'react';
import { Navigate, useLocation, useParams, } from 'react-router-dom';

import ContactSales from 'components/ContactSales';
import ErrorPage from 'components/Errors/ErrorPage';
import { SecurityRights } from 'components/OBXUser/Model/SecurityRightsEnum';
import { RibbonTypes } from 'components/Ribbons/Models/RibbonTypes';

import MarketAssist from './modules/MarketAssist';

import ArtisCharting from 'modules/ArtisCharting';
import ChartWindow from 'modules/ArtisCharting/Components/ChartWindow';
import BlotterPage from 'modules/Blotter';
import CargoFlows from 'modules/CargoFlows';
import CargoTracker from 'modules/CargoTracker/CargoTrackerPage';
import { CargoTrackerModeEnum } from 'modules/CargoTracker/Models/Enums';
import DataModulePage from 'modules/DataModule';
import DistanceCalcPage from 'modules/DistanceCalc';
import DistListPage from 'modules/DistList/DistListPage';
import { DistListModeEnum } from 'modules/DistList/Models/Enums';
import InternalTesting from 'modules/Internal';
import UserSettingsPlayground from 'modules/Internal/UserSettingsPlayground';
import Rates from 'modules/Rates';
import BalticRates from 'modules/Rates/BalticRates';
import CommoditiesData from 'modules/Rates/Commodities';
import RateGrids from 'modules/Rates/Grids';
import VortexaRates from 'modules/Rates/VortexaRates/VortexaRates';
import Reports from 'modules/Reports';
import SanctionsPage from 'modules/Sanctions';
import { ResultsModeEnum } from 'modules/Sanctions/Models/Enums';
import ShippingFixtures from 'modules/ShippingFixtures';
import Surveillance from 'modules/Surveillance';
import EscalationInbox from 'modules/Surveillance/Components/EscalationInbox/EscalationInbox';
import ReportLinkExpiredPage from 'modules/Surveillance/Components/ReportLinkExpired';
import { SurveillanceModeEnum } from 'modules/Surveillance/Models/Enums';
import WorkflowPage from 'modules/Workflows/WorkflowPage';

import {
  CargoTrackerConfig,
  DistListConfig,
  LoaderConfig,
  RatesConfig,
  RouteConfig,
  SanctionsConfig
} from 'types/routes-type';

// Define custom route names (mapped later in TopMenuRoutes.ts)
/* eslint-disable no-unused-vars */
export enum CustomRoute {
  Workflows = 'CUSTOM_ROUTE_WORKFLOWS',
  Surveillance = 'CUSTOM_ROUTE_SURVEILLANCE',
}
/* eslint-enable no-unused-vars */

export const RATES_ROUTE_CONFIG: RatesConfig[] = [
  {
    path: 'baltic-spot',
    label: 'Baltic',
    subpath: ':market',
    component: 'BalticRates',
    accessCode: SecurityRights.BalticRates
  },
  {
    path: 'vortexa',
    label: 'Vortexa',
    subpath: ':market',
    component: 'VortexaRates',
    accessCode: SecurityRights.VortexaRates
  },
  {
    path: 'iea-mods',
    label: 'IEA',
    subpath: ':source',
    component: 'IEAModsData',
    accessCode: SecurityRights.IEARates
  },
  {
    path: 'jodi-mods',
    label: 'JODI',
    subpath: ':source',
    component: 'JodiModsData',
    accessCode: SecurityRights.JodiRates
  },
  {
    path: 'rate-grids',
    label: 'Rate Grids',
    subpath: ':grid',
    component: 'RateGrids',
    accessCode: SecurityRights.RateGrids
  },
];

const SANCTIONS_ROUTE_CONFIG: SanctionsConfig[] = [
  {
    path: 'vessel',
    label: 'Vessel Sanctions',
    subpath: ':search',
    resultsMode: ResultsModeEnum.VesselSanctions
  },
  {
    path: 'entity',
    label: 'Entity Sanctions',
    subpath: ':search',
    resultsMode: ResultsModeEnum.LegalEntitySanctions
  },
  {
    path: 'individual',
    label: 'Individual Sanctions',
    subpath: ':search',
    resultsMode: ResultsModeEnum.IndividualSanctions
  },
];

const TESTING_ROUTE_CONFIG = [
  { path: 'user-settings', label: 'User Settings', element: <UserSettingsPlayground/> }
];

const CARGO_TRACKER_ROUTE_CONFIG: CargoTrackerConfig[] = [
  { path: 'spot', label: 'Spot', resultsMode: CargoTrackerModeEnum.Spot },
  { path: 'time-charter', label: 'TC', resultsMode: CargoTrackerModeEnum.TC },
];

const DIST_LIST_ROUTE_CONFIG: DistListConfig[] = [
  {
    path: 'dl',
    subpath: ':distListIdParam?/:emailIdParam?',
    label: 'Distribution List',
    resultsMode: DistListModeEnum.dl
  },
  {
    path: 'single',
    subpath: ':singleIdParam?/:activeTab?',
    label: 'Single Message',
    resultsMode: DistListModeEnum.single
  },
];

const DIST_LIST_LOADER = ():LoaderConfig => ({
  heading: 'Distribution List',
  accessCode: SecurityRights.DistList
});

const SurveillanceRedirect = (): ReactNode => {
  const location = useLocation();
  const params = useParams();

  if (params.batchparam) {
    // Redirect old URL "surveillance/results/:worksheet/batch/:batchparam" to new "surveillance/escalations/:batchparam"
    return (
      <Navigate
        to={`/surveillance/escalations/${ params.batchparam }`}
        state={location.state}
        replace
      />
    );
  } else {
    // Redirect old URL "surveillance/:worksheet" to new "surveillance/results/:worksheet"
    return (
      <Navigate
        to={`/surveillance/results${
          params.worksheetparam ? `/${ params.worksheetparam }` : ''
        }`}
        state={location.state}
        replace
      />
    );
  }
};

const SurveillanceLoader = ():LoaderConfig => ({
  heading: 'Surveillance',
  accessCode: [SecurityRights.Surveillance],
});

const CargoFlowsLoader = ():LoaderConfig => ({
  ribbons: [RibbonTypes.MobileVersionInDevelopment],
  heading: 'Cargo Flows',
  accessCode: SecurityRights.CargoMovements,
});

/* NOTE: remember to add `accessCode` inside a loader method.
    You won't be able to access to the page without it. */
export const ROUTES_CONFIG: RouteConfig[] = [
  {
    path: '/reports',
    element: <Reports/>,
    topMenuConfig: {
      name: 'reports',
      glyph: 'stats-report',
      label: 'Reports',
      route: '/reports',
      isBeta: false,
      hideOnMobile: true,
      securityRights: SecurityRights.Reports
    },
    loader: () => ({
      ribbons: [RibbonTypes.AlphaRelease],
      heading: 'Reports',
      accessCode: SecurityRights.Reports
    }),
    children: [
      {
        path: ':reportId', element: <Reports/>,
        loader: () => ({
          ribbons: [RibbonTypes.AlphaRelease],
          heading: 'Reports',
          accessCode: SecurityRights.Reports
        }),
        children: [{
          path: ':pageName', element: <Reports/>,
          loader: () => ({
            ribbons: [RibbonTypes.AlphaRelease],
            heading: 'Reports',
            accessCode: SecurityRights.Reports
          })
        }
        ]
      }
    ]
  },

  {
    path: '/market-assistant/:worksheetparam?',
    errorElement: <ErrorPage />,
    element: <MarketAssist />,
    topMenuConfig: {
      name: 'market-assistant',
      glyph: 'sparks',
      label: 'Market Assistant',
      route: '/market-assistant',
      isBeta: false,
      securityRights: SecurityRights._Unprotected
    },
    loader: () => ({
      heading: 'Market Assistant',
      accessCode: SecurityRights._Unprotected
    })
  },

  {
    path: '/sanctions',
    topMenuConfig: {
      name: 'sanctions',
      glyph: 'shield-alert',
      label: 'Sanctions',
      route: '/sanctions/vessel',
      isBeta: false,
      securityRights: SecurityRights.Sanctions
    },
    loader: () => ({
      accessCode: SecurityRights.Sanctions,
    }),
    errorElement: <ErrorPage/>,
    children: SANCTIONS_ROUTE_CONFIG.map((item: SanctionsConfig) => {
      const { path, subpath, resultsMode, label } = item;
      return {
        path,
        resultsMode,
        element: <SanctionsPage resultsMode={resultsMode} items={SANCTIONS_ROUTE_CONFIG}/>,
        loader: () => ({heading: label, accessCode: SecurityRights.Sanctions}),
        children: [
          {
            path: subpath,
            loader: () => ({accessCode: SecurityRights.Sanctions}),
            element: <SanctionsPage resultsMode={resultsMode} items={SANCTIONS_ROUTE_CONFIG}/>
          }
        ]
      };
    })
  },

  {
    path: '/rates',
    // NOTE: Trick for redirecting to first available sub-module from Rates.
    // On click on the Rates in the LeftMenu we have to check which package (Baltic, Vortexa, etc.) is allowed
    // and redirect to first possible rather than redirect to the SalesPage
    element: <Rates/>,
    errorElement: <ErrorPage/>,
    topMenuConfig: {
      name: 'rates',
      glyph: 'money-square',
      label: 'Rates',
      route: '/rates',
      isBeta: false,
      securityRights: RATES_ROUTE_CONFIG.map(r => r.accessCode)
    },
    loader: () => ({
      accessCode: RATES_ROUTE_CONFIG.map(r => r.accessCode),
      heading: 'Rates'
    }),
    children: RATES_ROUTE_CONFIG.map(item => {
      const {component, path, subpath, label, accessCode} = item;
      let element: ReactNode;

      switch (component) {
      case 'BalticRates':
        element = <BalticRates items={RATES_ROUTE_CONFIG}/>;
        break;
      case 'VortexaRates':
        element = <VortexaRates items={RATES_ROUTE_CONFIG}/>;
        break;
      case 'IEAModsData':
        element = <CommoditiesData items={RATES_ROUTE_CONFIG} feed="iea"/>;
        break;
      case 'JodiModsData':
        element = <CommoditiesData items={RATES_ROUTE_CONFIG} feed="jodi"/>;
        break;
      case 'RateGrids':
      default:
        element = <RateGrids items={RATES_ROUTE_CONFIG}/>;
        break;
      }

      return {
        path,
        element,
        loader: () => ({accessCode}),
        children: [{
          path: subpath,
          element,
          loader: () => ({heading: label, accessCode})
        }],
      };
    })
  },

  {
    // NOTE: re-think how to pass the arguments when the list gets bigger
    path: '/curves/:artispackage/:product/:tenorCode/:tenorIndex/:frequency/:period',
    errorElement: <ErrorPage/>,
    element: <ChartWindow/>,
    loader: () => ({
      heading: 'Curves',
      hideMenu: true,
      accessCode: SecurityRights.Curves
    }),
  },

  {
    path: '/curves/',
    errorElement: <ErrorPage/>,
    element: <ArtisCharting/>,
    topMenuConfig: {
      name: 'curves',
      glyph: 'candlestick-chart',
      label: 'Price Curves',
      route: '/curves',
      isBeta: false,
      securityRights: SecurityRights.Curves
    },
    loader: () => ({
      heading: 'Curves',
      accessCode: SecurityRights.Curves
    }),
    children: [{
      path: ':artispackage',
      element: <ArtisCharting/>,
      loader: () => ({
        heading: 'Curves',
        accessCode: SecurityRights.Curves
      }),
    }]
  },

  {
    path: '/distance-calculator',
    element: <DistanceCalcPage/>,
    errorElement: <ErrorPage/>,
    topMenuConfig: {
      name: 'distance-calculator',
      glyph: 'map',
      label: 'Distance Calc',
      route: '/distance-calculator',
      isBeta: false,
      securityRights: SecurityRights.DistanceCalculator
    },
    loader: () => ({
      accessCode: SecurityRights.DistanceCalculator,
      heading: 'Distance Calculator'
    })
  },

  {
    path: '/blotter',
    errorElement: <ErrorPage/>,
    element: <BlotterPage/>,
    topMenuConfig: {
      name: 'blotter',
      glyph: 'open-book',
      label: 'Blotter',
      route: '/blotter',
      isBeta: false,
      securityRights: SecurityRights.Blotter
    },
    loader: () => ({
      heading: 'Blotter',
      accessCode: SecurityRights.Blotter
    }),
  },

  {
    path: '/cargo-flows',
    topMenuConfig: {
      name: 'cargo-flows',
      glyph: 'path-arrow',
      label: 'Cargo Flows',
      route: '/cargo-flows',
      isBeta: false,
      securityRights: SecurityRights.CargoMovements
    },
    children: [
      {
        index: true,
        path: ':worksheetparam?/:share?',
        element: <CargoFlows/>,
        loader: CargoFlowsLoader,
      },
    ],
  },

  {
    path: '/cargo-tracker',
    errorElement: <ErrorPage/>,
    topMenuConfig: {
      name: 'cargo-tracker',
      glyph: 'cylinder',
      label: 'Cargo Tracker',
      route: '/cargo-tracker/spot',
      isBeta: false,
      securityRights: SecurityRights.CargoTracker
    },
    loader: () => ({accessCode: SecurityRights.CargoTracker}),
    children: CARGO_TRACKER_ROUTE_CONFIG.map(ct => {
      const {path, resultsMode} = ct;
      const loader = (): LoaderConfig => ({
        heading: 'Cargo Tracker',
        accessCode: SecurityRights.CargoTracker
      });

      return {
        element: <CargoTracker resultsMode={resultsMode} items={CARGO_TRACKER_ROUTE_CONFIG}/>,
        loader,
        path,
        resultsMode,
      };
    })
  },

  {
    path: '/workflows',
    errorElement: <ErrorPage/>,
    element: <WorkflowPage/>,
    topMenuConfig: {
      name: 'workflows',
      glyph: 'building',
      label: 'Workflows',
      route: CustomRoute.Workflows,
      isBeta: false,
      securityRights: SecurityRights.Onboarding
    },
    loader: () => ({
      heading: 'Workflows',
      accessCode: SecurityRights.Onboarding
    }),
    children: [
      {
        path: ':providerId/:statusFilter', element: <WorkflowPage/>,
        loader: () => ({
          heading: 'Workflows',
          accessCode: SecurityRights.Onboarding
        })
      }
    ]
  },

  {
    path: '/distlist',
    errorElement: <ErrorPage/>,
    topMenuConfig: {
      name: 'distlist',
      glyph: 'send-mail',
      label: 'Distribution List',
      route: '/distlist',
      isBeta: false,
      securityRights: SecurityRights.DistList
    },
    loader: DIST_LIST_LOADER,
    children: [
      {
        index: true,
        element: <Navigate to="dl" replace/>},
      ...DIST_LIST_ROUTE_CONFIG.map(dlc => {
        const {path, subpath, resultsMode} = dlc;

        const children = [
          {
            path: subpath,
            loader: DIST_LIST_LOADER,
            heading: 'Distribution List',
            element: <DistListPage resultsMode={resultsMode} items={DIST_LIST_ROUTE_CONFIG}/>
          }
        ];

        return {
          element: <DistListPage resultsMode={resultsMode} items={DIST_LIST_ROUTE_CONFIG}/>,
          loader: DIST_LIST_LOADER,
          path,
          resultsMode,
          children
        };
      })]
  },

  {
    path: '/surveillance',
    errorElement: <ErrorPage/>,
    topMenuConfig: {
      name: 'surveillance',
      glyph: 'binocular',
      label: 'Surveillance',
      route: CustomRoute.Surveillance,
      isBeta: false,
      hideOnMobile: true,
      securityRights: [SecurityRights.Surveillance, SecurityRights.FixtureSanctionsSearch]
    },
    children:
      [
        {index: true, element: <Navigate to="results" replace/>},
        {path: ':worksheetparam?', element: <SurveillanceRedirect/>},
        {path: ':worksheetparam?/batch/:batchparam?', element: <SurveillanceRedirect/>},
        {
          path: 'link-expired',
          element: <ReportLinkExpiredPage/>,
          loader: () => ({...SurveillanceLoader(), ribbons: []}),
        },
        {
          path: 'results/:worksheetparam?/batch/:batchparam?',
          element: <SurveillanceRedirect/>,
          loader: SurveillanceLoader,
        },
        {
          path: 'results/:worksheetparam?/:share?',
          element: <Surveillance resultsMode={SurveillanceModeEnum.Results}/>,
          loader: SurveillanceLoader,
        },
        {
          path: 'reviewed/:worksheetparam?/:share?',
          element: <Surveillance resultsMode={SurveillanceModeEnum.Reviewed}/>,
          loader: SurveillanceLoader
        },
        {
          path: 'escalations/:batchparam?',
          errorElement: <ErrorPage/>,
          element: <EscalationInbox/>,
          loader: SurveillanceLoader
        },
        {
          path: 'sanctions',
          children: [
            {
              index: true,
              element: <Navigate to="shipping-fixtures" replace/>
            },
            {
              path: 'shipping-fixtures',
              errorElement: <ErrorPage/>,
              element: <ShippingFixtures/>,
              loader: () => ({
                accessCode: SecurityRights.FixtureSanctionsSearch,
                heading: 'Shipping Fixtures Sanctions',
              })
            }
          ]
        }
      ],
  },

  {
    path: '/data',
    errorElement: <ErrorPage/>,
    element: <DataModulePage/>,
    topMenuConfig: {
      name: 'data',
      glyph: 'database-settings',
      label: 'Data',
      route: '/data',
      isBeta: false,
      hideOnMobile: true,
      securityRights: SecurityRights.DataManagement
    },
    loader: () => ({
      heading: 'Data',
      accessCode: SecurityRights.DataManagement
    })
  },

  {
    path: '/internal',
    element: <InternalTesting items={TESTING_ROUTE_CONFIG}/>,
    topMenuConfig: {
      name: 'internal',
      glyph: 'test-tube',
      label: 'Internal Test Lab',
      route: '/internal/user-settings',
      isBeta: true,
      securityRights: SecurityRights._Unprotected
    },
    children: TESTING_ROUTE_CONFIG.map(item => {
      const {path, element} = item;
      return {
        path,
        element,
        loader: () => ({
          accessCode: SecurityRights._Unprotected,
          heading: 'Internal',
        }),
      };
    })
  },

  // Deprecated / Unused ?

  {
    path: '/contact-sales/:moduleName?',
    errorElement: <ErrorPage/>,
    element: <ContactSales/>,
    loader: () => ({
      // heading: 'Contact Sales',
      accessCode: SecurityRights._Unprotected
    }),
  },
  {
    path: '/dashboard',
    element: <Reports/>,
    errorElement: <ErrorPage/> // add errorElement on the path level so left menu is still available
  },
];
