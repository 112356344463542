import { ReactNode, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';

import { uniqueId } from 'helpers/Utils/string';

import styles from './ToggleSwitch.module.scss';

interface IToggleProps {
  checked: boolean;
  callback?: (value: boolean) => void;
  label?: 'left' | 'right';
  align?: 'left' | 'right';
  slots?: string[];
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
}

const ToggleSwitch = ({
  checked,
  callback,
  label,
  align,
  slots,
  disabled = false,
  className,
  children,
}: IToggleProps): ReactNode => {
  const [left, right] = slots ?? [];

  const [state, setState] = useState<boolean>(checked);

  const toggleId = useMemo(() => uniqueId(), []);

  useEffect(() => {
    setState(checked ?? '');
  }, [checked]);

  return (
    <div
      className={clsx(className, styles.instance, {
        [styles.reversed]: label === 'right',
        [styles.justifyEnd]: align === 'left',
        [styles.disabled]: disabled,
      })}
    >
      {children && <label htmlFor={toggleId}>{children}</label>}
      {slots && <span>{left}</span>}
      <InputSwitch
        inputId={toggleId}
        checked={state}
        onChange={(e: InputSwitchChangeEvent) => {
          const value = e.value ?? false;
          setState(() => value);
          callback && callback(value);
        }}
        disabled={disabled}
      />
      {slots && <span>{right}</span>}
    </div>
  );
};

export default ToggleSwitch;
export { ToggleSwitch };
