import { ReactNode, useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { ColumnBodyOptions } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import useDimensions from 'helpers/Hooks/Dimensions';
import { PHONE_REGEX } from 'helpers/Utils/string';

import styles from './DoubleLine.module.scss';
import general from './General.module.scss';

/**
 * Custom cell rendering function for the <DataTable> instances. Used to display
 * a double line data for counterpart that requires truncation if total length exceeds available
 * horizontal space
 *
 * @param {F<string>} data
 * @param {ColumnBodyOptions} config
 * @param {boolean} showOverflowTooltip
 *
 */
export const DoubleLineCounterpart = <T,>(
  data: T,
  config: Pick<ColumnBodyOptions, 'field' | 'rowIndex'>,
  showOverflowTooltip: boolean = false
): ReactNode => {
  const { field, rowIndex } = config;
  const [content, group] = field.split(',');
  const groupData = data[group as keyof T];
  const contentData = data[content as keyof T];

  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const spanRef = useRef<HTMLSpanElement>(null);
  const { width, scrollWidth } = useDimensions(spanRef);

  useEffect(() => {
    setIsOverflowed(width < scrollWidth);
  }, [scrollWidth, width]);

  return (
    <div className='overflow--hidden'>
      {showOverflowTooltip && isOverflowed && (
        <Tooltip
          target={`.${ content }-${ rowIndex }`}
          content={
            groupData ? `${ groupData }\n${ contentData }` : `${ contentData }`
          }
        />
      )}
      {groupData && (
        <span className={clsx('upper', general.truncated)}>
          {`${ groupData }`}
        </span>
      )}
      <span
        className={clsx(
          styles.truncated,
          'truncated',
          {
            [styles.truncatedTooltip]: showOverflowTooltip && isOverflowed,
            unknown: !contentData,
            lower: groupData,
          },
          `${ content }-${ rowIndex }`
        )}
        ref={spanRef}
      >
        {`${
          PHONE_REGEX.test(contentData as string)
            ? contentData // TODO: Add later telephone number formatting in formatter.ts and use here
            : (contentData as string) ?? 'Unknown'
        }`}
      </span>
    </div>
  );
};
