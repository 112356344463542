import useSWR, { MutatorCallback, State, useSWRConfig, Key } from "swr";
import useSWRMutation, { SWRMutationResponse } from "swr/mutation";
import type { AxiosError } from "axios";

import { replaceItemAt } from "helpers/Utils/collections";
import { notNil } from "helpers/Utils/misc";

import {
  CargoSearchRequest,
  CargoUpdateResponse,
} from "../Models/CargoTrackerRequest";
import {
  CargoTrackerResponse,
  CargoTrackerExportPayload,
} from "../Models/CargoTrackerResponse";
import { ResponseError } from "models/shared/error";

import { isSearchRequestEmpty } from "../Components/CargoSearch/Models/Parsers";

import { CargoTrackerAPI, CARGO_CACHE } from "./CargoTrackerAPI";

export const useGetCargo = (): {
  data: CargoTrackerResponse[] | undefined;
  error: ResponseError;
  isLoading: boolean;
} => {
  const { data, error, isLoading } = useSWR(
    CARGO_CACHE,
    () => CargoTrackerAPI.getCargo(),
    { revalidateOnFocus: false }
  );
  return { data, error, isLoading };
};

export const useUpdateCargo = (
  id: string | null
): {
  trigger: SWRMutationResponse["trigger"];
  result?: CargoUpdateResponse;
  isMutating: SWRMutationResponse["isMutating"];
} => {
  const { mutate } = useSWR(CARGO_CACHE);
  const { cache } = useSWRConfig();

  const { trigger, data, isMutating } = useSWRMutation(
    `cargo/${id}`,
    CargoTrackerAPI.updateCargo,
    {
      onSuccess: (c) => {
        //	Mutate the cache
        const { data } = cache.get(CARGO_CACHE) as State<
          CargoTrackerResponse[]
        >;

        if (!data || !c) return;

        if (!notNil(id)) {
          //	Created a new record, so we can just add into the start  of the collection
          mutate([CargoTrackerAPI.updateCargoTrackerResponse(c), ...data], {
            revalidate: false,
          });
          return;
        } else {
          //	its an exsiting item - so we need to find it in the collection and replace with the updated
          //	data recieved in the response
          const index: number = data.findIndex((i) => i.id === c.id);
          mutate(
            replaceItemAt(
              data,
              CargoTrackerAPI.updateCargoTrackerResponse(c),
              index
            ),
            { revalidate: false }
          );
        }
      },
    }
  );

  return { trigger, result: data, isMutating };
};

export const useDeleteCargo = (
  selectedCargos: CargoTrackerResponse[]
): {
  remove: (data: { ids: Array<string> }) => Promise<void>;
  result?: CargoUpdateResponse;
  isMutating: SWRMutationResponse["isMutating"];
} => {
  const { mutate } = useSWR(CARGO_CACHE);
  const { cache } = useSWRConfig();

  const { trigger, data, isMutating } = useSWRMutation(
    CARGO_CACHE,
    CargoTrackerAPI.deleteCargo,
    {
      onSuccess: () => {
        const { data } = cache.get(CARGO_CACHE) as State<
          CargoTrackerResponse[]
        >;

        if (!data) return;

        // filter out items that are selected
        mutate(
          data.filter(
            (item) =>
              selectedCargos.findIndex((cargo) => cargo.id === item.id) === -1
          ),
          { revalidate: false }
        );
      },
    }
  );
  // @ts-ignore // TODO: Fix Typescript
  return { remove: trigger, result: data, isMutating };
};

export const useSearchCargo = (
  csr: CargoSearchRequest | null
): {
  searchResults: CargoTrackerResponse[] | undefined;
  searchError: ResponseError;
  searchIsLoading: boolean;
  searchMutate: MutatorCallback<CargoTrackerResponse[]>;
} => {
  let arg: CargoSearchRequest | null | string = csr;
  let csrData: CargoSearchRequest = csr ?? {};

  // When csr is null -> arg for useSWR should be null to not fetch data (worksheet is still loading)
  // When csr is loaded but empty -> arg for useSWR should be CARGO_CACHE so it can be obtained/updated from CACHE
  // By default send CargoSearchRequest data as above
  if (arg !== null && isSearchRequestEmpty(arg)) {
    arg = CARGO_CACHE;
    csrData = {};
  }

  const { data, error, isLoading, mutate } = useSWR(
    arg,
    () => CargoTrackerAPI.searchCargo(csrData),
    { revalidateOnFocus: false }
  );
  return {
    searchResults: data,
    searchError: error,
    searchIsLoading: isLoading,
    searchMutate: mutate,
  };
};

type ExportTradesReturnType = {
  trigger: (arg: CargoTrackerExportPayload) => Promise<Blob>;
  error?: AxiosError;
  isMutating: boolean;
};

export const useExportTrades = (): ExportTradesReturnType => {
  const { trigger, error, isMutating } = useSWRMutation(
    "export-trades",
    CargoTrackerAPI.exportCargoRecords
  );

  return { trigger, error, isMutating };
};
