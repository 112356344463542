import { Dispatch, KeyboardEvent, ReactElement, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

import { getModuleGlyph } from '../../Models/Common';
import { CONV_CONFIG, ConversationModuleContext } from '../../Models/Enums';

import { getKeyValuePairs } from 'helpers/Utils/enum';
import { notNil } from 'helpers/Utils/misc';

import './QuestionBar.scss';

interface QuestionBarProps {
  startConversation: (content: string, moduleContext: number) => Promise<void>;
  continueConversation: (content: string) => Promise<void>;
  inputQuestion: string;
  setInputQuestion: Dispatch<SetStateAction<string>>;
  isLoadingAnswer: boolean;
  activeWorksheetId?: string | null;
  context?: ConversationModuleContext;
}

const isContentFieldSizingAvailable = CSS.supports('field-sizing', 'content');

const MODULES = getKeyValuePairs(ConversationModuleContext).map(el =>
  ({ ...el, label: CONV_CONFIG[el.value as ConversationModuleContext].label })).sort(
  (a,b) => (b.value as number) - (a.value as number));

const QuestionBar = (props: QuestionBarProps): ReactElement => {
  const {
    activeWorksheetId,
    startConversation,
    continueConversation,
    inputQuestion,
    setInputQuestion,
    isLoadingAnswer,
    context  = ConversationModuleContext.General,
  } = props;

  const [searchModule, setSearchModule] = useState<ConversationModuleContext>(ConversationModuleContext.General);

  const isConversationLoaded = notNil(activeWorksheetId);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const moduleGlyph: string = useMemo(() => getModuleGlyph(context), [context]);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if ((e.key === 'Enter' && e.shiftKey) || (e.key === 'Enter' && (e.metaKey || e.ctrlKey))) {
      // Ignore Shift+Enter or Command+Enter (or Ctrl+Enter for non-Mac systems)
      return;
    }

    if (e.key === 'Enter' && inputQuestion.trim() !== '') {
      handleSubmit();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleSubmit = (): void => {
    if (isLoadingAnswer) {
      return;
    }

    setInputQuestion('');
    isMobile && inputRef.current?.blur();
    if (!isConversationLoaded) {
      startConversation(inputQuestion, searchModule);
    } else {
      continueConversation(inputQuestion);
    }
  };

  useEffect(() => {
    setSearchModule(ConversationModuleContext.General);
  }, [activeWorksheetId]);

  useEffect(() => {
    // Focus input when switching WS (only on non mobile so keyboard is not shown automatically)
    !isMobile && inputRef.current?.focus();
  }, [activeWorksheetId, isConversationLoaded, isMobile]);

  // Custom autoResize as with PrimeReact one there was sometimes error "Cannot set properties of undefined (setting 'overflow')"
  // Once 'field-sizing:content' will be available across iOS/Safari, we can remove this useEffect
  // https://caniuse.com/?search=field-sizing%3A%20content
  useEffect(() => {
    if (inputRef.current && !isContentFieldSizingAvailable) {
      inputRef.current.style.height = 'auto'; // Reset height
      inputRef.current.style.height = `${ inputRef.current.scrollHeight }px`;
    }
  }, [inputQuestion]);

  return (
    <div className='ma-question'>
      {!isConversationLoaded && <p className='ma-question-description'>What can I help with?</p>}
      <div className='ma-question-bar p-inputgroup'>
        <div className='ma-question-bar-dropdown-container'>
          {!isConversationLoaded && <Dropdown
            appendTo='self'
            className='p-inputgroup-addon'
            onChange={ e => setSearchModule(e.value)}
            options={MODULES}
            value={searchModule}
          />}
        </div>
        <div className='ma-question-bar-input'>
          <InputTextarea
            placeholder='Ask me anything'
            value={inputQuestion}
            onChange={e => setInputQuestion(e.target.value)}
            /* onKeyDown is not in specs but is passed and works
            // @ts-ignore */
            onKeyDown={handleKeyDown}
            ref={inputRef}
            rows={1}
            autoFocus={!isMobile}
          />
          <Button
            icon='iconoir-arrow-up'
            disabled={inputQuestion.trim() === ''}
            onClick={handleSubmit}
            loading={isLoadingAnswer}
          />
        </div>
      </div>
      {isConversationLoaded &&
        <div className='ma-question-context'>
          You are asking in
          <i className={`iconoir-${ moduleGlyph } icon--tiny`}></i>
          {CONV_CONFIG[context].label}
        </div>
      }
      <div className={clsx('ma-question-warning', {'warning-bottom': !isConversationLoaded})}>
        Assistant is still under active development and learning. Mistakes are possible
      </div>
    </div>
  );
};

export default QuestionBar;