import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate, useMatches } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import { SortOrder } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { DataTable, type DataTableProps, type DataTableRowClickEvent, type DataTableSortEvent } from 'primereact/datatable';
import { DataView } from 'primereact/dataview';
import { Menu } from 'primereact/menu';
import { type MenuItem } from 'primereact/menuitem';
import { Column, type ColumnSortEvent, type ColumnProps } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useDebounce } from 'primereact/hooks';
import { clsx } from 'clsx';

import { useSignalR } from 'App';
import { UISettings } from 'components/OBXUser/Model/Enums';
import SecondaryNavigation from 'components/SecondaryNavigation';
import ToastMessage, { type ToastMessageRef } from 'components/ToastMessage';
import AdditionalFilters from 'components/AdditionalFilters';
import BorealisBar from 'components/BorealisBar';
import { useSaveUserSetting, useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { replaceItemAt } from 'helpers/Utils/collections';
import { sortByDateTime, sortByNumerical } from 'helpers/DataTable/SortingFunctions';
import { DoubleLineUpdatedAuthorAndDate } from 'helpers/DataTable/Templates/ColumnTemplates';
import { type Modify, notNil } from 'helpers/Utils/misc';

import WorkflowDetails from './Components/WorkflowDetails';
import WorkflowProgressBar from './Components/WorkflowProgressBar/WorkflowProgressBar';
import CreateWorkflow from './Components/CreateWorkflow';
import { WorkflowEvents, WorkflowStatusTypeEnum, WorkflowDisplayMode, WorkflowProvider } from './Models/Enums';

import { WorkflowApi } from './Services/WorkflowApi';

import { apiSupportedProvider, apiWorkflowResponse, Workflow } from './Models';

import { useProviders, useSearchWorkflows, useStatusOptions } from './Services/hooks';
import { WorkflowMutationEvent } from './Services/Events';

import { WorkflowSignalEventTypes, WorkflowSocket } from './Services/SignalRSocket';
import DeleteWorkflowDialogFooter from './Templates/DeleteDialog';
import GridItemTemplate from './Templates/GridItemTemplate';
import WorkflowEmptyPage, { EmptyPageMode } from './Templates/EmptyPage';
import NoWorkflowSelected from './Templates/NoWorkflowSelected';

import eventBus from 'server/EventBus';

import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';

import './WorkflowPage.scss';
import { Dropdown } from 'primereact/dropdown';

type SortConfig = {
    sortField: DataTableProps<Workflow[]>['sortField'];
    sortOrder: DataTableProps<Workflow[]>['sortOrder'];
    header?: string;
};

type WorkflowsSettings = SortConfig & {
    searchTerm: string;
    lastOpenStatus: string;
    justMyTasks: boolean;
    justMyWorkflows: boolean;
    provider: number
};

type ReactNodeWithProps = Modify<React.ReactNode, {props: ColumnProps}>

const DEFAULT_SORTING: SortConfig = {
  sortField: 'createdUtc,requestedByName',
  sortOrder: SortOrder.DESC,
  header: 'Requested',
};

export default function WorkflowPage() {
    const { trigger: saveSetting } = useSaveUserSetting();
    const { getSetting } = useLoadUserSettings();

    const settings: WorkflowsSettings | undefined = getSetting(UISettings.WORKFLOWS_CONFIG);

    const { providerId, statusFilter } = useParams();
    const matches = useMatches();

    const [ searchTerm, searchTermDebounced, setSearchTerm ] = useDebounce(settings?.searchTerm ?? '', 500) as [string, string, (arg?: string) => void];
    const [ workflows, setWorkflows ] = useState<Workflow[]>([]);
    const [ selectedWorkflow, setSelectedWorkflow ] = useState<Workflow | null>(null);
    const [ mode, setMode ] = useState<WorkflowDisplayMode>(WorkflowDisplayMode.None);
    const [ justMyWorkflows, setJustMyWorkflows ] = useState<boolean>(settings?.justMyWorkflows ?? false);
    const [ justMyTasks, setJustMyTasks ] = useState<boolean>(settings?.justMyTasks ?? false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    
    const toast = useRef<ToastMessageRef>(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

    const containerRef = useRef<HTMLDivElement>(null);
    const wftable = useRef<DataTable<any>>(null);
    const sortByMenu  = useRef<Menu>(null); 

  
    const gestures = useSwipeable({
        onSwipedRight: (): void => {
            unselectWorkflow();
        },
    });

    const initialSort: SortConfig = useMemo(
      () => {

        /** 
         * Pull the sort settings from the users profile OR if there isn't one
         * fall back to the default
         */
        const { sortField , sortOrder, header } = settings ?? { sortField: 'createdUtc', sortOrder: SortOrder.DESC, header: 'Progress'}

        return {
          ...DEFAULT_SORTING,
          sortField, sortOrder, header
        }
      }, []
    );

    // store config in state to react immidiatelly on sort config change
    const [ sortConfig, setSortConfig ] = useState<SortConfig>(initialSort);
    const [ sortableColumns, setSortableColumns ] = useState<MenuItem[]>([]);

    const { signal } = useSignalR();

    const getProviderId = () => {
        if (!providerId) {
            return WorkflowProvider.Onboarding;
        }
        return +providerId;
    };

    const { data: workflowResults, isLoading: workflowsLoading, isValidating: workflowsValidating, mutate: getWorkflows } = useSearchWorkflows({
        workflowProvider: getProviderId(),
        searchTerm: searchTerm ?? '',
        justMyWorkflows,
        justMyTasks
    });

    const statusOptions = useStatusOptions(getProviderId());
    const { providers } = useProviders();

    const availableStatuses = useMemo(() =>
        statusOptions.map(({ value }) => +value),
        [statusOptions]
    );

    const [ selectedProvider, setSelectedProvider ] = useState<apiSupportedProvider>();

    useEffect(() => {
      if (!providers || !providerId) return;
      setSelectedProvider( providers.find(({id}) => id === +providerId))
    }, [ providers ])

    useEffect(() => {
      if (!selectedProvider) return;

      let lastOpenedStatus: WorkflowStatusTypeEnum;

      if (notNil(settings?.lastOpenStatus) && selectedProvider.statusTypes.includes(+settings!.lastOpenStatus)) {
        lastOpenedStatus = +settings!.lastOpenStatus;
      } else {
        lastOpenedStatus = WorkflowStatusTypeEnum.NotStarted;
      }

      navigate(`${matches[1].pathname}/${selectedProvider.id}/${lastOpenedStatus}`);

    }, [selectedProvider])

    const itemRenderer = (item: MenuItem) => {
      const handleReSort = (
        sortOrder: DataTableProps<Workflow[]>['sortOrder']
      ) => {
        const { header, sortField } = item.data;
        setSortConfig({ header, sortField, sortOrder });
      };

      return (
        <div className='p-menuitem-content p-workflow-sorting-item'>
          <span>
            <strong>{item.data.header}</strong>
          </span>
          <Button
            text
            size='small'
            icon={clsx(
              'iconoir-sort-down icon--small',
              item.data.sortOrder === SortOrder.DESC && 'icon--ob-orange'
            )}
            onClick={() => handleReSort(SortOrder.DESC)}
          />
          <Button
            text
            size='small'
            icon={clsx(
              'iconoir-sort-up icon--small',
              item.data.sortOrder === SortOrder.ASC && 'icon--ob-orange'
            )}
            onClick={() => handleReSort(SortOrder.ASC)}
          />
        </div>
      );
    };

    useEffect(() => {
  
      setSelectedWorkflow( current => {
        if (!current) return null;
        return workflows.find(({id}) => id === current.id)! 
      })

    }, [workflows])

    useEffect(() => {
      if (wftable.current) {
        /** Create a strucutred list of the columns which are configurared as */
        /** sortable, structured as MenuItem instances */
        const { children, sortField: currentField, sortOrder: currentSortOrder } = wftable.current.props;

        const cols = (children as any[]).reduce<MenuItem[]>((a, c) => {
          
          const { sortable, field, header } = c.props;
          const sortOrder = field === currentField ? currentSortOrder : SortOrder.UNSORTED
          const data = { header, sortOrder, sortField: field };

          return sortable ? [...a, { label: header, data, template: itemRenderer}] : a ;
        }, [])

        setSortableColumns([
          ...cols,
          {
            template: (
              <div className='p-workflow-sorting-menu-footer'>
                <Button
                  disabled={!sortConfig || sortConfig?.sortOrder === SortOrder.UNSORTED}
                  text
                  onClick={() =>
                    setSortConfig(c => ({
                      ...c,
                      sortOrder: SortOrder.UNSORTED,
                    }))
                  }
                >
                  clear
                </Button>
              </div>
            ),
          },
        ]);
      }
    }, [workflows, sortConfig])


    useEffect(() => {
        if (workflowResults) {

            const filteredResults = workflowResults.filter(x => x.overallStatus === parseInt(statusFilter || '0'));

            setWorkflows(filteredResults);

            if (!filteredResults.length && mode !== WorkflowDisplayMode.Create) {
                unselectWorkflow();
            }
        } else {
            setWorkflows([]);
        }
    }, [mode, statusFilter, workflowResults]);

    useEffect(() => {
        setIsLoading(workflowsLoading || workflowsValidating);
    }, [workflowsLoading, workflowsValidating]);

    useEffect(() => {

        saveSetting({
            setting: UISettings.WORKFLOWS_CONFIG,
            data: {
                sortField: sortConfig.sortField,
                sortOrder: sortConfig.sortOrder,
                header: sortConfig.header,
                justMyTasks,
                justMyWorkflows,
                lastOpenStatus: statusFilter,
                searchTerm: searchTermDebounced, // use debounced value to limit `saveSatting` calls,
                provider: selectedProvider?.id
            } as WorkflowsSettings
        });
        // eslint-disable-next-line
    }, [justMyTasks, justMyWorkflows, searchTermDebounced, sortConfig, statusFilter, selectedProvider]);

    useEffect(() => {
        // re-fetch Workflows data when any param changes
        getWorkflows();
    }, [searchTerm, justMyTasks, justMyWorkflows, getWorkflows]);

    useEffect(() => {

        let socket: WorkflowSocket;
        socket = WorkflowSocket.instance;
        socket.init(signal);

    }, [signal]);

    useEffect(() => {

        eventBus.on(
            WorkflowSignalEventTypes.WORKFLOW_CHANGED,
            handleSignalRUpdate
        );

        eventBus.on( WorkflowEvents.Mutation, handleMutationMessage )
        
        return () => {
          eventBus.remove( WorkflowEvents.Mutation, handleMutationMessage )
          
          eventBus.remove(
                WorkflowSignalEventTypes.WORKFLOW_CHANGED,
                handleSignalRUpdate
            );
        };

        // eslint-disable-next-line
    }, []);

    const handleMutationMessage = (event: CustomEvent<WorkflowMutationEvent>) => {
      
      const { detail } = event;

      setIsLoading(detail.isMutating);
    }

    const handleSignalRUpdate = (data: CustomEvent<apiWorkflowResponse>) => {
      if (!data.detail.isDeleted) {
        setWorkflows((workflows) => {
          const index = workflows.findIndex(x => x.id === data.detail.id);

          if (index > -1) {
              return replaceItemAt(workflows, WorkflowApi.parseWorkflow(data.detail), index);
          }

          return workflows;
        });
      }
    };

    const handleSortChange = ({ sortField, sortOrder }: DataTableSortEvent): void => {

      const { children } = wftable.current!.props;

      // TODO - 'any' here is smelly. ReactNode props property
      const { props } = (children as ReactNodeWithProps[]).find(({props}) => props.field === sortField)!

      setSortConfig({ sortField, sortOrder, header: props.header as string });
    };

    const selectWorkflow = (item: Workflow): void => {
      setSelectedWorkflow(item);
      setMode(WorkflowDisplayMode.Edit);    
    };

    const onWorkflowDeleted = (workflowid?: string) => {
      if (!selectedWorkflow || !workflowid) return;

      /** If the recently deleted item item was the one selected */
      /** then we have to de-selected it */
      if (selectedWorkflow.id === workflowid) {
        unselectWorkflow();
      }
    }

    const unselectWorkflow = (): void => {
        setSelectedWorkflow(null);
        setMode(WorkflowDisplayMode.None);
    };

    const searchAndFiltering = useMemo(() => {
        let content = <div className='header__search-block'>
            <InputText 
              placeholder='Search by keywords' 
              value={searchTerm} 
              onChange={(e) => setSearchTerm(e.currentTarget.value)} className='search-input' 
            />
            <AdditionalFilters
                className='workflow__additional-filter'
                filterCount={Number(justMyTasks) + Number(justMyWorkflows) || undefined}
            >
                <div className='filter-item'>
                    <Checkbox checked={justMyWorkflows} onChange={(e => setJustMyWorkflows(!!e.checked))} />
                    <small>My Workflows</small>
                </div>
                <div className='filter-item'>
                    <Checkbox checked={justMyTasks} onChange={(e => setJustMyTasks(!!e.checked))} />
                    <small>My Outstanding Tasks</small>
                </div>
            </AdditionalFilters>
        </div>;

        if (isMobile) {
            if (mode !== WorkflowDisplayMode.None) {
                return null; // no header if aside is open
            }
            return <Accordion activeIndex={0}>
                <AccordionTab header='Search'>
                    {content}
                </AccordionTab>
            </Accordion>;
        }

        return content;
        // eslint-disable-next-line
    }, [isMobile, justMyTasks, justMyWorkflows, mode, searchTerm]);

    // const workflowStatusResolver = (status: string) => status.replace(/(on|for)/gi, (match) => match.toLowerCase())

    const handleTabChange = (): Promise<void> => {
        
      if (mode === WorkflowDisplayMode.Edit) {
          
        setSelectedWorkflow(null);

        setMode(WorkflowDisplayMode.NoWorkflowSelected);
      }

        // required by SecondaryNavigation component
        return Promise.resolve();
    }

    const navigationItems = useMemo(() =>
        {
          if (!statusOptions) return [];

          return statusOptions.reduce<{ path: string; label: string; }[]>(
            (acc, item) => [
              ...acc, 
              {
                path: `${providerId}/${item.value}`,
                label: `${item.key} (${workflowResults?.filter(x => x.overallStatus === +item.value).length ?? 0})`
              }
            ],
            []
          )
        },
        [providerId, workflowResults, statusOptions]
    );

    const drawerActive = useMemo(() => mode !== WorkflowDisplayMode.None, [mode]);

    return <div ref={containerRef} className='workflow-page direction--column grow-to-fill overflow--y'>
        <header>
          { searchAndFiltering }
          {!isMobile &&
            <>
              <div>
                <Dropdown 
                  options={providers}
                  optionLabel='name'
                  value={selectedProvider}
                  onChange={(e) => {
                    setSelectedProvider(e.value)
                  }}
                />
              </div>
              <div>
                <Button
                  size='small'
                  onClick={() => {
                    setMode(WorkflowDisplayMode.Create);
                    // if something selected, unselect it
                    setSelectedWorkflow(null);
                  }}
                >
                    Add workflow
                </Button>
              </div>
            </>
          }
        </header>
        { (!drawerActive && isMobile) && 
          <div>
            <label>Department</label>
            <Dropdown 
              options={providers}
              optionLabel='name'
              value={selectedProvider}
              onChange={(e) => {
                setSelectedProvider(e.value)
              }}
            />
          </div>
        }
        {!(drawerActive && isMobile) && 
          <nav className='tabbed-navigation-set__container'>
            <label>Status</label>
            <SecondaryNavigation 
              items={ navigationItems } 
              onBeforeNavigation={ handleTabChange } 
            />
          </nav>
        }
        <main
            className={clsx('grow-to-fill workflow-page__main',
                { 'drawer--active': drawerActive }
            )}
            data-cols={drawerActive ? '4,8' : '12,0'}
            data-drawer-style='slide'
            data-drawer-position='alongside-right'
        >
            {!isLoading && !searchTerm && (!workflowResults?.length || !workflows.length) 
              ? <section>
                  <WorkflowEmptyPage
                      mode={!workflowResults?.length && !(justMyTasks || justMyWorkflows) ? EmptyPageMode.EmptyResults : EmptyPageMode.EmptyStatus}
                      onAddWorkflowButtonClick={() => setMode(WorkflowDisplayMode.Create)}
                  />
                </section> 
              : <section className='grow-to-fill overflow--hidden'>
                { isMobile 
                  ? <DataView
                      gutter
                      className='workflows-page__data-view'
                      itemTemplate={(item) => <GridItemTemplate
                          availableStatuses={availableStatuses}
                          item={item}
                          scrollableContainerRef={containerRef}
                          selectWorkflow={selectWorkflow}
                      />}
                      loading={isLoading}
                      loadingIcon={<BorealisBar />}
                      value={workflows}
                    /> 
                  : <DataTable
                      ref={wftable}
                      emptyMessage={searchTerm ? 'Sorry, no results match that term' : undefined}
                      loading={isLoading}
                      onRowClick={(e: DataTableRowClickEvent) => {
                        selectWorkflow(e.data as Workflow)
                      }}
                      rowClassName={(data: Workflow) => data.id === selectedWorkflow?.id ? 'p-selected-row' : ''}
                      scrollable
                      scrollHeight='flex'
                      {...sortConfig}
                      onSort={handleSortChange}
                      value={workflows}
                      header={
                        <div>
                          <label className='grow-to-fill'>Sort by</label>
                          <Button
                            size='small'
                            icon={`icon--small p-button-icon-right ${
                              sortConfig?.sortOrder === SortOrder.ASC
                                ? 'iconoir-sort-up'
                                : sortConfig?.sortOrder === SortOrder.DESC
                                ? 'iconoir-sort-down'
                                : 'iconoir-data-transfer-both'
                            }`}
                            className='lowercase'
                            onClick={(e) => {														
                              sortByMenu.current?.toggle(e)
                            }}
                            text
                          >
                          {sortConfig?.sortOrder ? sortConfig.header : null}
                          </Button>
                          <Menu
                            className='p-workflow-sorting-menu'
                            popupAlignment='right'
                            model={sortableColumns}
                            popup
                            ref={sortByMenu}
                          />
                        </div>
                      }
                    >
                      <Column
                        field='title'
                        header='Title'
                        sortable
                      />
                      <Column
                        field='inProgressCount'
                        header='Progress'
                        body={(data: Workflow) => (
                          <WorkflowProgressBar
                            className='p-workflow-progressbar'
                                availableStatuses={availableStatuses}
                            statuses={data.taskStatusCounts}
                            totalCount={data.totalCount}
                          />
                        )}
                        sortable
                        sortFunction={(e) => sortByNumerical(e, 'inProgressCount')}
                      />
                      <Column
                        field='createdUtc,requestedByName'
                        header='Requested'
                        body={DoubleLineUpdatedAuthorAndDate}
                        sortable
                        sortFunction={(e: ColumnSortEvent): ReturnType<typeof sortByDateTime> => sortByDateTime(e, 'createdUtc')}
                      />
                      <Column
                        field='lastModified,lastModifiedUserName'
                        header='Updated'
                        body={DoubleLineUpdatedAuthorAndDate}
                        sortable
                        sortFunction={(e: ColumnSortEvent): ReturnType<typeof sortByDateTime> => sortByDateTime(e, 'lastModified')}
                      />
                      <Column
                        body={(data: Workflow) => <Button
                        text size='small'
                        icon='iconoir-trash icon--small icon--right'
                        className='no--background'
                        onClick={() => {
                          eventBus.dispatch(
                            GlobalDialogDisplayEvents.DISPLAY,
                            {
                              body: <><p>Are you sure you want to permanently delete this workflow?</p>
                              <p>You can't undo this.</p></>,
                              footer: <DeleteWorkflowDialogFooter
                                workflow={data}
                                callback={onWorkflowDeleted}
                              />,
                              size: 'small',
                              header: 'Delete Workflow'
                            }
                          )
                        }}
                        />
                      }
                      />
                    </DataTable>}
                </section>
            }
                { (mode !== WorkflowDisplayMode.None ) &&
                  <aside {...gestures}>
                    {drawerActive && 
                    <>
                      { mode === WorkflowDisplayMode.NoWorkflowSelected && 
                          <NoWorkflowSelected
                            closePanel={unselectWorkflow}
                          />
                        }
                        { mode === WorkflowDisplayMode.Create && 
                          <CreateWorkflow
                            getProviderId={getProviderId}
                            closePanel={unselectWorkflow}
                          />
                        }
                        { mode === WorkflowDisplayMode.Edit && selectedWorkflow && 
                          <WorkflowDetails
                            workflow={selectedWorkflow}
                            setSelectedWorkflow={setSelectedWorkflow}
                            availableStatuses={statusOptions.map(({ value }) => +value)}
                            closePanel={unselectWorkflow}
                          />
                        }
                    </>
                    }
                  </aside>
                }
        </main>
        <ToastMessage ref={toast} />
    </div>;
}