import {
  ReactElement,
  RefObject,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import clsx from 'clsx';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';

import { UISettings } from 'components/OBXUser/Model/Enums';
import { useLoadUserSettings, useSaveUserSetting } from 'components/OBXUser/Services/ProfileHooks';

import { getFirstAvailableRoute } from '../../Models/TopMenuRoutes';
import { Route } from '../../Models/Types';

import './BentoMenu.scss';

interface BentoMenuProps {
  modules: Route[];
  bentoMenuOverlayRef: RefObject<OverlayPanel>;
  assignedSecurityRights: number[];
  lowerLevelEnvironment: boolean;
}

const BentoMenu = (props: BentoMenuProps):ReactElement => {
  const { modules, bentoMenuOverlayRef, assignedSecurityRights, lowerLevelEnvironment } = props;

  const [defaultModule, setDefaultModule] = useState<string>();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const { getSetting } = useLoadUserSettings();
  const { trigger: saveUserSettings } = useSaveUserSetting();
  const currentRoute: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  const bentoButtonRef = useRef<Button>(null);

  const showBentoMenu = (e: SyntheticEvent):void => {
    bentoMenuOverlayRef.current?.toggle(e);
  };

  const defaultUserRoute = getSetting(UISettings.DEFAULT_ROUTE);

  const slideLeftGesture = useSwipeable({
    onSwipedLeft: bentoMenuOverlayRef.current?.hide,
  });

  const slideRightGesture = useSwipeable({
    onSwipedRight: (e): void =>
      bentoMenuOverlayRef.current?.show(e as unknown as SyntheticEvent, undefined),
  });

  useEffect(() => {
    const foundDefaultUserRoute = modules.find(item => item.name === defaultUserRoute);

    if (defaultUserRoute && foundDefaultUserRoute) {
      setDefaultModule(foundDefaultUserRoute.name);
    } else {
      // No default route set or not found in available modules (lost security rights) -> set default as first available
      const firstAvailable = getFirstAvailableRoute(
        getSetting,
        lowerLevelEnvironment,
        isTabletOrMobile,
        assignedSecurityRights
      );

      if (firstAvailable) {
        saveUserSettings({
          setting: UISettings.DEFAULT_ROUTE,
          data: firstAvailable.name
        });
        setDefaultModule(firstAvailable.name);
      }
    }
    // Disabling as getSetting and defaultUserRoute as dependencies adds unnecessary checks
    // eslint-disable-next-line
  }, [assignedSecurityRights, lowerLevelEnvironment, modules, saveUserSettings]);

  const onOverlayPanelShow = (): void => {
    const observer = new MutationObserver((_, obs) => {
      obs.disconnect();
      bentoMenuOverlayRef.current?.align();
    });

    // Observe changes in subtree (content of overlay)
    bentoMenuOverlayRef.current &&
    observer.observe(bentoMenuOverlayRef.current?.getElement(), {
      subtree: true,
      attributes: true,
    });
  };

  const onOverlayPanelHide = (): void => {
    (bentoButtonRef.current as unknown as HTMLButtonElement)?.blur();
  };

  const navigateTo = (path: string): void => {
    bentoMenuOverlayRef?.current?.hide();
    navigate(path, { state: { closeWorksheets: true } });
  };

  const defaultModeOptions = useMemo(
    () =>
      modules.map(item => ({
        label: item.label,
        value: item.name
      })),
    [modules]
  );

  const saveDefaultModule = (e: DropdownChangeEvent): void => {
    setDefaultModule(e.value);
    saveUserSettings({
      setting: UISettings.DEFAULT_ROUTE,
      data: e.value
    });
  };

  // After switching to different module, when data/layout is loading hide doesn't work, this is workaround to manually hide (bug 3776)
  // Also fixes issue when clicking outside on some elements like ContextMenu or Scrollbar (bug 3770)
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent | TouchEvent): void => {
      const overlay = bentoMenuOverlayRef.current;
      const overlayElement = overlay?.getElement();
      const button = bentoButtonRef.current as unknown as HTMLButtonElement;
      const target = event.target as Node;
      if (
        overlay?.isVisible() &&
        !overlayElement?.contains(target) &&
        !button?.contains(target)
      ) {
        overlay.hide();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    return (): void => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [bentoMenuOverlayRef]);

  return (
    <div className='bento-menu__container' {...(isTabletOrMobile ? slideRightGesture : {})}>
      <Button
        icon='iconoir-bento icon--tiny'
        text
        className='bento'
        onClick={showBentoMenu}
        ref={bentoButtonRef}
      />

      <OverlayPanel
        ref={bentoMenuOverlayRef}
        onShow={onOverlayPanelShow}
        onHide={onOverlayPanelHide}
        className='bento-menu__overlay'
        appendTo='self'
        transitionOptions={isTabletOrMobile ? {
          classNames: 'slide',
          timeout: { enter: 300, exit: 300 },
        } : undefined}
      >
        <div className='bento-menu__slide' {...(isTabletOrMobile ? slideLeftGesture : {})}>
          <header>
            <div className='default-module-container'>
              <label htmlFor='default-module'>Default Module</label>
              <Dropdown
                id='default-module'
                value={defaultModule}
                onChange={saveDefaultModule}
                options={defaultModeOptions}
                appendTo='self'
                focusOnHover={false}
              />
            </div>
          </header>
          <div className='bento-menu__items'>
            {/* If mobile version and flag hideOnMobile is set to true -> don't show icon on mobile & native app */}
            {modules.filter(route => !(isTabletOrMobile && route.hideOnMobile)).map(ic => {
              const pathnameSplit = currentRoute.pathname.split('/');
              const isCurrent: boolean =
                  [0, 1].includes(ic.route.indexOf(pathnameSplit[1])); // First found route (sanctions to match /sanctions/vessel but not surveillance/sanctions/shipping-fixtures)
              // const isPrevious = ic.route.indexOf(currentRoute.state?.from?.split('/')?.[1]) !== -1;

              return (
                <Button
                  text
                  key={`${ ic.name }`}
                  onClick={() => navigateTo(ic.route)}
                  className={clsx('bento-menu__item plain-text', {
                    active: isCurrent,
                  })}
                  icon={`iconoir-${ ic.glyph } icon--medium`}
                >
                  {ic.label}
                  {defaultModule === ic.name && <Badge
                    className='iconoir-pin icon--mini'
                    severity='info'
                  />}
                </Button>
              );
            })}
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default BentoMenu;