import {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Badge } from 'primereact/badge';
import { Checkbox } from 'primereact/checkbox';
import { ContextMenu } from 'primereact/contextmenu';
import { Menu } from 'primereact/menu';
import { MenuItem, MenuItemCommandEvent } from 'primereact/menuitem';

import styles from './FilteringHeader.module.scss';

type ContextHeaderItem = {
  label: string;
  value: boolean;
  onChanged: (value: boolean) => void;
};

type ContextHeaderProps = {
  label: string;
  items?: ContextHeaderItem[] | Record<string, ContextHeaderItem[]>;
  className?: string;
  menuClassName?: string;
};

const MAX_FILTERS_COUNT = 99;

const FilteringHeader = ({
  label,
  items,
  className,
  menuClassName,
}: ContextHeaderProps): ReactNode => {
  const [mainMenuItems, setMainMenuItems] = useState<MenuItem[] | undefined>();
  const [secondaryMenuItems, setSecondaryMenuItems] = useState<
    MenuItem[] | undefined
  >();

  const mainMenu = useRef<Menu>(null);
  const secondMenu = useRef<ContextMenu>(null);

  const filtersCount = useMemo(() => {
    const filters = items
      ? Array.isArray(items)
        ? items
        : Object.values(items).flat()
      : [];
    return filters.filter(({ value }) => value).length;
  }, [items]);

  const mapHeaderItem = (item: ContextHeaderItem): MenuItem => ({
    command: (e: MenuItemCommandEvent): void => {
      mainMenu.current?.hide(e.originalEvent);
      item.onChanged(!item.value);
    },
    template: () => (
      <>
        <Checkbox checked={item.value} />
        <label>{item.label}</label>
      </>
    ),
  });

  useEffect(() => {
    if (items) {
      if (Array.isArray(items)) {
        setMainMenuItems(undefined);
        setSecondaryMenuItems(items?.map(mapHeaderItem));
      } else {
        const mainElements = Object.keys(items);
        setMainMenuItems(
          mainElements.map(item => ({
            label: item,
            template: (): ReactNode => {
              const onClick: MouseEventHandler<HTMLDivElement> = e => {
                setSecondaryMenuItems(items[item].map(mapHeaderItem));
                secondMenu.current?.show(e);
              };
              return (
                <div
                  className={clsx(styles.paddingLarge, styles.menuItem)}
                  onClick={onClick}
                >
                  {item}
                </div>
              );
            },
          }))
        );
      }
    }
  }, [items]);

  const onIconClick: MouseEventHandler<HTMLDivElement> = e => {
    if (mainMenuItems?.length && mainMenu.current) {
      mainMenu.current.show(e);
    } else if (secondaryMenuItems?.length && secondMenu.current) {
      secondMenu.current.show(e);
    }
  };

  return (
    <div
      className={clsx(styles.filteringControl, className)}
      onClick={onIconClick}
      onContextMenu={onIconClick}
    >
      <Menu
        className={clsx(
          'text--capitalize',
          styles.filteringControlContextMenu,
          menuClassName
        )}
        popup
        popupAlignment='right'
        ref={mainMenu}
        model={mainMenuItems}
      />
      <ContextMenu
        className={clsx(styles.filteringControlContextMenu, menuClassName)}
        ref={secondMenu}
        model={secondaryMenuItems}
      />
      <div>{label}</div>
      <i className='p-overlay-badge iconoir-filter icon--small' />
      {!!filtersCount && (
        <Badge
          value={
            filtersCount > MAX_FILTERS_COUNT
              ? `${ MAX_FILTERS_COUNT }+`
              : filtersCount
          }
        />
      )}
    </div>
  );
};

export default FilteringHeader;
export { FilteringHeader };
