import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { ContextMenu } from 'primereact/contextmenu';
import { MenuItem } from 'primereact/menuitem';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import { SelectItem } from 'primereact/selectitem';

import { Frequency, State } from '../../Models/Enums';
import { SubscriptionsResponse } from '../../Models/ReportsResponse';

import { useAddSubscription, useGetSubscriptions, useUpdateSubscription } from './Services/SubscribePanelService';
import SubscribePanelRow from './SubscribePanelRow';

import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';

import './SubscribePanel.scss';

export const SUBSCRIBE_PERIODS: SelectItem[] = [
  { label: 'Hourly', value: Frequency.Hourly, disabled: false },
  { label: 'Daily - 06:00 PM', value: Frequency.Daily, disabled: false },
  { label: 'Weekly - Fridays at 02:00 PM', value: Frequency.Weekly, disabled: false }
];

type SubscribePanelProps = {
  isDateSet: boolean;
  activeWorksheetId?: string | null;
}

const MAX_SUBSCRIPTION_AMOUNT = 3; // Max 3 subscriptions per worksheet ID possible (Hourly, Daily, Weekly)

const SubscribePanel = (props: SubscribePanelProps): JSX.Element => {
  const { isDateSet, activeWorksheetId } = props;
  const [ isPanelOpen, setIsPanelOpen ]= useState(false);
  const [ subscriptions, setSubscriptions ]= useState<SubscriptionsResponse[]>([]);
  const [ contextMenuItems, setContextMenuItems] = useState<MenuItem[]>([]);
  const [ updatedItemIndex, setUpdatedItemIndex ] = useState<number | null>(null);
  const [ draftFrequency, setDraftFrequency] = useState<Frequency>();

  const { data } = useGetSubscriptions(activeWorksheetId);
  const { trigger: triggerAdd, isMutating: isMutatingAdd } = useAddSubscription(activeWorksheetId);
  const { trigger: triggerUpdate, isMutating: isMutatingUpdate } = useUpdateSubscription(activeWorksheetId);

  useEffect(() => {
    setUpdatedItemIndex(null);
    setSubscriptions(data ? data : []);
  }, [activeWorksheetId, data]);

  const addSubscription = async (newFrequency: Frequency): Promise<void> => {
    const response = await triggerAdd({
      frequency: newFrequency,
      worksheetId: activeWorksheetId ?? '',
    });
    response && setSubscriptions([ ...subscriptions, response ]);
  };

  const updateSubscription = async (updatedSubscription: SubscriptionsResponse, index: number): Promise<void> => {
    setUpdatedItemIndex(index);
    const response = await triggerUpdate(updatedSubscription);

    if (response?.state === State.Deleted) {
      setSubscriptions(removeItemAt(subscriptions, index ));
    } else {
      response && setSubscriptions(replaceItemAt(subscriptions, response, index ));
    }
    setUpdatedItemIndex(null);
  };

  const togglePanel = ():void => {
    setIsPanelOpen(open => !open);
  };

  const PanelHeader: JSX.Element = <>
    Subscription to Report Updates
    <Button
      text
      icon="iconoir-xmark icon--tiny"
      className="subscribe-panel__close_button"
      onClick={togglePanel}
    /></>;

  const contextMenu = useRef<ContextMenu>(null);

  const isInactive = subscriptions.every(e => e.state === State.Muted);
  const isNotFull = subscriptions.length < MAX_SUBSCRIPTION_AMOUNT;
  const frequencyTaken = useMemo(() =>
    subscriptions.map(s => s.frequency)
  , [subscriptions]);

  return (
    <div className="subscribe-panel">
      <Button
        icon="pi pi-bell"
        className="surveillance-subscribe"
        text
        size="small"
        onClick={togglePanel}
      >
        {subscriptions.length === 0 ? 'Subscribe' : 'Subscribed'}
        {subscriptions.length > 0 &&
          <Badge
            className={clsx('icon--mini', isInactive ? 'iconoir-xmark' : 'iconoir-check')}
            severity={isInactive ? 'danger' : 'success'}
          />}
      </Button>
      <Panel
        toggleable={false}
        header={PanelHeader}
        className={clsx({ hidden: !isPanelOpen, 'info-message': isDateSet })}
      >
        {isDateSet && <Message text="Updates may be empty due to the date, and time range applied" />}
        {subscriptions.map((subscription, index) =>
          subscription.state !== State.Deleted && <SubscribePanelRow
            key={subscription.id}
            index={index}
            subscription={subscription}
            contextMenu={contextMenu}
            setContextMenuItems={setContextMenuItems}
            frequencyTaken={frequencyTaken}
            addSubscription={addSubscription}
            isMutating={isMutatingUpdate && updatedItemIndex === index}
            updateSubscription={updateSubscription}
            draftFrequency={draftFrequency}
          />
        )}
        {isNotFull && <SubscribePanelRow
          frequencyTaken={frequencyTaken}
          index={subscriptions.length}
          isDraft={true}
          addSubscription={addSubscription}
          isMutating={isMutatingAdd}
          draftFrequency={draftFrequency}
          setDraftFrequency={setDraftFrequency}
        />}
      </Panel>
      <ContextMenu ref={contextMenu} model={contextMenuItems} className="subscribe-panel-context-menu" />
    </div>
  );
};

export default SubscribePanel;