import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';

import eventBus from 'server/EventBus';
import { DistListSignalEventTypes } from 'modules/DistList/Services/SignalRSocket';
import { SingleLineUnknown } from 'helpers/DataTable/Templates/ColumnTemplates/SingleLineUnknown';

import { DL_MESSAGES_STATUS_LABEL, DLMessagesStatus } from '../../Models/Enums';

import CreateNewButton from './CreateNewButton';
import DroppedTooltip from './DroppedTooltip';
import EmptyMessage from './EmptyMessage';
import FooterTemplate from './FooterTemplate';
import HeaderTemplate from './HeaderTemplate';
import ResendButton from './ResendButton';

import { shouldShowResendButton } from 'modules/DistList/Helpers';

import type { DistributionListMessagesSearchResponseFlat } from '../../Models/distribution-list-response';
import type { DistributionList } from 'modules/DistList/Models/distribution-list-response';
import type { GetRecipientsParams } from 'modules/DistList/Models/distribution-list-create-request';

interface MessageDesktopProps {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSubjectSearch: Dispatch<SetStateAction<string | undefined>>;
  isLoading: boolean;
  isCreateDisabled: boolean;
  handleClose: () => void;
  onMessageResend: (data: DistributionListMessagesSearchResponseFlat) => void;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  messagesUngrouped: DistributionListMessagesSearchResponseFlat[] | undefined;
  getRecipients: (d: GetRecipientsParams) => Promise<void>;
  messageIdForRecipientsToLoad: string | null;
  activeDlId?: DistributionList['id'];
}

const MessageDesktop = (props: MessageDesktopProps): JSX.Element => {
  const {
    onMessageResend,
    searchValue,
    setSearchValue,
    setSubjectSearch,
    isLoading,
    isCreateDisabled,
    handleClose,
    activeDlId,
    setActiveDetailTab,
    messagesUngrouped,
    getRecipients,
    messageIdForRecipientsToLoad
  } = props;

  const [expandedRows, setExpandedRows] = useState<DistributionListMessagesSearchResponseFlat[]>([]);

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  useEffect(() => {
    // reset expanded rows on the DL change
    setExpandedRows([]);
  }, [activeDlId]);

  useEffect(() => {
    const onSubjectFound = (e: CustomEvent<DistributionListMessagesSearchResponseFlat>): void => {
      // expand proper row
      setExpandedRows([e.detail]);
    };

    eventBus.on(DistListSignalEventTypes.DIST_LIST_SUBJECT_FOUND, onSubjectFound);

    return (): void => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_SUBJECT_FOUND, onSubjectFound);
    };
  });

  const onRowToggle = (e: { data: DistributionListMessagesSearchResponseFlat[]; }): void => {
    setExpandedRows(e.data);

    const expandedRow = e.data.find(i => !expandedRows.includes(i));

    if (expandedRow) {
      getRecipients({ id: expandedRow.id, distributionListId: expandedRow.distributionListId });
    }
  };

  return (
    <>
      <div className="dl-messages-container grow-to-fill">
        <div className="dl-messages-search-container">
          <span className="p-input-icon-left grow-to-fill">
            <i
              className={clsx('pi', {
                'pi-spinner pi-spin': isLoading,
                'pi-search': !isLoading,
              })}
            />
            <InputText
              placeholder="Search by subject"
              value={searchValue}
              onChange={(e): void => setSearchValue(e.target.value)}
              onBlur={(e): void => setSubjectSearch(e.target.value)}
              onKeyUp={(e): boolean | void => e.key === 'Enter' && (e.target as HTMLInputElement).blur()}
              className="dl-search-input"
            />
          </span>
          <CreateNewButton activeDlId={activeDlId} isDisabled={isCreateDisabled}/>
        </div>
        <DataTable
          className={clsx('distlist__table--messages', {
            'grow-to-fill': messagesUngrouped && messagesUngrouped.length > 0
          })}
          dataKey="idKey"
          loading={isLoading}
          value={messagesUngrouped}
          scrollable
          scrollHeight="flex"
          emptyMessage=" "
          rowGroupMode="subheader"
          expandableRowGroups
          expandedRows={expandedRows}
          groupRowsBy="id"
          onRowToggle={onRowToggle}
          rowGroupFooterTemplate={(data) => <FooterTemplate data={data} messageIdForRecipientsToLoad={messageIdForRecipientsToLoad} />}
          rowGroupHeaderTemplate={HeaderTemplate}
        >
          <Column
            header="Recipient"
            field="emailAddress"
          />
          <Column
            header="Status"
            field="status"
            body={(data): JSX.Element => !data.recipients?.length ?
              <></> :
              [DLMessagesStatus.dropped].includes(data.status) ?
                <>{DL_MESSAGES_STATUS_LABEL[DLMessagesStatus.dropped]}<DroppedTooltip /></> :
                <>{DL_MESSAGES_STATUS_LABEL[data.status as DLMessagesStatus ?? DLMessagesStatus.null]}</>}
            className="column-status"
          />
          <Column
            header="Opening IP Address"
            field="ipAddress"
            body={(data, config) => !data.recipients?.length ? <></> : SingleLineUnknown(data, config)}
          />
          <Column
            header="Attachment Downloaded"
            field="attachmentDownloaded"
            body={(data): JSX.Element => data.attachmentDownloaded ? <i className="pi pi-check"></i> : <></>}
          />
          <Column header="Action"
            className="column-action"
            body={(data): JSX.Element =>
              shouldShowResendButton(data) ?
                <ResendButton
                  data={data}
                  callback={data => onMessageResend(data as DistributionListMessagesSearchResponseFlat)}
                /> :
                <></>
            }/>
        </DataTable>
        {messagesUngrouped && messagesUngrouped.length === 0 &&
          <EmptyMessage activeDlId={activeDlId} isCreateDisabled={isCreateDisabled} setActiveDetailTab={setActiveDetailTab} />}
      </div>
      <Button
        text
        icon="iconoir-xmark icon--tiny p-button-icon-only"
        className={clsx('close-button', {hidden: isMobile})}
        onClick={handleClose}
      />
    </>
  );
};

export default MessageDesktop;
