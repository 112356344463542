import { ReactNode } from 'react';
import { Chip } from 'primereact/chip';
import { ColumnBodyOptions } from 'primereact/column';

import {
  SURVEILLANCE_MEDIA_LABEL,
  SurveillanceMedia,
} from '../../../Models/ReportsResponse';

export const MediaChip = <T,>(
  data: T,
  config: Pick<ColumnBodyOptions, 'field'>
): ReactNode => {
  const [, media] = config.field.split(',');
  const mediaData: string = data[media as keyof T] as string;

  const label = SURVEILLANCE_MEDIA_LABEL[mediaData as SurveillanceMedia];

  return label ? (
    <Chip label={label} className={`chip-${ mediaData.toLowerCase() }`} />
  ) : (
    <span className='unknown'>Unknown</span>
  );
};
