import { getValueCollection } from 'helpers/Utils/enum';

import type { SignalRApi } from 'server/signalR-api';

export enum SnactionsSignalEventTypes {
  UPDATE_SANCTION_SEARCH_HISTORY = 'updateSanctionSearchHistory'
}

export class SanctionsSocket {

  private static _instance: SanctionsSocket;

  static get instance(): SanctionsSocket {
    return this._instance || (this._instance = new this());
  }

  init = async (sr: SignalRApi): Promise<void> => {
    const events = [
      ...getValueCollection(SnactionsSignalEventTypes, false),
    ];

    events.map(event => sr.dispatchEventOnMessage(event.key));
  };
}