import { Button } from 'primereact/button';
import { MultiSelect, type MultiSelectChangeEvent } from 'primereact/multiselect';

import './WorkflowDetailsFilterOption.scss';

export type FilterOptionValue = {
  key: string;
  value: string | number;
};

interface FilterOptionProps {
  options: FilterOptionValue[];
  placeholder: string;
  setValue: (value: FilterOptionValue[]) => void;
  values: FilterOptionValue[];
  emptyMessage?: string;
}

export default function FilterOption(props: FilterOptionProps): JSX.Element {
  const { options, placeholder, setValue, values, emptyMessage } = props;

  return <MultiSelect
    appendTo='self'
    className='workflow-details-filter-option'
    onChange={(e: MultiSelectChangeEvent) => setValue(e.value)}
    options={options}
    panelFooterTemplate={options.length &&
      <div className='align--right grow-to-fill'>
        <Button
          text
          size='small'
          disabled={!values.length}
          onClick={() => setValue([])}
        >
          Clear
        </Button>
      </div>
    }
    panelHeaderTemplate={options.length ?
      <></> :
      <h3 className='workflow-details-filter-option__empty-message-header'>
        Filtering isn't available
      </h3>
    }
    emptyMessage={emptyMessage}
    placeholder={placeholder}
    optionLabel='key'
    selectedItemsLabel={`${values[0]?.key} ${values.length - 1 ? `(+${values.length - 1})` : ''}`}
    maxSelectedLabels={1}
    showSelectAll={false}
    useOptionAsValue
    value={values}
  />;
}