import { ReactNode } from 'react';
import { ColumnBodyOptions } from 'primereact/column';

import NotAvailable from 'components/NotAvailable';

export const Content = <T,>(
  data: T,
  config: Pick<ColumnBodyOptions, 'field'>
): ReactNode => {
  const [content, hasAttachement] = config.field.split(',');
  const contentData: string = data[content as keyof T] as string;
  const hasAttachmentData: string = data[hasAttachement as keyof T] as string;

  return (
    <div className='direction--row spacing--small'>
      {hasAttachmentData && (
        <p className='iconoir-attachment icon--tiny icon--sky-80' />
      )}
      {contentData ? (
        <span>{contentData}</span>
      ) : (
        <NotAvailable label='Empty' />
      )}
    </div>
  );
};
