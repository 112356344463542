import * as signalR from '@microsoft/signalr';
import { eventBus } from 'server/EventBus';
// import { getCookie } from 'typescript-cookie';

const getCookie = (cookieName: string) =>
	new RegExp(`${cookieName}=([^;]+)(;|$)`) // read until semicolon or end of the string
		.exec(document.cookie)?.[1];

export class SignalRApi {

  public hubConnection: signalR.HubConnection;

	name: string = "signalRSocket";

	private lockResolver?: (value: any) => void;
	private lockRejector?: (reason: string) => void;
	private retryCounter: number = 0;
	private isTokenAvailable: boolean = false;
	private retryTimes: Array<number> = [1000, 3000, 10000, 20000, 30000, 60000, 180000]; // 1s - 180s, then fail
	private methodList: Array<string> = [];

	constructor() {
			this.hubConnection = this.initHubConnection();

			if (navigator && navigator.locks && navigator.locks.request) {
					const promise = new Promise((res, rej) => {
							this.lockResolver = res;
							this.lockRejector = rej;
					});

					navigator.locks.request('signal_r_lock_' + new Date().toString(), { mode: "shared" }, () => {
							return promise;
					});
			}
	}

	initHubConnection(): signalR.HubConnection {
		let win: any = window;
		let endpoint = win.signalREndpoint;
		let userId = getCookie('userid') ?? '';
		let idToken = getCookie('id_token') ?? '';
		this.isTokenAvailable  = !!idToken;

		return new signalR.HubConnectionBuilder()
			.withUrl(endpoint, {
				withCredentials: false,
				headers: {
					'X-UserId': userId,
					Authorization: idToken
				},
			})
			// .withAutomaticReconnect - "Bearer $token" is sent using this instead of "$token"
			.configureLogging(signalR.LogLevel.Critical)
			.build();
	}

	connectedStateChangedListner(callback: (connectionState?: signalR.HubConnectionState) => void) {
			this.hubConnection.onclose(() => callback(this.hubConnection.state));
			this.hubConnection.onreconnected(() => callback(this.hubConnection.state));
			this.hubConnection.onreconnecting(() => callback(this.hubConnection.state));
	}

	startConnection(callback?: (connectionState: signalR.HubConnectionState) => void): Promise<void> {
			this.retryCounter++;

			if (callback) {
				callback(this.hubConnection.state);
			}

			if (this.hubConnection.state === signalR.HubConnectionState.Disconnected && this.isTokenAvailable) {
					this.hubConnection.start().then(() => {
						if (callback) {
								callback(this.hubConnection.state);
						}
					}).catch(error => {
						console.error('signalR connection start error!\n', error);
						if (this.retryTimes[this.retryCounter]) {
							setTimeout(() => {
								this.startConnection(callback);
							}, this.retryTimes[this.retryCounter]);
						} else {
							// If max timeout passed, throw error and do not retry
							throw(error);
						}
					});
			}
			return new Promise<void>((r => r()));
	}


	closeConnection(): Promise<void> {
		//  Connecrtion has closed...
    if (this.lockResolver) {
        this.lockResolver("Natural CLose");
    }
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
        return this.hubConnection.stop();
    }
    return new Promise<void>((r => r()));
	}

	handleMessage(method: string, callback: (...args: any[]) => any): void {
			this.hubConnection.on(method, callback);
	}

	restoreEvents(): void {
		this.methodList.forEach(method => this.dispatchEventOnMessage(method));
	}

	dispatchEventOnMessage(method: string): void {
		this.hubConnection.on(
			method, 
			(d) => eventBus.dispatch(method, typeof d === "string" ? SignalRApi.parse(d) : d)
		)
		this.methodList.push(method);
	}

	private static parse(content: string): any {

		if ( content.match(/((^\{)(.*))/gm) ) {
			return  ({...JSON.parse(content)});
		} else {
			return content.replace(/\\|"/g, "");
		}
	}

}