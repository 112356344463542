import type { PositionConfidentialityEnum } from '../../PositionsList/Models/Enums';
import type { CargoTrackerModeEnum, StatusEnum, UnitEnum } from './Enums';
import type { DateTime } from 'luxon';

export type Commodity = {
  productCategory?: string;
  productGrade?: string;
  product?: string;
  productGroup?: string;
  productGradeSearchId?: string;
  productCategoryAlias?: string;
  productGradeAlias?: string;
  productAlias?: string;
  productGroupAlias?: string;
};

export const COMMODITY_KEYS = [
  'productGradeAlias',
  'productCategoryAlias',
  'productGroupAlias',
  'productAlias',
  'productGrade',
  'productCategory',
  'productGroup',
  'product',
];

export type Quantity = {
  from: number;
  original: string;
  to: number;
  unit: UnitEnum; // unit enum
};

export enum LocationType {
  Search = 1, // Regular search
  Text, // Zone
}

export enum PetroleumProductEnum {
  NA = 0,
  TankerClean = 1,
  TankerDirty = 2,
  TankerBoth = 3,
}

export const PP_TEXT_MAP: { [key in PetroleumProductEnum]: string } = {
  [PetroleumProductEnum.NA]: 'unknown',
  [PetroleumProductEnum.TankerClean]: 'CPP',
  [PetroleumProductEnum.TankerDirty]: 'DPP',
  [PetroleumProductEnum.TankerBoth]: 'CPP/DPP',
};

type ZoneLocation = {
  searchId: string | null;
  value: string;
};

export type CargoTrackerLocation = {
  flagCode?: string;
  locationType: LocationType;
  portCountry?: string;
  portName?: string;
  portSignalOceanId?: string;
  zone?: ZoneLocation;
};

export type Laycan = {
  original: string;
  fromDate: string; // date-string
  toDate: string; // date-string
  fromDateParsed?: DateTime;
  toDateParsed?: DateTime;
};

export type VesselType = {
  subType?: string;
  type?: string;
};

export type CreatedOrUpdatedInfo = {
  date: string; // date-string
  userId: string;
  userName: string;
  dateParsed?: DateTime;
};

export type AssignedUser = {
  userId: string;
  userName: string;
};

export type UserRights = {
  canEditVisibility: boolean;
};

export type CargoTrackerResponse = {
  assignedUser?: AssignedUser;
  charterer: string;
  charterPeriod?: string;
  commodity?: Commodity;
  createdInfo: CreatedOrUpdatedInfo;
  discharging: CargoTrackerLocation;
  id: string;
  isUrgent: boolean;
  laycan?: Laycan;
  loading: CargoTrackerLocation;
  notes?: string;
  quantity?: Quantity;
  status: StatusEnum;
  type: CargoTrackerModeEnum;
  updatedInfo: CreatedOrUpdatedInfo;
  vesselType?: VesselType;
  confidentialityIndicator: PositionConfidentialityEnum;
  userRights: UserRights;
  petroleumProductType: PetroleumProductEnum;
  chartererSearchId?: string | null;
  code?: string;
};

export type CargoTrackerExportPayload = {
  worksheetId: string;
  type: CargoTrackerModeEnum;
};
