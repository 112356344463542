import { NavigateFunction, useNavigate } from 'react-router-dom';
import useSWR from "swr";

import { useSaveUserSetting, useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { UISettings } from 'components/OBXUser/Model/Enums';

import { BalticOrVortexaApi } from '../Services/BalticOrVortexaRatesAPI';
import BalticOrVortexaTemplate from '../Templates/BalticOrVortexa';
import { GridProvider } from '../Grids/Models/GridProvider';

import type { RatesConfig } from 'types/routes-type';
// import type { IAvailableRategrid } from '../Grids/Models/RateGrid';
import { AvailableRateGridsResponse } from '../Grids/Models/RateGridResponse';

function BalticRates(args: { items: RatesConfig[] }) {
    const { items } = args;
    const { data } = useSWR(GridProvider.BalticExchangeClient, BalticOrVortexaApi.getAvailableFeeds);
    // TODO: handle loading & error state
    const navigate: NavigateFunction = useNavigate();
    const { trigger } = useSaveUserSetting();
    const { getSetting } = useLoadUserSettings();
    const lastVisitedGrid = getSetting(UISettings.LAST_VISITED_BALTIC_SPOT) as AvailableRateGridsResponse | undefined;

    // React.useEffect(() => {
    //   if (isTabletOrMobile) {
    //     currentIndex
    //       ? mainModuleCanvas.current?.classList.add("drawer--active")
    //       : mainModuleCanvas.current?.classList.remove("drawer--active");
    //   }
    // }, [currentIndex])

    const switchDisplayedRate = (rate: AvailableRateGridsResponse) => {
        navigate(`/rates/baltic-spot/${rate.name}`);

        if (rate.name !== lastVisitedGrid?.name) {
            trigger({
                setting: UISettings.LAST_VISITED_BALTIC_SPOT,
                data: rate,
            });
        }
    }

    return <BalticOrVortexaTemplate
        data={data}
        items={items}
        lastVisitedGrid={lastVisitedGrid}
        provider={GridProvider.BalticExchangeClient}
        switchDisplayedRate={switchDisplayedRate}
    />
}

export default BalticRates;
