import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { ToastMessageRef, ToastSeverity } from 'components/ToastMessage';

import { useGetDistListById, useGetRecipients, useSearchMessages } from '../../Services/DistListService';
import { DistListSignalEventTypes } from '../../Services/SignalRSocket';

import MessageDesktop from './MessageDesktop';
import MessageMobile from './MessageMobile';

import { removeTrailingSlash, stripParams } from 'helpers/Utils/string';
import { replaceItemAt } from 'helpers/Utils/collections';
import { parsePropsToDateTime } from 'helpers/Utils/misc';

import { DLMessagesStatus } from 'modules/DistList/Models/Enums';

import eventBus from 'server/EventBus';

import type { apiMailStatusUpdatedSignalR, apiSecureMailRecipientsResponse } from 'modules/DistList/Models/apiResponse';
import type { DistributionListMessagesSearchRequest } from '../../Models/distribution-list-create-request';
import type {
  DistributionListMessagesSearchResponse,
  DistributionListMessagesSearchResponseFlat
} from '../../Models/distribution-list-response';

interface MessageProps {
  handleClose: () => void;
  toast: RefObject<ToastMessageRef>;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  activeDlId?: DistributionListMessagesSearchResponse['id'];
  dlName?: string;
  activeDetailTab?: number;
}

const Message = (props: MessageProps): JSX.Element => {
  const {activeDlId, handleClose, toast, dlName, activeDetailTab, setActiveDetailTab} = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [subjectSearch, setSubjectSearch] = useState<string>();
  const [messages, setMessages] = useState<DistributionListMessagesSearchResponse[] | undefined>(undefined);
  const [searchItems, setSearchItems] =
   useState<DistributionListMessagesSearchRequest>({ distributionListId: activeDlId ?? '' });

  const { searchResults, searchIsLoading } = useSearchMessages(searchItems);

  useEffect(() => {
    setSearchItems({
      distributionListId: activeDlId ?? '',
      subjectSearch: subjectSearch,
    });
  }, [activeDlId, subjectSearch, setSearchItems]);

  useEffect(() => {
    // Clear value on DL change
    setSearchValue('');
    setSubjectSearch(undefined);
    setMessages(undefined);
  }, [activeDlId]);

  useEffect(() => {
    if (searchResults !== undefined && !searchIsLoading) {
      setMessages(searchResults);
    }
  }, [searchIsLoading, searchResults]);

  const { emailIdParam } = useParams();
  const currentRoute = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const {data, isLoading} = useGetDistListById(activeDlId);
  const [messagesUngrouped, setMessagesUngrouped] = useState<DistributionListMessagesSearchResponseFlat[] | undefined>(undefined);
  const [messageIdForRecipientsToLoad, setMessageIdForRecipientsToLoad] = useState<string | null>(null);

  const { trigger: getRecipients, isMutating: getRecipientsMutating, error: getRecipientsError } = useGetRecipients();

  // Convert [{id: 1, recipients: [{status: a}, {status: b}]}] => [{id: 1, status: a}, {id: 1, status: b}]
  const convertMessages = useCallback((data?: DistributionListMessagesSearchResponse[]): DistributionListMessagesSearchResponseFlat[] | undefined => {
    const elements: DistributionListMessagesSearchResponseFlat[] = [];

    return data?.reduce((acc, cur) => {
      if (cur.recipients?.length) {
        return [...acc, ...cur.recipients.map((rec, i) => ({ ...cur, ...rec, recipientIndex: i, idKey: `${cur.id}_${i}` }))] as DistributionListMessagesSearchResponseFlat[];
      } else {
        return [...acc, { ...cur, recipientIndex: 0, idKey: `${cur.id}_0` }] as DistributionListMessagesSearchResponseFlat[];
      }
    }, elements);
  }, []);

  useEffect(() => {
    setMessagesUngrouped(convertMessages(messages));
  }, [convertMessages, messages]);

  useEffect(() => {
    if (emailIdParam && !isLoading && messagesUngrouped) {
      const foundMessage = messagesUngrouped?.find(c => c.id === emailIdParam);

      if (foundMessage) {
        if (!foundMessage.recipients) {
          fetchMessageWithRecipients(foundMessage);
          eventBus.dispatch(DistListSignalEventTypes.DIST_LIST_SUBJECT_FOUND, foundMessage);
        }
      } else {
        toast.current?.replace({
          title: 'Wrong Subject ID',
          message: 'Provided Subject ID in link was not found',
          severity: ToastSeverity.WARN
        });

        // remove invalid email ID from URL
        navigate(removeTrailingSlash(stripParams(currentRoute.pathname, emailIdParam)));
      }
    }
  }, [emailIdParam, isLoading, toast, messagesUngrouped]);


  const fetchMessageWithRecipients = async (data: { id: string, distributionListId: string; }): Promise<void> => {
    try {
      setMessageIdForRecipientsToLoad(data.id);

      const res = await getRecipients(data);

      setMessages(msgs => {
        if (msgs) {
          const index = msgs.findIndex(p => p.id === data.id);

          if (index !== -1) {
            return replaceItemAt(msgs, res, index);
          }
        }

        return msgs;
      });

      setMessageIdForRecipientsToLoad(null);
    } catch (e) {
      console.log('[DistList] Loading recipients error', e);
    }
  };

  const handleUpdateEmail = useCallback((event: CustomEvent<apiSecureMailRecipientsResponse>): void => {
    if (event.detail.distributionListId !== activeDlId) {
      return;
    }

    const index: number = messages?.findIndex(d => d.id === event.detail.id) ?? -1;

    if (index !== -1) {
    // fetch message details and it's recipients
    // Update existing
      fetchMessageWithRecipients({ id: event.detail.id, distributionListId: event.detail.distributionListId });
    } else {
      setMessages(msgs => {
        if (msgs) {
          // Add new item
          return [...msgs, parsePropsToDateTime(event.detail, ['messageDate'])].sort((a, b) =>
            b.messageDate.toMillis() - a.messageDate.toMillis());
        }

        return msgs;
      });
    }
    // eslint-disable-next-line
  }, [activeDlId, messages]);

  const handleUpdateStatus = (event: CustomEvent<apiMailStatusUpdatedSignalR>): void => {
    const { emailId, recipient } = event.detail;

    setMessages(msgs => {
      if (msgs) {
        const index = msgs.findIndex(m => m.id === emailId);

        if (index !== -1 && msgs[index]?.recipients) {
          const recipientIndex = msgs[index].recipients.findIndex(r => r.emailAddress === recipient.emailAddress);

          return replaceItemAt(
            msgs,
            { ...msgs[index], recipients: replaceItemAt(msgs[index].recipients, recipient, recipientIndex) },
            index
          );
        }
      }

      return msgs;
    });
  }

  useEffect(() => {
    eventBus.on(DistListSignalEventTypes.DIST_LIST_EMAIL_UPDATED, handleUpdateEmail);
    eventBus.on(DistListSignalEventTypes.DIST_LIST_EMAIL_RECIPIENT_STATUS_CHANGED, handleUpdateStatus);

    return () => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_EMAIL_UPDATED, handleUpdateEmail);
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_EMAIL_RECIPIENT_STATUS_CHANGED, handleUpdateStatus);
    };
  }, [handleUpdateEmail]);

  const onMessageResend = (data: DistributionListMessagesSearchResponseFlat): void => {
    const index: number | undefined = messages?.findIndex(i => i.id === data.id);

    if (index !== -1 && index !== undefined && messages !== undefined) {
      const updatedRecipients = replaceItemAt(messages[index].recipients,
        { ...messages[index].recipients[data.recipientIndex], status: DLMessagesStatus.processed }, data.recipientIndex);
      const updatedMessages = replaceItemAt(messages,
        { ...data, recipients: updatedRecipients }, index);
      setMessages(updatedMessages);
    }
  }

  return isMobile ?
    <MessageMobile
      onMessageResend={onMessageResend}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setSubjectSearch={setSubjectSearch}
      isLoading={isLoading || searchIsLoading}
      isCreateDisabled={!data?.recipients.length}
      handleClose={handleClose}
      activeDlId={activeDlId}
      dlName={dlName}
      activeDetailTab={activeDetailTab}
      setActiveDetailTab={setActiveDetailTab}
      messages={messages}
      messagesUngrouped={messagesUngrouped}
      getRecipients={fetchMessageWithRecipients}
      getRecipientsMutating={getRecipientsMutating}
      messageIdForRecipientsToLoad={messageIdForRecipientsToLoad}
      getRecipientsError={getRecipientsError}
    /> :
    <MessageDesktop
      onMessageResend={onMessageResend}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setSubjectSearch={setSubjectSearch}
      isLoading={isLoading || searchIsLoading}
      isCreateDisabled={!data?.recipients.length}
      handleClose={handleClose}
      activeDlId={activeDlId}
      setActiveDetailTab={setActiveDetailTab}
      messagesUngrouped={messagesUngrouped}
      getRecipients={fetchMessageWithRecipients}
      messageIdForRecipientsToLoad={messageIdForRecipientsToLoad}
    />;
};

export default Message;
