import { Dispatch, type ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableDataSelectableEvent, DataTableRowClickEvent, type DataTableRowToggleEvent,DataTableSelectionSingleChangeEvent } from 'primereact/datatable';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';

import NotAvailable from 'components/NotAvailable';
import SpecDetails from 'components/SpecDetails';

import EditableInfoField from '../EditableInfoField/EditableInfoField';
import WorkflowProgressBar from '../WorkflowProgressBar/WorkflowProgressBar';

import { formatName } from 'helpers/Utils/string';
import { replaceItemAt } from 'helpers/Utils/collections';
import { ControlBehaviour,TaskChangeType, WorkflowEvents, WorkflowStatusTypeEnum } from 'modules/Workflows/Models/Enums';
import { useAddTaskUpdate,useExportWorkflow, useStatusOptions } from 'modules/Workflows/Services/hooks';
import DeleteWorkflowDialogFooter from 'modules/Workflows/Templates/DeleteDialog';
import Task from 'modules/Workflows/Templates/Task';
import TaskHistoryInfoStatus from 'modules/Workflows/Templates/TaskHistoryInfoStatus';
import TaskSidebar from 'modules/Workflows/Templates/TaskSidebar';
import FilterOption, { type FilterOptionValue } from 'modules/Workflows/Templates/WorkflowDetailsFilterOption';
import WorkflowName from 'modules/Workflows/Templates/WorkflowName';

import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';
import eventBus from 'server/EventBus';

import type { SuggestionResponse } from 'modules/CargoTracker/Components/GroupedSearch';
import type { TaskHistoryObject } from 'modules/Workflows/Models';
import type { Workflow, WorkflowTask } from 'modules/Workflows/Models';

import './WorkflowDetails.scss';

interface WorkflowDetailsProps {
  workflow: Workflow;
  availableStatuses: number[];
  setSelectedWorkflow: Dispatch<SetStateAction<Workflow | null>>;
  closePanel: () => void;
}

const WorkflowDetails = (props: WorkflowDetailsProps): ReactNode => {

  const { workflow, availableStatuses, closePanel, setSelectedWorkflow } = props;

  const [selectedAssignees, setSelectedAssignees] = useState<FilterOptionValue[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<FilterOptionValue[]>([]);

  const { exportWorkflow, isMutating } = useExportWorkflow();
  const { updateTask, isUpdating } = useAddTaskUpdate();


  const statusOptions = useStatusOptions(workflow.provider);

  const navigate = useNavigate();

  // all assignees from all workflow's tasks
  const assigneeOptions = useMemo(() => workflow.tasks.reduce(
    (acc, curr) => (!curr.assignedToName || acc.findIndex(assignee => assignee.value === curr.assignedToName) > -1) ?
      acc :
      [...acc, { key: formatName(curr.assignedToName), value: curr.assignedToName }],
    [] as FilterOptionValue[]
  ), [workflow.tasks]);

  // expandedRows === the array of first elements of each group (row)
  const initialExpandedRow = useMemo(() => workflow.tasks.reduce(
    (acc, curr) => acc.findIndex(i => i.grouping === curr.grouping) > -1 ? acc : [...acc, curr],
    [] as WorkflowTask[]
  ), [workflow]);


  const [expandedRows, setExpandedRows] = useState<WorkflowTask[]>(initialExpandedRow ?? []);
  const [ selectedTab, setSelectedTab ] = useState<'progress' | 'info'>('progress');
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const [selectedTask, setSelectedTask] = useState<WorkflowTask | null>(null);
  // keep selected row selected even if task data has been updated
  const [ selectedRowData, setSelectedRowData ] = useState<WorkflowTask | null>(null);

  const toggleTaskSelection = (data: WorkflowTask | null) => {
    setSelectedTask(data);
    setSelectedRowData(data);
  };

  useEffect(() => {
    setSidebarVisible(!!selectedRowData);
  }, [selectedRowData]);

  useEffect(() => {
    toggleTaskSelection(null);
    setSelectedAssignees([]);
    setSelectedStatuses([]);
  }, [workflow.id]);

  useEffect(() => {

    const status: boolean = isMutating || isUpdating;

    eventBus.dispatch(WorkflowEvents.Mutation, { isMutating: status });

  }, [isMutating, isUpdating ])

  const toggleRow = (e: DataTableRowToggleEvent): void => {
    setExpandedRows(e.data as WorkflowTask[]);
  };

  const filteredTasks = useMemo(() => {
    let tasks = workflow.tasks;

    if (selectedAssignees.length > 0) {
      tasks = tasks.filter(t => selectedAssignees.some(s => s.value === t.assignedToName));
    }

    if (selectedStatuses.length > 0) {
      tasks = tasks.filter(t => selectedStatuses.some(s => s.value === t.currentStatus));
    }

    return tasks;
  }, [selectedAssignees, selectedStatuses, workflow.tasks]);

  const saveChangesToTask = async (task: WorkflowTask, comment?: string) => {

    const { provider, id: workflowId } = workflow;

    const updated = await updateTask({
      provider, task, workflowId, comment
    });

    if (updated) {
      navigate(`/workflows/${provider}/${updated.overallStatus}`);
    }

  }

  const handleStatusChange = (e: DropdownChangeEvent, task: WorkflowTask, save?: boolean): void => {
    if (e.value === 'Custom') {
      toggleTaskSelection(task);

      return;
    }

    const updatedTask = { ...task, currentStatus: e.value };
    setSelectedTask(updatedTask);

    if (save) {
      saveChangesToTask(updatedTask);
    }
  };

  const handleAssigneeChange = (change: SuggestionResponse | null, task: WorkflowTask, save?: boolean): void => {
    const newTask = { ...task, assignedToId: change?.searchEntityId ?? '', assignedToName: change?.value ?? '' };
    setSelectedTask(newTask);

    if (!change) {
      setSelectedWorkflow((wf: Workflow | null) => {
        if (!wf) return null;

        const index = wf.tasks.findIndex(t => t.taskId === newTask.taskId);

        return index > -1 ?
          { ...wf, tasks: replaceItemAt(wf.tasks, newTask, index) } :
          wf;
      });

      return;
    }

    if (save) {
      saveChangesToTask(newTask);
    }
  }

  const getStatusLabel = (status: WorkflowStatusTypeEnum): string => statusOptions.find((opt) => +opt.value === status)?.key ?? ''

  const generateTaskHistoryTitle = (item: TaskHistoryObject): ReactNode => {
    let title = <h4>{item.title}</h4>;
    let subtitle = null;

    switch (item.changeType) {
      case TaskChangeType.Assignee:
        subtitle = <small>Changed assignee to {formatName(item.assigneeName)} </small>;
        break;
      case TaskChangeType.Status:
      default:
        subtitle = <small>Changed status to "{getStatusLabel(item.key)}" </small>;
        break;
    }

    return <>
      {title}
      {subtitle}
    </>;
  };

  const generateTaskHistorySpec = (item: TaskHistoryObject): Map<string, ReactNode> => {
    const { assigneeName, changeType, grouping, key, note, previousAssigneeName, previousStatus, updatedByUserName } = item;

    switch (changeType) {
      case TaskChangeType.Assignee:
        return new Map<string, ReactNode>([
          ['Previous assignee', formatName(previousAssigneeName!)],
          ['New assignee', formatName(assigneeName)],
          ['Updated by', formatName(updatedByUserName) || <NotAvailable />],
          ['Group', grouping],
          ['Notes', note || <NotAvailable label='Have not been added' />],
        ]);
      case TaskChangeType.Status:
      default:
        return new Map<string, ReactNode>([
          ['Previous status', <TaskHistoryInfoStatus status={previousStatus ?? WorkflowStatusTypeEnum.NotStarted} label={getStatusLabel(previousStatus ?? WorkflowStatusTypeEnum.NotStarted)} />],
          ['New status', <TaskHistoryInfoStatus status={key} label={getStatusLabel(key)} />],
          ['Updated by', formatName(updatedByUserName) || <NotAvailable />],
          ['Group', grouping],
          ['Notes', note || <NotAvailable label='Have not been added' />],
        ]);
    }
  }
  const rules = useMemo(
    () =>
      workflow.properties.reduce((acc, value) => {
        const rules = value.control?.rules
          ? Array.isArray(value.control?.rules)
            ? value.control?.rules
            : [value.control?.rules]
          : [];
        for (const rule of rules) {
          const isVisible =
            rule.behaviour === ControlBehaviour.Show
              ? rule.values.includes(value.value)
              : !rule.values.includes(value.value);
          acc[rule.ref] = {
            isVisible: isVisible || (acc[rule.ref]?.isVisible ?? false),
          };
        }
        return acc;
      }, {} as Record<string, { isVisible: boolean }>),
    [workflow.properties]
  );

  const taskSpec = useMemo(
    () =>
      new Map<string, ReactNode>([
        ['', <WorkflowName key={'workflow-name'} workflow={workflow} />],
        ['Requested By', workflow.requestedByName],
        ...workflow.properties
          // rules and control display value filtering
          .filter(
            property =>
              rules[property.key]?.isVisible ??
              property.control?.display ??
              true
          )
          .map(property => [
            property.control?.mandatory ? `${ property.key }*` : property.key,
            <EditableInfoField
              key={property.key}
              id={workflow.id}
              property={property}
            />,
          ] as [string, ReactNode]),
        // eslint-disable-next-line
      ]),
    [workflow, rules]
  );


  const historyNodes = useMemo(() =>
    workflow.tasks.reduce<TaskHistoryObject[]>(
      (acc, { statusTimeStamps, ...taskData }) => {
        // if statusTimeStamps.length <= 1 -> nothing has been updated, filter out unchanged tasks
        if (statusTimeStamps.length <= 1) {
          return acc;
        }

        const updated = [
          ...acc,
          ...statusTimeStamps
            // remove last element from `statusTimeStamps` as it means 'created' time
            .slice(0, -1)
            // map timeStamps to object containing ts data and task data - simplyfied rendering
            .map(ts => ({ ...ts, ...taskData }))
        ]

        return updated;
      }, [])
      // sort data by the timeStamp `value` (means ISO datetime)
      .sort((a, b) => a.value > b.value ? -1 : a.value < b.value ? 1 : 0)
      // map items to the ReactNode - render item
      .map(item => <Accordion key={item.value.toString()}>
        <AccordionTab
          pt={{
            root: { className: 'grow-to-fill direction--column' },
            content: { className: 'grow-to-fill' }
          }}
          headerTemplate={<div className='info-tab__history--header-container space-between grow-to-fill align-items--center'>
            <div className='direction--column'>
              {generateTaskHistoryTitle(item)}
            </div>
            <span>{item.value.toFormat('dd LLL yyyy, HH:mm')} GMT</span>
          </div>}
        >
          <div className='grow-to-fill no--padding'>
            <SpecDetails
              className='info-tab__details info-tab__details--task-details grow-to-fill no--padding'
              data={generateTaskHistorySpec(item)}
            />
          </div>
        </AccordionTab>
      </Accordion>),
    // eslint-disable-next-line
    [workflow]
  );

  return <div className='grow-to-fill direction--column workflow-details'>
    {isMobile && <header className='workflow-details__header'>
      <Button
        text
        size='small'
        onClick={closePanel}
        icon='iconoir-nav-arrow-left icon--small'
        className='workflows-page__back-button plain-text'
      >
        Back to list
      </Button>
    </header>}
    <div className='workflow-details__container'>
      {isMobile &&
        <WorkflowName
          workflow={workflow}
        />
      }
      <nav className='workflow-details__nav'>
        {isMobile ?
          <Dropdown
            className='workflow-details__tab-select-dropdown'
            onChange={(e) => setSelectedTab(e.value)}
            value={selectedTab}
            options={[{ label: 'Progress', value: 'progress' }, { label: 'Info', value: 'info' }]}
          />
          :
          <>
            <Button
              size='small'
              className={clsx('p-button--tab-style', { 'active': selectedTab === 'progress' })}
              onClick={() => setSelectedTab('progress')}
            >
              Progress
            </Button>
            <Button
              size='small'
              className={clsx('p-button--tab-style', { 'active': selectedTab === 'info' })}
              onClick={() => setSelectedTab('info')}
            >
              Info
            </Button>
          </>}
        {selectedTab === 'progress' && <div className='workflow-details__filters-container'>
          <FilterOption
            options={assigneeOptions}
            placeholder='Assignee'
            setValue={setSelectedAssignees}
            values={selectedAssignees}
            emptyMessage='There are no tasks currently assigned'
          />
          <FilterOption
            options={statusOptions}
            placeholder='Status'
            setValue={setSelectedStatuses}
            values={selectedStatuses}
          />
        </div>}
        <div className='workflow-details__updated-details'>
          <i className='iconoir-clock icon--small' />
          Updated: {workflow.lastModified.toFormat('dd LLL yyyy, HH:mm')} GMT
          by {formatName(workflow.lastModifiedUserName ?? workflow.requestedByName)}
        </div>
        <Button
          text
          icon='iconoir-xmark icon--tiny p-button-icon-only'
          className='close-button'
          onClick={closePanel}
        />
      </nav>
      {selectedTab === 'progress' && <div className='progress-tab__container'>
        <WorkflowProgressBar
          className='workflow-details__progress-bar'
          isHeaderVisible={true}
          isLegendVisible={true}
          availableStatuses={availableStatuses}
          statuses={workflow.taskStatusCounts}
          totalCount={workflow.totalCount}
        />
        <DataTable
          className='grouping--no-footer row--no-border workflow-details__datatable'
          groupRowsBy='grouping'
          rowGroupMode='subheader'
          dataKey='taskId'
          expandableRowGroups
          expandedRows={expandedRows}
          onRowToggle={toggleRow}
          rowGroupHeaderTemplate={(data: WorkflowTask) => data.grouping}
          value={filteredTasks}
          showHeaders={false}
          selectionMode='single'
          // NOTE: Disable selection of rows.
          // If Assignee (autocomplete) option panel is open, pressing keyboard arrows moves the focus
          // to the next 'selectable' row instead of iterating through autocomplete options.
          // isDataSelectable={(e: DataTableDataSelectableEvent): boolean => e.data.taskId === selectedTask?.taskId}
          isDataSelectable={(e: DataTableDataSelectableEvent): boolean => e.data.taskId === selectedTask?.taskId}
          onSelectionChange={(e: DataTableSelectionSingleChangeEvent<WorkflowTask[]>) => toggleTaskSelection(e.value)}
          selection={selectedRowData}
          onRowClick={(e: DataTableRowClickEvent): void => toggleTaskSelection(e.data as WorkflowTask)}
          footer={<footer className='space-between'>
            <Button
              text
              severity='danger'
              onClick={() =>
                eventBus.dispatch(
                  GlobalDialogDisplayEvents.DISPLAY,
                  {
                    body: <><p>Are you sure you want to permanently delete this workflow?</p>
                    <p>You can't undo this.</p></>,
                    footer: <DeleteWorkflowDialogFooter
                      workflow={workflow}
                      callback={closePanel}
                    />,
                    size: 'small',
                    header: 'Delete Workflow'
                  }
                )
              }
            >
              Delete
            </Button>
            <Button
              outlined
              disabled={isMutating}
              icon="iconoir-download icon--small"
              onClick={() => exportWorkflow(workflow)}
            >
              Export
            </Button>
          </footer>}
        >
          <Column
            className='no--padding'
            body={
              (data: WorkflowTask) => <Task
                key={data.taskId}
                task={data}
                provider={workflow.provider}
                handleStatusChange={handleStatusChange}
                handleAssigneeChange={handleAssigneeChange}
              />
            }
          />
        </DataTable>
        {/* render Sidebar once here, not inside of Task component and feed it with data
        instead of rendering multiple Sidebars for each Task */}
        {selectedTask &&
          <TaskSidebar
            task={selectedTask}
            visible={sidebarVisible}
            setVisible={(visible: boolean): void => { if (!visible) toggleTaskSelection(null); }}
            position={isMobile ? 'bottom' : 'right'}
            provider={workflow.provider}
            updateTask={saveChangesToTask}
            handleStatusChange={handleStatusChange}
            handleAssigneeChange={handleAssigneeChange}
          />}
      </div>}
      {selectedTab === 'info' &&
        <div className='info-tab__container'>
          <SpecDetails
            className='info-tab__details'
            itemClassName='info-tab__details--item'
            data={taskSpec}
          />
          <div className='info-tab__history'>
            { historyNodes }
          </div>
        </div>
      }
    </div>
  </div>;
};

export { WorkflowDetails };
export default WorkflowDetails;
