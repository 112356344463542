import SecondaryNavigation from 'components/SecondaryNavigation'

import { useEffect, useState } from 'react';
import './ReportsPage.scss'
import { ReportApi, useGetReports } from './Services/ReportAPI';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report } from 'powerbi-client'
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom';
import { EmbedParamsResponse } from './Models/EmbedParamsResponse';
import { useSaveUserSetting, useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';

import { UISettings } from 'components/OBXUser/Model/Enums';


export default function ReportPage() {


    const { reportId, pageName } = useParams();
    const { data, error, isLoading } = useGetReports()

    const [embeddedReport, setEmbeddedReport] = useState<Report>();

    const [folders, setFolders] = useState<string[]>([])
    const [reports, setReports] = useState<any[]>([])
    const [currentReportId, setCurrentReportId] = useState<string>()
    const [reportParams, setReportParams] = useState<EmbedParamsResponse | undefined>(undefined);
    const [pages, setPages] = useState<any[]>([]);

    const { getSetting } = useLoadUserSettings();
    const { trigger } = useSaveUserSetting();

    const navigate: NavigateFunction = useNavigate()

    const initState = () => {
        let state = [];

        try {
            if (!currentReportId) {
                //	Get the state from the uiSettings cache
                state = getSetting(UISettings.REPORTS_LASTOPENED);

            }
        } catch (e) {
            //	seems we dont have a open worksheets state - so lets create one
            state = []
        }
        return state;
    }

    const [lastOpenedReport] = useState(initState());

    useEffect(() => {

        if (embeddedReport && currentReportId === embeddedReport?.getId() && pageName) {
            let realName = pageName.split('--')[1];
            embeddedReport.setPage(realName);
        }

    }, [pageName])

    useEffect(() => {
        if (embeddedReport) {
            embeddedReport?.on("loaded", (event) => {
                embeddedReport.getPages().then(x => {
                    let pages: any[] = []
                    let pageFpund = false;
                    x.forEach(page => {
                        console.log(page.displayName);
                        let urlPageName = `${x.indexOf(page)}--${page.name}`;

                        let tab = { path: `${embeddedReport.getId()}/${urlPageName}`, label: page.displayName }
                        pages.push(tab);

                        if (pageName === urlPageName) {
                            embeddedReport.setPage(page.name);
                            pageFpund = true;
                        }

                    });
                    setPages(pages);
                    if (!pageFpund) {
                        navigate(pages[0].path);
                    }
                });



            });
            return () => {
                embeddedReport?.off("loaded");
                embeddedReport?.off("saved");
            };
        }
    }, [embeddedReport]);

    useEffect(() => {
        let folders: string[] = [];
        data?.forEach(d => {
            if (folders.indexOf(d.folder) === -1) {
                folders.push(d.folder);
            }
        });
        setFolders(folders);

        let reportMenuItems: any[] = [];
        data?.forEach(d => {
            reportMenuItems.push({ path: d.reportId, label: d.name, folder: d.folder });
        });
        setReports(reportMenuItems);
        console.log(data);
        if (!reportId && data && data.length > 0 && !currentReportId && !lastOpenedReport) {
            console.log("1", data)
            navigate(`/reports/${data[0].reportId}`);
        } else if (!currentReportId && data && lastOpenedReport) {
            console.log("2", data, lastOpenedReport);
            navigate(`/reports/${lastOpenedReport}`);
        }


    }, [data])

    useEffect(() => {
        if (reportId) {
            setCurrentReportId(reportId);
            console.log("3", reportId)
        }
    }, [reportId])

    useEffect(() => {
        if (currentReportId) {
            ReportApi.getReport(currentReportId).then(report => {
                setReportParams(report);
            });
            trigger({
                setting: UISettings.REPORTS_LASTOPENED,
                data: currentReportId
            })
        }
    }, [currentReportId])
    return <>
        <div className='reports-page'>
            { }
            <div className='reports-menu'>
                {
                    folders.map((folder, fKey) => {
                        return <>
                            <div key={fKey} className='report-folder'>{folder}</div>
                            {
                                reports.filter(x => x.folder === folder).map((report, rKey) => {
                                    return <div onClick={() => {
                                        console.log("Moving to a new report", report.path);
                                        navigate(`/reports/${report.path}`);
                                    }} className='menu-item-container'><div className={reportId === report.path ? ' active ' : ''}></div><div key={rKey} className="report-item"><label>{report.label}</label></div></div>
                                })
                            }
                        </>
                    })
                }
            </div>
            <div className='reports-viewer'>
                <header>
                    <SecondaryNavigation items={pages} />
                </header>
                {reportParams &&
                    <PowerBIEmbed cssClassName='report-container' embedConfig={
                        {
                            type: 'report'
                            , tokenType: models.TokenType.Embed
                            , id: reportParams.embedReport[0].reportId ?? ""
                            , embedUrl: reportParams.embedReport[0].embedUrl
                            , accessToken: reportParams.embedToken.token

                            , settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: true
                                    },
                                    pageNavigation: {
                                        visible: false,
                                        position: models.PageNavigationPosition.Bottom
                                    }

                                },
                                navContentPaneEnabled: true

                            }

                        }}

                        getEmbeddedComponent={(r) => {
                            setEmbeddedReport(r as Report);
                        }}

                        eventHandlers={
                            // Handle click and propagate as custom click event to close Top / Bento Menu (bug 3770)
                            new Map([['visualClicked', (event:any) => {
                                const customClickEvent = new CustomEvent('mousedown', { detail: event.detail });
                                document.dispatchEvent(customClickEvent);
                            }]])
                        }


                    />
                }
            </div>

        </div>
    </>
}