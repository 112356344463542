import axios from 'axios';
import { DateTime } from 'luxon';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import {
  CargoSearchRequest,
  CargoUpdateRequest,
  CargoUpdateResponse,
} from '../Models/CargoTrackerRequest';
import {
  CargoTrackerExportPayload,
  CargoTrackerResponse,
  LocationType,
  PetroleumProductEnum,
} from '../Models/CargoTrackerResponse';
import fileDownload from 'js-file-download';

export const CARGO_CACHE: string = 'CargoTracker-Cargo';

export class CargoTrackerAPI {
  static getCargo = (): Promise<CargoTrackerResponse[]> =>
    axios
      .post('cargotracker/cargo/search', {})
      .then(results => this.updateCargoData(results.data))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 403, 500, 503]);

        throw e;
      });

  static updateCargoData = (
    data: CargoTrackerResponse[]
  ): CargoTrackerResponse[] => data.map(this.updateCargoTrackerResponse);

  static updateCargoTrackerResponse = (
    r: CargoTrackerResponse
  ): CargoTrackerResponse => ({
    ...r,
    laycan: {
      ...r.laycan,
      original: r.laycan?.original ?? '',
      fromDate: r.laycan?.fromDate ?? '',
      toDate: r.laycan?.toDate ?? '',
      fromDateParsed: DateTime.fromISO(`${r.laycan?.fromDate}`).toUTC(),
      toDateParsed: DateTime.fromISO(`${r.laycan?.toDate}`).toUTC(),
    },
    createdInfo: {
      ...r.createdInfo,
      dateParsed: DateTime.fromISO(`${r.createdInfo?.date}`).toUTC(),
    },
    updatedInfo: {
      ...r.updatedInfo,
      dateParsed: DateTime.fromISO(`${r.updatedInfo?.date}`).toUTC(),
    },
    code: r.code ?? '',
  });

  static updateCargo = (url: string, params: any) => {
    const { data }: { data: CargoUpdateRequest } = params.arg;

    const method: string = 'PUT';

    return axios
      .request({
        url: 'cargotracker/cargo',
        data,
        method,
      })
      .catch(e => {
        console.error('Error', e);
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
        return;
      })
      .then(r => r?.data as CargoUpdateResponse);
  };

  static deleteCargo = (url: string, params: any) => {
    const data: { ids: Array<string> } = params.arg;
    const method: string = 'DELETE';

    return axios
      .request({
        url: 'cargotracker/cargo',
        data,
        method,
      })
      .catch(e => {
        console.error('Error', e);
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
        return;
      })
      .then(r => r?.data);
  };

  static searchCargo = (
    data: CargoSearchRequest
  ): Promise<CargoTrackerResponse[]> =>
    axios
      .request({
        url: 'cargotracker/cargo/search',
        data,
        method: 'POST',
      })
      .then(results => this.updateCargoData(results.data))
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);

        throw e;
      });

  static exportCargoRecords = async (
    url: string,
    params: { arg: CargoTrackerExportPayload }
  ) => {
    console.log('exporting cargo records', params.arg);
    return axios
      .request<CargoSearchRequest, { data: Blob }>({
        method: 'POST',
        url: 'cargotracker/cargo/export',
        data: params.arg,
        responseType: 'blob',
      })
      .then(({ data }) => {
        fileDownload(
          data,
          `Cargos ${DateTime.now().toISODate()}`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        return data;
      })
      .catch(e => {
        throw e;
      });
  };

  static createEmptyRequest = (
    initialProps: CargoUpdateRequest = {}
  ): CargoUpdateRequest => ({
    id: undefined,
    type: undefined,
    status: undefined,
    charterPeriod: undefined,
    charterer: undefined,
    commodity: undefined,
    discharging: undefined,
    isUrgent: false,
    laycan: undefined,
    loading: {
      locationType: LocationType.Search,
    },
    notes: undefined,
    quantity: undefined,
    vesselType: undefined,
    assignedUser: undefined,
    confidentialityIndicator: undefined,
    userRights: undefined,
    petroleumProductType: PetroleumProductEnum.NA,
    chartererSearchId: null,
    code: '',
    ...initialProps,
  });
}
