import { useState, useMemo } from 'react';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import { Sidebar, SidebarProps } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import clsx from 'clsx';

import SingleEntitySearch from 'components/EntitySearch/SingleEntitySearch';
import AssignToMe from 'components/AssignToMe';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { WorkflowProvider, WorkflowStatusTypeEnum } from 'modules/Workflows/Models/Enums';
import { formatName } from 'helpers/Utils/string';
import { useStatusOptions } from 'modules/Workflows/Services/hooks';

import Comment from '../Comment';

import type { SuggestionResponse } from 'modules/CargoTracker/Components/GroupedSearch';
// import type { ApiWorkflowTaskResponse } from 'modules/Workflows/Models/ApiWorkflowTaskResponse';

import './TaskSidebar.scss';
import { WorkflowTask } from 'modules/Workflows/Models';

interface TaskSidebarProps {
  task: WorkflowTask;
  setVisible: (arg: boolean) => void;
  visible: boolean;
  position: SidebarProps['position'];
  updateTask: (task: WorkflowTask, comment?: string) => Promise<void>;
  handleAssigneeChange: (change: SuggestionResponse | null, task: WorkflowTask) => void;
  handleStatusChange: (e: DropdownChangeEvent, task: WorkflowTask) => void;
	provider: WorkflowProvider
}

export default function TaskSidebar(props: TaskSidebarProps): JSX.Element {
  const { task, setVisible, visible, position = 'right', updateTask, handleAssigneeChange, handleStatusChange, provider } = props;
  const [comment, setComment] = useState<string>('');

  const { obxuser } = useLoggedInUser();

	const availableStautusOptions = useStatusOptions(provider);

	const options = useMemo(() => {
		if (!availableStautusOptions) return;

		return [
			...availableStautusOptions.map(({key, value}) => ({
			value,
			label: key }))
		]
	}, [availableStautusOptions])

  const handleUpdateButtonClick = () => {
    updateTask(task, comment).then(() => setVisible(false));
  };

  return <Sidebar
    appendTo='self'
    className='task-sidebar__container'
    closeOnEscape
    header='Details'
    showCloseIcon
    onHide={() => setVisible(false)}
    position={position}
    visible={visible}
  >
    <form className='task-sidebar__form'>
      <div className='form-input__container task-sidebar__form-title'>
        <label>Title</label>
        <div>{task?.title}</div>
      </div>
      <SingleEntitySearch
        customItems={[{
          value: obxuser?.name ?? '',
          searchEntityId: obxuser?.userId ?? '',
          template: AssignToMe
        }]}
        showCustomItem={!task.assignedToId || task.assignedToId !== obxuser?.userId}
        label='Assignee'
        module={EntitySearchGroupEnum.Users}
        fields={EntitySearchFieldsEnum.User}
        initialTerm={formatName(task.assignedToName ?? '')}
        itemTemplate={(i: SuggestionResponse): string => formatName(i.value)}
        onInputClear={(): void => handleAssigneeChange(null, task)}
        showError={!task.assignedToId}
        errorVisibleAfterTouch={false}
        callback={(change: SuggestionResponse | null): void => handleAssigneeChange(change, task)}
      />
      <div className='form-input__container task-sidebar__dropdown'>
        <label htmlFor='task-status'>Status</label>
        <Dropdown
          id='task-status'
          onFocus={(e): void => e.target.select()}
          onChange={(e) => handleStatusChange(e, task)}
          options={options}
          value={task.currentStatus}
          scrollHeight='40vh'
          className={clsx(`status--${WorkflowStatusTypeEnum[task.currentStatus]}`)}
        />
      </div>
      <div className='task-sidebar__notes overflow--y'>
        <header className='task-sidebar__notes--header'>Notes</header>
        <div className='overflow--y direction--column'>
          <div className='form-input__container'>
            <InputTextarea
              rows={3}
              placeholder='Please add an optional note/comment'
              onFocus={(e): void => e.target.select()}
              onChange={(e): void => setComment(e.target.value)}
            />
          </div>
          {task.comments.map((comment, index) => <Comment key={`comment.${index}`}comment={comment} />)}
        </div>
      </div>
    </form>
    <div className='task-sidebar__footer'>
      <Button
        disabled={!task.assignedToId}
        severity='success'
        onClick={handleUpdateButtonClick}
      >
        Update
      </Button>
    </div>
  </Sidebar>;
}