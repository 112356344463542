import { ReactNode } from 'react';
import clsx from 'clsx';
import { Button } from 'primereact/button';

import { TranscriptionFooterProps } from '../../../Models';

import styles from './TranscriptionFooter.module.scss';

const DATE_FORMAT = 'dd LLL HH:mm:ss';

const TranscriptionFooter = ({
  isDisabled,
  isEditing,
  onCancel,
  transcription,
  onSave,
  isEditable = true,
}: TranscriptionFooterProps): ReactNode | null => {
  if (!transcription || !transcription.text || !isEditable) {
    return null;
  }

  if (isEditing) {
    return (
      <footer className={styles.footer}>
        <Button
          className={styles.surveillanceTranscriptionButtonSmall}
          size='small'
          text
          severity='danger'
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={clsx(
            'align-items--center',
            styles.surveillanceTranscriptionButtonLarge
          )}
          size='small'
          severity='success'
          autoFocus
          disabled={isDisabled}
          onClick={onSave}
        >
          Save changes
        </Button>
      </footer>
    );
  }

  return (
    <footer className={clsx(styles.footer, 'no-background')}>
      <div className='direction--column grow-to-fill'>
        {transcription.isOriginal ? (
          'Original version'
        ) : (
          <>
            <div>
              <span>
                Edited {transcription.updatedAt.toFormat(DATE_FORMAT)} by{' '}
                {transcription.updatedByName}
              </span>
            </div>
          </>
        )}
      </div>
    </footer>
  );
};

export { TranscriptionFooter };
export default TranscriptionFooter;
