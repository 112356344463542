import { useEffect, useState, useReducer } from 'react';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';

import { useGetWorkflowTemplates, useStartWorkflow } from 'modules/Workflows/Services/hooks';
import type { WorkflowTemplateResponse, TemplateProperty
 } from 'modules/Workflows/Models';
import { TemplateControlTypes, WorkflowStatusTypeEnum,
	WorkflowEvents, ControlBehaviour, WorkflowProvider} from 'modules/Workflows/Models/Enums';

import { asEncoded } from 'helpers/Utils/string';
import { replaceItemAt } from 'helpers/Utils/collections';
import { wait } from 'helpers/Utils/misc';

import DateTimeRange from 'components/DateTimeRange';

import eventBus from 'server/EventBus';


import './CreateWorkflow.scss';



interface CreateWorkflowProps {
  getProviderId: () => number;
  closePanel: () => void;
};

enum MutationType {
	All = 0,
	Partial = 1,
	Validate = 2
}

type MutationAction = {
	type: MutationType;
	payload: TemplateProperty | TemplateProperty[]
}

type TemplateDropdownProps<T> = {
	handler: (value: string) => void;
	options: T[] | undefined;
}

const TemplateDropdown = <T, >(props: TemplateDropdownProps<T>) => {

	const { options } = props;

	const [ selected, setSelected ] = useState<T>();

	return <Dropdown 
		className='grow-to-fill'
		options={options}
		optionLabel='label'
		placeholder='Select…'
		value={selected}
		onChange={(e) => {
			setSelected(e.value)
			props.handler(e.value)
		}}
	/>

}

export default function CreateWorkflow(props: CreateWorkflowProps): JSX.Element {

  const { closePanel } = props;

  const { providerId } = useParams();
	const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
	  const navigate: NavigateFunction = useNavigate();
		
  const { templatesData } = useGetWorkflowTemplates();
	const { startWorkflow, isMutating } = useStartWorkflow();

  const [ templates, setTemplates ] = useState<WorkflowTemplateResponse[]>([]);
	const [ selectedTemplate, setSelectedTemplate ] = useState<WorkflowTemplateResponse>();
	const [ workflowInvalid, setInvalid ] = useState<boolean>(true); 

	useEffect(() => {
		/** Other parts of the UI need to know about the mutation state */
		eventBus.dispatch(WorkflowEvents.Mutation, {isMutating});
	}, [ isMutating ])

	const reducer = (current: TemplateProperty[], action: MutationAction): TemplateProperty[] => {
				
		if (Array.isArray(action.payload)) {
			return action.payload
		} else {
		
			// let index;
			const { key } = action.payload;
			const index = current.findIndex(item => item.key === key);

			switch(action.type) {
				
				case MutationType.Validate:
						
					const { value, validation } = action.payload;
					
					if (!validation) return current;
					
					const { rule } = validation;
				
					const matcher = new RegExp(rule.replaceAll("/", ""), 'gi');

					console.log('validate', value.length, action.payload.control.mandatory)

					if (!matcher.test(value) && (value.length)) {
						/** validation failed - so mark the item as being in an error state */
						return replaceItemAt(current, {...action.payload, validationError: true }, index);
					} else {

						/** If validation passes, then stip out any potential value currently */
						/** in the payload */
						const { validationError, ...rest } = action.payload;

						return replaceItemAt(current, rest, index);
					}
				

				case MutationType.Partial:
				default:
					/** The most common change will be to simply update the state */
					/** with the new value */
					return replaceItemAt(current, action.payload, index);
					
			}

		}
		
	}

	const [ state, dispatchState ] = useReducer(reducer, []);

	useEffect(() => {
		if (!selectedTemplate) return;

		dispatchState({
			type: MutationType.All,
			payload: selectedTemplate.template.properties
		})

		// NOTE: if form has been touched - display the warning dialog on panel closing
		setFormTouched(true);

	}, [selectedTemplate])

	useEffect(() => {

		/** every time state changes assess if the save button should be disabled or not */
		setInvalid(state.length > 0 
			? state.some(s => s.validationError || (s.control.mandatory && !s.value?.length)) 
			: true);
	}, [state])
	
  const [ isWarningDialogVisible, setIsWarningDialogVisible ] = useState<boolean>(false);
  const [ formTouched, setFormTouched ] = useState<boolean>(false);

  useEffect(() => {

    if (!providerId) {
      navigate(`/workflows/${WorkflowProvider.Generic}/${WorkflowStatusTypeEnum.NotStarted}`);
    }

    if (templatesData) {
      setTemplates(templatesData.filter(y => y.linkedProvider.toString() === providerId));
    }

  }, [navigate, providerId, templatesData]);

   const saveNewWorkflow = async (): Promise<void> => {
   
		if (!selectedTemplate) return;

		const { templateName } = selectedTemplate;
		const properties = state.map(({key, value}) => ({key, value}))

	   await startWorkflow({ workflowTemplateName: templateName, properties });

	   // keep the panel open until the next tick so the `isMutating` flag is set to false
	   // and the `WorkflowEvents.Mutation` event can be dispatched
	   setTimeout(() => {
		   closePanel();
	   }, 0);

  }

  const handleClosePanel = (): void => {
    if (formTouched) {
      setIsWarningDialogVisible(true);
    } else {
      closePanel();
    }
  }

	const renderFormItem = (item: TemplateProperty) => {
		
		if (!item.control) return;
		
		switch(item.control.type) {
			case TemplateControlTypes.Dropdown:
				
				const { options } = item.control;

				return <TemplateDropdown 
					options={options} 
					handler={(value: string) => {
						dispatchState({
							type: MutationType.Partial,
							payload: { ...item, value }
						})

						if (!item.control.rules) return; 

						const rules = Array.isArray(item.control.rules) ? item.control.rules : [item.control.rules];

						/** Some template configurations may contain controls with */
						/** a set of sideffect rules, that effect the display state */
						/** of other items */
						/** see https://dev.azure.com/oilbrokerage/OBXchange/_wiki/wikis/OBX.wiki/248/Workflows */
						rules.forEach(({ref, behaviour, values}) => {

							/** whether or not an effected control should be displayed */
							/** is determined by the selected value matching the show/hide */
							/** rule defined in the template configuration */
							const display = (behaviour === ControlBehaviour.Show && values.includes(value)) || (behaviour === ControlBehaviour.Hide && !values.includes(value));

							const effected = state.find(s => s.key === ref)!;
							const { control } = effected;
							const payload = {...effected, control: { ...control, display }}

							dispatchState({ type: MutationType.Partial, payload })
						})

					}} 
				/>

			case TemplateControlTypes.TextField:
				return <InputTextarea
						className={clsx({ 'p-invalid': item.validationError })}
						rows={3}
						onChange={e => {
							const value: string = e.currentTarget.value;
	
							dispatchState({
								type: MutationType.Partial,
								payload: { ...item, value }
							})
						}}
						value={item.value ?? ""}
				/>
			case TemplateControlTypes.DateInput:
				return <DateTimeRange 
						onDateParsed={(result) => {

							dispatchState({
								type: MutationType.Partial,
								payload: { ...item, value: result.from.toFormat(item.control.formatter ?? 'dd MMM yyyy')}
							})
						}}
						showErrorMessage={false}
						defaultValue={item.value ?? ""}
					/>
			case TemplateControlTypes.NumberInput:
				return <div className='p-inputgroup grow-to-fill'>
					<InputNumber
						mode='decimal'
						maxFractionDigits={item.control.maxFractionalPlaces ?? 4}
						onChange={(e) => {
							const value: string = `${e.value ?? ''}`;

							dispatchState({
								type: MutationType.Partial,
								payload: { ...item, value }
							})
						}}
						onBlur={(e) => {
							const { value } = e.target;

							dispatchState({
								type: MutationType.Validate,
								payload: { ...item, value }
							});
						}}
						value={item.value ? +item.value : undefined}
					/>
					{ item.control.suffix && <span className="p-inputgroup-addon">{item.control.suffix}</span> }
				</div>
			case TemplateControlTypes.TextInput:
			default:
				return <InputText
						className={clsx({ 'p-invalid': item.validationError })}
						onChange={(e) => {
							const value: string = e.currentTarget.value;

							dispatchState({
								type: MutationType.Partial,
								payload: { ...item, value }
							})
						}}
						onBlur={(e) => {
							const value: string = e.currentTarget.value;

							dispatchState({
								type: MutationType.Validate,
								payload: { ...item, value }
							})
						}}
						value={item.value ?? ""}
					/>
			
		}
	}

  return <div className='grow-to-fill direction--column create-workflow'>
    <header className='create-workflow__header'>
      {isMobile ?
        <Button
          text
          size='small'
          onClick={handleClosePanel}
          icon='iconoir-nav-arrow-left icon--small'
          className='workflows-page__back-button plain-text'
        >
          Back to list
        </Button>
      :
        <>
          <span>Add Workflow</span>
          <Button
            text
            icon='iconoir-xmark icon--tiny p-button-icon-only'
            className='close-button'
            onClick={handleClosePanel}
          />
        </>}
    </header>
    <div className='create-workflow__container'>
			<form>
				<div className='form-input__container'>
					<label>Template</label>
					<Dropdown
						value={selectedTemplate}
						placeholder='Select template'
						onChange={(e) => setSelectedTemplate(e.value)}
						options={templates}
						optionLabel='templateName'
					/>
				</div>
				{ state.filter(({control}) => control.display).map( prop => (
					<div 
						key={asEncoded(prop.key)}
						className='form-input__container'
					>
						<label>{ prop.key } { prop.control.mandatory && <>*</>}</label>
						<div>
							{ renderFormItem(prop) }
							{ prop.validationError && <span className='error'>{prop.validation?.message}</span>}
						</div>
					</div>
				))}
      </form>
    </div>
    <footer>
      <Button
        size='small'
        severity='success'
        loading={isMutating}
        onClick={saveNewWorkflow}
				disabled={workflowInvalid}
      >
        SAVE
      </Button>
    </footer>

    <Dialog
      appendTo='self'
      position='top'
      className='p-dialog--margin-less p-dialog--no-header create-workflow__warning-dialog'
      visible={isWarningDialogVisible}
      footer={<div className='space-between'>
        <Button
          size='small'
          outlined
          severity='danger'
          onClick={closePanel}
        >
          Continue
        </Button>
        <Button
          size='small'
          text
          onClick={() => setIsWarningDialogVisible(false)}
        >
          Return
        </Button>
      </div>}
      onHide={() => setIsWarningDialogVisible(false)}
    >
      <strong>You have unsaved changes</strong>
      <div>If you CONTINUE any changes you've made will be lost. Hit RETURN to complete.</div>
    </Dialog>
  </div>;
}