import { ReactNode } from 'react';
import clsx from 'clsx';

import { getValueByPath } from 'helpers/Utils/misc';

type MultipleLineCellProps<T> = {
  data: T;
  field: string;
  header?: ReactNode;
  isDefaultBehavior?: boolean;
  customCell?: ReactNode;
  className?: string;
};

const MultipleLineCell = <T,>({
  data,
  field,
  header,
  isDefaultBehavior,
  customCell,
  className,
}: MultipleLineCellProps<T>): ReactNode => {
  const keys = field.split(',');

  // get values by path in case that field is provided with dot (.), ex. `data.name`
  const values = keys.reduce((acc, key) => {
    if (key) {
      acc[key] = getValueByPath(data, key);
    }
    return acc;
  }, {} as Record<string, ReactNode>);

  const mapNodes = (key: string): ReactNode => (
    <span key={key}>{values[key]}</span>
  );

  if (isDefaultBehavior) {
    return <>{customCell || keys.map(mapNodes)}</>;
  }

  return (
    <div className={clsx('direction--column align-items--start', className)}>
      {header && <span className='multi-line-cell-header'>{header}</span>}
      {customCell || keys.map(mapNodes)}
    </div>
  );
};

export default MultipleLineCell;
export { MultipleLineCell };
