import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Button } from 'primereact/button';
import { ContextMenu } from 'primereact/contextmenu';
import { MenuItem } from 'primereact/menuitem';
import { Message } from 'primereact/message';
import { TabPanel, TabView } from 'primereact/tabview';

import Loader from 'components/Loader';
import { ToastSeverity } from 'components/ToastMessage';

import { SurveillanceEntityStatus } from '../../Models/Enums';

import ActivityView from './Components/ActivityView';
import ContentComponent from './Components/ContentComponent';
import DetailsComponent from './Components/DetailsComponent';
import DropdownMenuItem from './Components/DropdownMenuItem/DropdownMenuItem';
import MetadataView from './Components/MetadataView';
import { isMessagesEqual } from './Models/Helpers';
import { SurveillanceMessageResult } from './Models/Response';
import {
  SurveillanceDetailsAPI,
  useCreateTranscriptionVersion,
  useDetails,
  useMessages,
} from './Services/SurveillanceDetailsAPI';
import {
  ContentComponentMessage,
  ContentComponentProps,
  DetailsPanelState,
  DetailsTabs,
  PanelState,
  RequestSiblingMessagesDirection,
  RightPanelProps,
  TranscriptionState,
} from './Models';

import { getKeyValuePairs } from 'helpers/Utils/enum';
import { formatName } from 'helpers/Utils/string';
import { isEntitySelectable } from 'modules/Surveillance/Helpers';
import { SurveillanceMediaRecording } from 'modules/Surveillance/Models/ReportsResponse';

import { ApplicationInternalEventTypes } from 'models/shared/Enums';
import eventBus from 'server/EventBus';

import styles from './RightPanel.module.scss';

const MESSAGES_COUNT = 5;
const audioTypes = Object.keys(SurveillanceMediaRecording);

const RightPanel = ({
  activeWorksheetId,
  record,
  setRecordSelected,
  selectedMessages,
  setSelectedMessages,
  selectedContentMessages,
  setSelectedContentMessages,
  contentPanelState,
  setContentPanelState,
  detailsPanelState,
  setDetailsPanelState,
  searchRequestFields,
  resultsMode,
}: RightPanelProps): ReactNode => {
  const { id, partitionKey, provider } = record;

  const [activeTab, setActiveTab] = useState(0);
  const [topRibbon, setTopRibbon] = useState<ReactNode>();
  const [isEditing, setIsEditing] = useState(false);
  const [msgDirection, setMsgDirection] =
    useState<RequestSiblingMessagesDirection | null>(null);
  const [hasNoNextMessages, setHasNoNextMessages] = useState<boolean | null>(
    null
  );
  const [hasNoPrevMessages, setHasNoPrevMessages] = useState<boolean | null>(
    null
  );
  const [prevMessagesPage, setPrevMessagesPage] = useState<number>(0);
  const [nextMessagesPage, setNextMessagesPage] = useState<number>(0);
  const [messagesList, setMessagesList] = useState<
    ContentComponentMessage[] | undefined
  >(undefined);

  const scrollDetailsRef = useRef<HTMLDivElement>(null);
  const scrollExpandedDetailsRef = useRef<HTMLDivElement>(null);
  const contentComponentRef = useRef<HTMLDivElement>(null);
  const contentComponentExpandedRef = useRef<HTMLDivElement>(null);
  const contextMenu = useRef<ContextMenu>(null);

  const mapMessagesList = useCallback(
    (message: SurveillanceMessageResult): ContentComponentMessage => ({
      ...message,
      isEditable: false,
    }),
    []
  );

  const scrollTo = useCallback((id: string, partitionKey: string): void => {
    const observer = new MutationObserver((_, obs) => {
      obs.disconnect();

      const top = document.getElementById(`${ id }-${ partitionKey }`)?.offsetTop;

      scrollDetailsRef.current?.scrollTo({ top });
      scrollExpandedDetailsRef.current?.scrollTo({ top });
    });
    // Observe changes in component's subtree
    contentComponentRef.current &&
      observer.observe(contentComponentRef.current.getRootNode(), {
        childList: true,
        subtree: true,
      });
    contentComponentExpandedRef.current &&
      observer.observe(contentComponentExpandedRef.current.getRootNode(), {
        childList: true,
        subtree: true,
      });
  }, []);

  const onDetailsPanelStateChanged = useCallback(
    (state: DetailsPanelState) => {
      setDetailsPanelState(
        state === DetailsPanelState.Medium
          ? PanelState.expanded
          : PanelState.collapsed
      );
    },
    [setDetailsPanelState]
  );

  const {
    details,
    isLoading,
    mutate: getDetails,
  } = useDetails(
    {
      id,
      partitionKey,
      providerName: provider,
      searchRequestFields,
    },
    activeWorksheetId
  );

  const mainMessage = useMemo(
    () => ({
      ...record,
      highlights: details?.highlights,
      versions: details?.versions || [],
      isEditable: details?.media && audioTypes.includes(details?.media),
    }),
    [record, details]
  );

  const isMessagesSelectable = useMemo(
    () => isEntitySelectable(resultsMode, record.status),
    [record.status, resultsMode]
  );

  const contextMenuItems = useMemo<MenuItem[]>(
    () =>
      getKeyValuePairs(DetailsPanelState).map(({ key: label, value: key }) => {
        const currentPanelState =
          detailsPanelState === PanelState.expanded
            ? DetailsPanelState.Medium
            : DetailsPanelState.Small;
        return {
          command: (): void =>
            onDetailsPanelStateChanged(key as DetailsPanelState),
          icon:
            key === DetailsPanelState.Medium
              ? 'iconoir-align-right-box'
              : 'iconoir-align-right-box-small',
          label,
          template: ({ icon, label }): ReactNode => (
            <DropdownMenuItem
              icon={icon}
              label={label}
              isChecked={key === currentPanelState}
            />
          ),
        };
      }),
    [detailsPanelState, onDetailsPanelStateChanged]
  );

  const checkActivity = useCallback(async () => {
    const activity = await SurveillanceDetailsAPI.getActivity({
      body: { id: record.id, partitionKey: record.partitionKey },
      worksheetId: activeWorksheetId,
    });

    const result = activity.find(
      item => item.status === SurveillanceEntityStatus.Escalated
    );

    setTopRibbon(
      result ? (
        <Message
          className={styles.topRibbon}
          text={
            <>
              This was escalated and forwarded to
              <br />
              {formatName(result.assignedUserName ?? '')}
              <br />
              {result.updatedAt.toFormat('HH:mm:ss LLL dd yyyy')}
            </>
          }
        />
      ) : undefined
    );
  }, [activeWorksheetId, record]);

  useEffect(() => {
    if (record.status === SurveillanceEntityStatus.Escalated) {
      checkActivity();
    } else {
      setTopRibbon(undefined);
    }
  }, [checkActivity, record]);

  const { trigger: saveTranscription } = useCreateTranscriptionVersion();

  const { messages, totalPages } = useMessages(
    msgDirection !== null
      ? {
        id,
        partitionKey,
        direction: msgDirection,
        pageNumber:
            msgDirection === RequestSiblingMessagesDirection.Previous
              ? prevMessagesPage + 1
              : nextMessagesPage + 1,
        pageSize: MESSAGES_COUNT,
      }
      : undefined,
    activeWorksheetId
  );

  // reset state
  useEffect(() => {
    setActiveTab(0);
    setIsEditing(false);
    setMsgDirection(null);
    setHasNoNextMessages(null);
    setHasNoPrevMessages(null);
    setPrevMessagesPage(0);
    setNextMessagesPage(0);
    setMessagesList([]);
  }, [id]);

  useEffect(() => {
    if (details) {
      setMessagesList([mainMessage]);
      setHasNoNextMessages(null);
      setHasNoPrevMessages(null);
      setPrevMessagesPage(0);
      setNextMessagesPage(0);
      setMsgDirection(null);
    }
  }, [details, record, mainMessage]);

  useEffect(() => {
    if (messages) {
      // scroll to main message in case of messages are empty
      const messageToScroll = messages[0] || mainMessage;
      if (msgDirection === RequestSiblingMessagesDirection.Next) {
        scrollTo(messageToScroll.id, messageToScroll.partitionKey);
        setNextMessagesPage(n => ++n);
        setMessagesList(m => [...(m || []), ...messages.map(mapMessagesList)]);
        if (nextMessagesPage + 1 === totalPages || !messages.length) {
          setHasNoNextMessages(true);
        }
      } else if (msgDirection === RequestSiblingMessagesDirection.Previous) {
        scrollTo(messageToScroll.id, messageToScroll.partitionKey);
        setPrevMessagesPage(n => ++n);
        setMessagesList(m => [...messages.map(mapMessagesList), ...(m || [])]);
        if (prevMessagesPage + 1 === totalPages || !messages.length) {
          setHasNoPrevMessages(true);
        }
      }
      setMsgDirection(null);
    }
  }, [
    mainMessage,
    messages,
    msgDirection,
    nextMessagesPage,
    prevMessagesPage,
    totalPages,
    scrollTo,
    mapMessagesList,
  ]);

  useEffect(() => {
    if (selectedMessages.find(m => isMessagesEqual(m, mainMessage))) {
      setSelectedContentMessages(items => [
        ...items.filter(i => !isMessagesEqual(i, mainMessage)),
        mainMessage,
      ]);
    } else {
      setSelectedContentMessages(items => [
        ...items.filter(
          i =>
            !isMessagesEqual(i, mainMessage) &&
            i.parent &&
            !isMessagesEqual(i.parent, mainMessage)
        ),
      ]);
    }
  }, [mainMessage, selectedMessages, setSelectedContentMessages]);

  const saveTranscriptionVersion = useCallback(
    async (transcription: string): Promise<void> => {
      await saveTranscription({
        id,
        partitionKey,
        transcription,
      }).then(() => {
        eventBus.dispatch(
          ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
          {
            severity: ToastSeverity.SUCCESS,
            title: 'Changes saved',
            message: 'Success',
          }
        );
        record.content = transcription;
        /**
         * force update results grid item with
         * changed content value without reload
         */
        setRecordSelected({ ...record });
        getDetails();
      });
    },
    [id, partitionKey, record, getDetails, saveTranscription, setRecordSelected]
  );

  const onTranscriptionState = useCallback(
    (_: string, state: TranscriptionState): void => {
      switch (state) {
      case TranscriptionState.Edit:
        setIsEditing(true);
        break;
      default:
        setIsEditing(false);
        break;
      }
    },
    []
  );

  const contentComponentProperties: ContentComponentProps = useMemo(
    () => ({
      className: styles.itemContainer,
      worksheetId: activeWorksheetId,
      messagesPerPage: MESSAGES_COUNT,
      mainMessage,
      messages: messagesList,
      selectedMessages,
      setSelectedMessages,
      selectedSiblingMessages: selectedContentMessages,
      setSelectedSiblingMessages: setSelectedContentMessages,
      hasNoNextMessages,
      hasNoPrevMessages,
      contentPanelState: contentPanelState,
      rightPanelState: detailsPanelState,
      onTranscriptionState,
      saveTranscriptionVersion,
      setMsgDirection,
      setPanelState: setContentPanelState,
      resultsMode,
      isMessagesSelectable,
    }),
    [
      activeWorksheetId,
      mainMessage,
      messagesList,
      selectedMessages,
      setSelectedMessages,
      selectedContentMessages,
      setSelectedContentMessages,
      hasNoNextMessages,
      hasNoPrevMessages,
      contentPanelState,
      detailsPanelState,
      onTranscriptionState,
      saveTranscriptionVersion,
      setMsgDirection,
      setContentPanelState,
      resultsMode,
      isMessagesSelectable,
    ]
  );

  if (isLoading) {
    return (
      <div className={styles.flexCenterParent}>
        <div className={styles.flexCenterChild}>
          <Loader className='small' />
        </div>
      </div>
    );
  }

  return (
    <main
      className={clsx(
        styles.panelMainContainer,
        contentPanelState === PanelState.expanded ? 'drawer--active' : undefined
      )}
      data-cols={contentPanelState === PanelState.expanded ? '5,7' : undefined}
      data-drawer-style='slide'
      data-drawer-position='alongside-right'
    >
      <aside>
        <ContextMenu ref={contextMenu} model={contextMenuItems} />
        <TabView
          className={styles.tabview}
          renderActiveOnly={true}
          activeIndex={activeTab}
          onTabChange={({ index }): void => setActiveTab(index)}
        >
          <TabPanel
            contentClassName={clsx(
              !isEditing ? styles.scroll : '',
              'direction--column'
            )}
            header={DetailsTabs.Details}
          >
            <div
              ref={scrollDetailsRef}
              className={clsx(
                styles.detailsPanel,
                'grow-to-fill direction--column'
              )}
            >
              {topRibbon}
              <DetailsComponent
                hidden={contentPanelState === PanelState.collapsed && isEditing}
                className={styles.itemContainer}
                companyName={record.company}
                details={details}
                contentPanelState={contentPanelState}
                rightPanelState={detailsPanelState}
              />
              {contentPanelState === PanelState.collapsed && (
                <ContentComponent
                  ref={contentComponentRef}
                  {...contentComponentProperties}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel
            visible={Boolean(details?.metadata)}
            header={DetailsTabs.Metadata}
          >
            <MetadataView
              metadata={details?.metadata}
              record={record}
              topRibbon={topRibbon}
            />
          </TabPanel>
          <TabPanel header={DetailsTabs.Activity}>
            <ActivityView
              activeWorksheetId={activeWorksheetId}
              record={record}
              topRibbon={topRibbon}
            />
          </TabPanel>
        </TabView>
        <section className={clsx(styles.rightContainer, styles.buttons)}>
          <Button
            size='small'
            text
            className={'transparent'}
            icon='iconoir-more-vert icon--tiny'
            onClick={(e: MouseEvent<HTMLButtonElement>) =>
              contextMenu?.current?.show(e)
            }
          />
          <Button
            size='small'
            text
            className={'transparent'}
            icon='iconoir-xmark icon--tiny'
            onClick={(): void => setRecordSelected(null)}
          />
        </section>
      </aside>
      {contentPanelState === PanelState.expanded && (
        <aside>
          <ContentComponent
            ref={contentComponentExpandedRef}
            scrollExpandedDetailsRef={scrollExpandedDetailsRef}
            {...contentComponentProperties}
          />
        </aside>
      )}
    </main>
  );
};

export { RightPanel };
export default RightPanel;
