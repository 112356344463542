import { Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { Accordion, AccordionTab, AccordionTabChangeEvent, AccordionTabOpenEvent } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';

import eventBus from 'server/EventBus';
import { DistListSignalEventTypes } from 'modules/DistList/Services/SignalRSocket';
import BorealisBar from 'components/BorealisBar';

import CommonFields from '../CommonFields';
import CreateNewButton from './CreateNewButton';
import EmptyMessage from './EmptyMessage';
import HeaderTemplate from './HeaderTemplate';
import MessageTemplateMobile from './MessageTemplateMobile';

import type {
  DistributionListMessagesSearchResponse,
  DistributionListMessagesSearchResponseFlat,
  MessageRecipients
} from '../../Models/distribution-list-response';
import type { DistributionList } from 'modules/DistList/Models/distribution-list-response';
import type { GetRecipientsParams } from 'modules/DistList/Models/distribution-list-create-request';
import type { AxiosError } from 'axios';

interface MessageMobileProps {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSubjectSearch: Dispatch<SetStateAction<string | undefined>>;
  isLoading: boolean;
  isCreateDisabled: boolean;
  handleClose: () => void;
  onMessageResend: (data: DistributionListMessagesSearchResponseFlat) => void;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  messages: DistributionListMessagesSearchResponse[] | undefined;
  messagesUngrouped: DistributionListMessagesSearchResponseFlat[] | undefined;
  getRecipients: (d: GetRecipientsParams) => Promise<void>;
  getRecipientsMutating: boolean;
  getRecipientsError: AxiosError | undefined;
  messageIdForRecipientsToLoad: string | null;
  activeDlId?: DistributionList['id'];
  dlName?: string;
  activeDetailTab?: number;
}

const MessageMobile = (props: MessageMobileProps): JSX.Element => {
  const {
    onMessageResend,
    searchValue,
    setSearchValue,
    setSubjectSearch,
    isLoading,
    isCreateDisabled,
    handleClose,
    activeDlId,
    dlName,
    activeDetailTab,
    setActiveDetailTab,
    messages,
    messagesUngrouped,
    getRecipients,
    getRecipientsMutating,
    getRecipientsError,
    messageIdForRecipientsToLoad
  } = props;

  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);

  useEffect(() => {
    const onSubjectFound = (e: CustomEvent<DistributionListMessagesSearchResponseFlat>): void => {
        // open tab with message
      const index = messages?.findIndex(m => m.id === e.detail.id) ?? -1;
      setActiveIndexes(index > -1 ? [index] : []);
    };

    eventBus.on(DistListSignalEventTypes.DIST_LIST_SUBJECT_FOUND, onSubjectFound);

    return (): void => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_SUBJECT_FOUND, onSubjectFound);
    };
  });

  const onTabOpen = (e: AccordionTabOpenEvent): void => {
    if (messages?.length) {
      getRecipients({ id: messages[e.index].id, distributionListId: messages[e.index].distributionListId });
    }
  };

  const onTabChange = (e: AccordionTabChangeEvent): void => {
    setActiveIndexes(e.index as number[]);
  };

  const renderContent = (item: DistributionListMessagesSearchResponse): ReactNode => {
    if (getRecipientsMutating && messageIdForRecipientsToLoad === item.id) {
      return <div className='dl-messages__loader'><BorealisBar /></div>;
    } else if (!item.recipients && getRecipientsError) {
      return <div className='dl-messages__error-message'>Cannot load recipients</div>;
    } else {
      return <DataView
        value={item.recipients}
        itemTemplate={(el: MessageRecipients): ReactElement => {
          // find the recipient index...
          const index = item.recipients.findIndex(r => r.emailAddress === el.emailAddress);
          // ...and find the message in the 'ungrouped messages' collection to use it for resending
          const message = messagesUngrouped?.find(m => m.recipientIndex === index && m.id === item.id);

          // most likely not gonna happen
          if (!message) {
            return <></>;
          }

          return <MessageTemplateMobile
            item={message}
            onMessageResend={data => onMessageResend(data as DistributionListMessagesSearchResponseFlat)}
          />;
        }}
        className='distlist__view distlist__messages grow-to-fill no-background'
      />;
    }
  }

  return (
    <>
      <Button
        size="small"
        text
        className="plain-text back-button"
        icon={'iconoir-nav-arrow-left icon--small'}
        onClick={handleClose}
      >
        Back to list
      </Button>
      <div className="distlist-dl-panel__messages position--relative">
        {activeDlId && <CommonFields
          dlName={dlName}
          activeDetailTab={activeDetailTab}
          setActiveDetailTab={setActiveDetailTab}
        />}
        <div className="dl-messages-container grow-to-fill">
          <Accordion className="dl-messages__accordion">
            <AccordionTab className="dl-messages__accordion-tab search-tab"
              headerClassName="dl-messages__accordion-header"
              header="Search"
            >
              <div className="dl-messages__accordion-container">
                <div className="dl-messages-search-container">
                  <span className="p-input-icon-left grow-to-fill">
                    <i
                      className={clsx('pi', {
                        'pi-spinner pi-spin': isLoading,
                        'pi-search': !isLoading,
                      })}
                    />
                    <InputText
                      placeholder="Search by subject"
                      value={searchValue}
                      onChange={(e): void => setSearchValue(e.target.value)}
                      onBlur={(e): void => setSubjectSearch(e.target.value)}
                      onKeyUp={(e): boolean | void => e.key === 'Enter' && (e.target as HTMLInputElement).blur()}
                      className="dl-search-input"
                    />
                  </span>
                </div>
              </div>
            </AccordionTab>
          </Accordion>

          <Accordion
            className="dl-messages__accordion"
            onTabOpen={onTabOpen}
            onTabChange={onTabChange}
            activeIndex={activeIndexes}
            multiple
          >
            {/* use `messages` instead of `messageUngrouped` to avoid displaying additional AccordionTabs  */}
            {messages?.map(item => (
              <AccordionTab className="dl-messages__accordion-tab"
                headerClassName="dl-messages__accordion-header"
                header={():JSX.Element => HeaderTemplate(item)}
                key={item.id}
              >
                <div className='dl-messages__accordion-container'>
                  {renderContent(item)}
                </div>
              </AccordionTab>))}
          </Accordion>
          {!messages || messages.length === 0 && <EmptyMessage activeDlId={activeDlId} isCreateDisabled={isCreateDisabled} setActiveDetailTab={setActiveDetailTab} />}
        </div>
      </div>
      <footer className="distlist-dl-panel__footer">
        <CreateNewButton activeDlId={activeDlId} isDisabled={isCreateDisabled} />
      </footer>
    </>
  );
};

export default MessageMobile;
