import { getValueCollection } from 'helpers/Utils/enum';

import { SignalRApi } from 'server/signalR-api';

export enum DistListSignalEventTypes {
  DIST_LIST_UPDATED = 'DistributionListUpdated',
  DIST_LIST_EMAIL_UPDATED = 'DistListEmailChange',
  DIST_LIST_SINGLE_UPDATED = 'SingleSecureEmailChange',
  DIST_LIST_EMAIL_RECIPIENT_STATUS_CHANGED = 'EmailRecipientStatusChanged',
  DIST_LIST_SINGLE_CLEAR_SEARCH = 'SingleSecureEmailClearSearch',
  DIST_LIST_SUBJECT_FOUND = 'DistListSubjectFound',
}
export class DistributionListSocket {

  private static _instance: DistributionListSocket;

  static get instance():DistributionListSocket {
    return this._instance || (this._instance = new this());
  }
  init = async (sr: SignalRApi): Promise<void> => {

    const events = [
      ...getValueCollection(DistListSignalEventTypes, false)
    ];

    events.map(event => sr.dispatchEventOnMessage(event.key));
  };
}