import { ReactNode, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Location,
  NavigateFunction,
  useLocation,
  useMatches,
  useNavigate,
} from 'react-router-dom';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import Traversal from 'components/Traversal';

import styles from './SecondaryNavigation.module.scss';

type NavigationItem<T = object> = T & {
  selector?: RegExp;
};

interface ISecondaryNavigationProps<T> {
  items: T[];
  urlParam?: string;
  onBeforeNavigation?: () => Promise<void>;
  pathKey?: keyof T;
}

const SecondaryNavigation = <T extends NavigationItem>(
  args: ISecondaryNavigationProps<T>
): ReactNode => {
  const { items, urlParam, onBeforeNavigation, pathKey } = args;

  const matches = useMatches();

  const currentLoc: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const [currentActiveItem, setCurrentActiveItem] = useState<T>();

  const additional: string = urlParam ? `/${ urlParam }` : '';
  const navRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { pathname } = currentLoc;

    setCurrentActiveItem(
      items.find(i => {
        if (i.selector) {
          return i.selector.test(pathname);
        } else {
          if (pathKey) {
            return pathname.indexOf(i[pathKey] as string) > 0;
          } else if ('path' in i && typeof i.path === 'string') {
            return pathname.indexOf(i.path) > 0;
          }
        }
      })
    );
  }, [currentLoc, items, pathKey]);

  const doNavigate = (path: string): void => {
    if (typeof onBeforeNavigation === 'function') {
      onBeforeNavigation().finally(() => {
        navigate(path);
      });

      return;
    }

    navigate(path);
  };

  return (
    <>
      {isTabletOrMobile && (
        <Dropdown
          className={styles.dropdown}
          onChange={e =>
            doNavigate(
              `${ matches[1].pathname }/${
                e.value.path ?? e.value[pathKey as keyof typeof e.value]
              }${ additional }`
            )
          }
          value={currentActiveItem}
          options={items}
        />
      )}
      {!isTabletOrMobile && (
        <div>
          <Traversal container={navRef} />
          <div
            ref={navRef}
            className={clsx(styles.container, 'overflow--hidden')}
          >
            {items?.map((item: any) => {
              let { icon, path, label } = item;

              if (!path) {
                //	no item at key 'path' so use the pathKey prop
                path = item[pathKey as keyof typeof item];
              }

              if (icon) {
                icon = `${ icon } icon--tiny`;
              }
              return (
                <Button
                  key={crypto.randomUUID()}
                  icon={icon}
                  className={clsx(
                    'p-button--tab-style lowercase',
                    styles.menuItem,
                    { active: item === currentActiveItem }
                  )}
                  onClick={() =>
                    doNavigate(`${ matches[1].pathname }/${ path }${ additional }`)
                  }
                >
                  {label}
                </Button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default SecondaryNavigation;
export { type NavigationItem, SecondaryNavigation };
