import { useMemo } from 'react';
import clsx from 'clsx';
import { MeterGroup, MeterGroupValue } from 'primereact/metergroup';

import { getByNumber } from 'helpers/Utils/enum';
import { lowercaseWords } from 'helpers/Utils/string';
import { WorkflowStatusTypeEnum } from 'modules/Workflows/Models/Enums';

import styles from './WorkflowProgressBar.module.scss';

interface MeterTemplateAdditionalProps {
  className: string;
  'data-pc-section': string;
}

interface WorkflowProgressBarProps {
  availableStatuses: number[];
  statuses: Record<WorkflowStatusTypeEnum, number>;
  totalCount: number;
  isHeaderVisible?: boolean;
  isLegendVisible?: boolean;
  isMobile?: boolean;
  className?: string;
}

const WorkflowProgressBar = ({
  statuses,
  availableStatuses,
  totalCount,
  isHeaderVisible = false,
  isLegendVisible = false,
  isMobile = false,
  className,
}: WorkflowProgressBarProps): JSX.Element => {
  const totalDone =
    (statuses[WorkflowStatusTypeEnum.Approved] || 0) +
    (statuses[WorkflowStatusTypeEnum.Completed] || 0);
  const isCompletedPersist =
    availableStatuses?.includes(WorkflowStatusTypeEnum.Completed) || false;
  const approvedPercentage = Math.round((totalDone / totalCount) * 100);
  const values: MeterGroupValue[] = useMemo(
    () => Object.entries(statuses)
      .sort((a, b) => availableStatuses.indexOf(+a[0]) - availableStatuses.indexOf(+b[0]))
      .map(([key, value]) => {
        const label = lowercaseWords(
          getByNumber(WorkflowStatusTypeEnum, +key, true),
          ['on', 'for']
        );
        const colorClass =
          styles[`icon--${ label?.replace(/ /g, '').toLowerCase() }`];
        return {
          value,
          label,
          meterTemplate: (
            _props: MeterGroupValue,
            additionalProps: MeterTemplateAdditionalProps
          ): JSX.Element => {
            // We have to calculate percentages on our own because the "MeterGroup" converts
            // calculated percentages to integers and due to this we might lose some part of progress
            // (ex. status1 = 27; status2 = 1; status3 = 1; -> total = 29;
            // but this produces percentage as:
            // status1 = 93%; status2 = 3%; status3 = 3% -> total = 99%)
            const percentage = (value / totalCount) * 100;
            const className = clsx(additionalProps.className, colorClass);

            return (
              <span style={{ width: `${ percentage }%` }} className={className} />
            );
          },
          icon: clsx(styles.icon, colorClass),
        };
      }),
    [statuses]
  );

  return (
    <div className={clsx(className, styles.container)}>
      {isHeaderVisible && (
        <h4>
          {totalDone} out of {totalCount} tasks{' '}
          {isCompletedPersist ? 'completed' : 'approved'} ({approvedPercentage}
          %)
        </h4>
      )}
      <MeterGroup
        className={clsx(
          'p-metergroup-size-micro grow-to-fill',
          !isLegendVisible ? 'p-metergroup-hidden-legend' : ''
        )}
        labelOrientation={isMobile ? 'vertical' : 'horizontal'}
        max={totalCount}
        values={values}
      />
    </div>
  );
};

export { WorkflowProgressBar };
export default WorkflowProgressBar;
