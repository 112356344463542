import { KeyboardEvent, ReactElement, RefObject, SyntheticEvent, useRef } from 'react';
import { User } from '@auth0/auth0-spa-js';
import clsx from 'clsx';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';

import { ToastMessageRef } from 'components/ToastMessage';

import { ProfilePanel } from './ProfilePanel';

interface ProfileProps {
  toggleState: (force?: boolean) => void;
  user: User;
  profileOverlayRef: RefObject<OverlayPanel>;
  signalRStatus?: boolean;
}

const Profile = (props: ProfileProps):ReactElement => {
  const { signalRStatus, toggleState, user, profileOverlayRef } = props;

  const toastRef = useRef<ToastMessageRef>(null);

  const showUserProfilePanel = (e: SyntheticEvent):void => {
    toggleState();
    profileOverlayRef.current?.toggle(e);
  };

  const onOverlayPanelShow = (): void => {
    const observer = new MutationObserver((_, obs) => {
      obs.disconnect();
      profileOverlayRef.current?.align();
    });

    // Observe changes in subtree (content of overlay)
    profileOverlayRef.current &&
    observer.observe(profileOverlayRef.current?.getElement(), {
      subtree: true,
      attributes: true,
    });
  };

  const handleAvatarSelect = (e: KeyboardEvent<HTMLDivElement>):void => {
    switch (e.code) {
    case 'Enter':
      profileOverlayRef.current?.toggle(e);
      break;
    case 'Tab':
      if (e.shiftKey) {
        profileOverlayRef.current?.hide();
      }
      break;
    default:
      return;
    }
  };

  return (
    <>
      <div className='top-menu__avatar'>
        <div className='top-menu__avatar-container'>
          <Avatar
            image={user.picture}
            shape='circle'
            onClick={showUserProfilePanel}
            onKeyDown={(e): void => handleAvatarSelect(e)}
            tabIndex={0}
          >
            <Badge
              className={clsx(
                'icon--mini',
                signalRStatus ? 'iconoir-check' : 'iconoir-xmark'
              )}
              severity={signalRStatus ? 'success' : 'danger'}
            />
          </Avatar>
        </div>
      </div>

      <OverlayPanel
        ref={profileOverlayRef}
        onShow={onOverlayPanelShow}
        onHide={() => toggleState(false)}
        className='top-menu__profile-panel-container'
        appendTo='self'
      >
        <ProfilePanel toastRef={toastRef}/>
      </OverlayPanel>
    </>
  );
};

export default Profile;