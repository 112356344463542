import type { ColumnSortEvent } from 'primereact/column';
import type { apiSingleMailEmailResponse } from '../Models/apiResponse';

export const sortByAttachmentStatus = (event: ColumnSortEvent): apiSingleMailEmailResponse[] => {
  const { data, order, field } = event;
  const direction = order as number;
  const [sf, c] = field.split(',');

  const statusField = sf as keyof apiSingleMailEmailResponse;
  const container = c as keyof apiSingleMailEmailResponse;

  return (data as apiSingleMailEmailResponse[]).sort((a, b) => {
    if (Array.isArray(a[container]) && a[container].length === 0) {
      return 1 * direction;
    }

    if (Array.isArray(b[container]) && b[container].length === 0) {
      return -1 * direction;
    }

    const first = a[statusField] as number;
    const second = b[statusField] as number;

    const diff = first - second;

    return Math.sign(diff) * direction;
  });
}