import { CustomRoute, ROUTES_CONFIG } from 'routes';

import { UISettings } from 'components/OBXUser/Model/Enums';
import { SecurityRights } from 'components/OBXUser/Model/SecurityRightsEnum';

import { Route } from './Types';

import { WorkflowProvider } from 'modules/Workflows/Models/Enums';

import { RatesConfig } from 'types/routes-type';

/*
 * Checks is user has access ("assignedSecurityRights") to module with defined "securityRights"
 */
export const hasUserAccess = (
  securityRights: Route['securityRights'],
  assignedSecurityRights?: number[]
): boolean => {
  if (!assignedSecurityRights) {
    return false;
  }

  if (securityRights === SecurityRights._Unprotected) {
    return true;
  }

  if (typeof securityRights === 'number') {
    return assignedSecurityRights.includes(securityRights);
  } else if (Array.isArray(securityRights)) {
    return securityRights.some(right => assignedSecurityRights.includes(right));
  }

  return false;
};

/*
 * Gets routes in Rates module, that are available for user
 */
export const getAvailableRatesRoutes = (
  routes: RatesConfig[],
  assignedSecurityRights?: number[]
): RatesConfig[] =>
  routes.filter(i => hasUserAccess(i.accessCode, assignedSecurityRights));

/*
 * Gets first available routes in Rates module
 */
export const getFirstAvailableRatesRoute = (
  routes: RatesConfig[],
  assignedSecurityRights?: number[]
): RatesConfig | undefined =>
  routes.find(i => hasUserAccess(i.accessCode, assignedSecurityRights));

/*
 * Gets modules, that are available for user (will be in Bento Menu as buttons and to pick for "default module")
 */
export const getAvailableRoutes = (
  modules: Route[],
  lowerLevelEnvironment: boolean,
  assignedSecurityRights?: number[]
): Route[] =>
  modules.filter(
    i =>
      hasUserAccess(i.securityRights, assignedSecurityRights) &&
      (lowerLevelEnvironment ? i : !i.isBeta)
  );

/*
 * Returns first module, that is available to user
 * If it's not lowerLevelEnvironment (set in config.json) -> check isBeta
 */
export const getFirstAvailableRoute = (
  //eslint-disable-next-line
  getSetting: (key: string | null) => any,
  lowerLevelEnvironment: boolean,
  isMobile: boolean,
  assignedSecurityRights?: number[]
): Route | undefined => {
  console.log(
    'top menu routes',
    getTopMenuRoutes(getSetting, assignedSecurityRights)
  );

  return getTopMenuRoutes(getSetting, assignedSecurityRights).find(
    ({ name }) => name === 'market-assistant'
  );
  // TODO - hidig this as we dont need to do a filter now. Market
  // asstant is aways default
  // return getTopMenuRoutes(getSetting, assignedSecurityRights).find(
  //   i =>
  //     hasUserAccess(i.securityRights, assignedSecurityRights) &&
  //     (lowerLevelEnvironment ? true : !i.isBeta) &&
  //     !(isMobile && i.hideOnMobile) // If mobile version and flag hideOnMobile is set to true -> discard it on mobile & native app
  // );
};
/*
 * Return array with all modules in OBX
 * Get ROUTES_CONFIG from routes.tsx and map it for top menu use
 * Get props from topMenuConfig and optionally (if defined),
 * Map Custom Routes that depends on user security rights / ui settings
 */
export const getTopMenuRoutes = (
  //eslint-disable-next-line
  getSetting: (key: string | null) => any,
  assignedSecurityRights?: number[]
): Route[] =>
  ROUTES_CONFIG.filter(routeConfig => routeConfig.topMenuConfig).map(
    routeConfig => {
      let route: string = routeConfig.topMenuConfig?.route ?? '';

      switch (route) {
        case CustomRoute.Workflows:
          route = `/workflows/${
            getSetting(UISettings.WORKFLOWS_CONFIG)?.provider ??
            WorkflowProvider.Onboarding
          }/${getSetting(UISettings.WORKFLOWS_CONFIG)?.lastOpenStatus ?? '0'}`;
          break;
        case CustomRoute.Surveillance:
          route = assignedSecurityRights?.includes(SecurityRights.Surveillance)
            ? '/surveillance/results'
            : '/surveillance/sanctions/shipping-fixtures';
          break;
      }

      return {
        ...routeConfig.topMenuConfig!,
        route,
      };
    }
  );
