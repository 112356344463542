import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Blocker, BlockerFunction, useBeforeUnload, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';

import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';

import { PositionConfidentialityEnum } from '../../../PositionsList/Models/Enums';
import { CargoUpdateRequest } from '../../Models/CargoTrackerRequest';
import { CargoTrackerResponse } from '../../Models/CargoTrackerResponse';
import { CargoTrackerModeEnum, StatusEnum } from '../../Models/Enums';
import { spotCargoValidator, tcCargoValidator } from '../../Models/Validators';
import { CargoTrackerAPI } from '../../Services/CargoTrackerAPI';
import { useUpdateCargo } from '../../Services/hooks';
import { CargoTrackerSignalEventTypes } from '../../Services/SignalRSocket';
import CargoEditWarningDialog, {
  CargoEditDialogMode,
  CargoEditWarningDialogEvents,
  deferNextAction
} from '../CargoEditWarningDialog';

import CargoEditSpot from './CargoEditSpot';
import CargoEditTimeCharter from './CargoEditTimeCharter';

import useNavigationBlocker from 'helpers/Hooks/NavigationBlocker';
import { EnumKeys, getKeyValuePairs } from 'helpers/Utils/enum';

import eventBus from 'server/EventBus';

import './CargoEdit.scss';

interface ICargoEditProps {
  activeCargo: string | null;
  setActiveCargo: Dispatch<SetStateAction<string | null>>;
  closeEdit: () => void;
  showSuccess: (isNew: boolean) => void;
  cargoData: CargoTrackerResponse[] | undefined;
  resultsMode: CargoTrackerModeEnum;
}

const CargoEdit = (props: ICargoEditProps):JSX.Element => {
  const { activeCargo, setActiveCargo, closeEdit, cargoData, resultsMode, showSuccess } = props;

  const containerRef = useRef(null);
  const formRef = useRef<HTMLFormElement>(null);
  const selectedVisibilityRef = useRef<RadioButton>(null);

  const [ request, setRequest ] = useState<CargoUpdateRequest>({ confidentialityIndicator: PositionConfidentialityEnum.Open } as CargoUpdateRequest);
  const [ isValid, setIsValid ] = useState<boolean>(false);
  const [ isParsing, setIsParsing ] = useState<boolean>(false);
  const [ isValidating, setIsValidating ] = useState<boolean>(false);
  const [ isSaveDeferred, setIsSaveDeferred ] = useState<boolean>(false);
  const [ dialogMode, setDialogMode ] = useState<CargoEditDialogMode>(2);
  const [ isValidationVisible, setIsValidationVisible ] = useState<boolean>(false);
  const [ isSavePressed, setIsSavePressed ] = useState<boolean>(false);
  const [ isDeletedDialogVisible, setIsDeletedDialogVisible ] = useState<boolean>(false);
  const [ isCurrentCargoDeleted, setIsCurrentCargoDeleted ] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const visibility: EnumKeys[]  = getKeyValuePairs(PositionConfidentialityEnum);
  const { obxuser } = useLoggedInUser();
  const location = useLocation();

  const { trigger, isMutating } = useUpdateCargo(activeCargo);

  const handleCancel = async (force?: boolean): Promise<void> => {
    const cancel = ():void => {
      setActiveCargo(null);
      setRequest(CargoTrackerAPI.createEmptyRequest());
      closeEdit();
    };

    if (!force && await deferNextAction(CargoEditWarningDialogEvents.BEFORE_ACTION, CargoEditWarningDialogEvents.SET_CONTINUE_ACTION, cancel)) {
      return;
    }

    cancel();
  };


  const data = activeCargo && cargoData ? cargoData.find(e => e.id === activeCargo) : undefined;

  const handleSave = useCallback(async ():Promise<void> => {
    setIsSavePressed(true);
    if (isValid && !isParsing && !isValidating) {
      const updatedRequest = {...data, ...request};

      // @ts-ignore // TODO: Fix TS for this as SWRMutationResponse['trigger'] type is not working
      const saveDataResult = await trigger({data: updatedRequest}); // Save data

      if (saveDataResult) {
        showSuccess(!activeCargo && !updatedRequest.id);
      }

      setActiveCargo(null);
      setRequest(CargoTrackerAPI.createEmptyRequest());
      closeEdit();
    } else if (isParsing || isValidating) {
      setIsSaveDeferred(true);
      setIsValidating(true);
    }
  }, [activeCargo, closeEdit, data, isParsing, isValid, isValidating, request, setActiveCargo, showSuccess, trigger]);

  // Handle case when input is parsing data (e.g. DateTimeRange) and we need to wait and defer data save
  useEffect(() => {
    if (isSaveDeferred && !isParsing && !isValidating) {
      setIsSaveDeferred(false);
      handleSave();
    }
  }, [handleSave, isParsing, isSaveDeferred, isValidating]);

  useEffect(() => {
    if (data) {
      setRequest(data);
    }
  }, [data]);

  useEffect(() => {
    setDialogMode(isValid ? CargoEditDialogMode.UnsavedChanges : CargoEditDialogMode.NotAllRequiredFilled);
  }, [isValid]);

  useEffect(() => {
    const isEqual = JSON.stringify(data) === JSON.stringify(request);

    setIsValidationVisible(!!(data && request.id && !isEqual) || (!data && isSavePressed));
  }, [data, isSavePressed, isValid, request]);

  /**
   * This one will show default Browser's prompt as it is not possible to block navigation without it.
   * NOTE: Problem is that cargo is validated after "onchange" event and that means if user change something
   * and imidiatelly push "reaload" button validation won't be fired (as event wasn't fired yet) and cargo might be still considered
   * as valid at this moment.
   * TODO: fix?
   */
  useBeforeUnload(
    useCallback(async (event: BeforeUnloadEvent) => {
      if (isValidationVisible) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        deferNextAction(CargoEditWarningDialogEvents.BEFORE_ACTION);
        // Chrome requires returnValue to be set.
        return event.returnValue = '';
      }
    }, [isValidationVisible]), { capture: true }
  );

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && (isValidationVisible || isParsing || isValidating),
    [isParsing, isValidating, isValidationVisible]
  );

  useNavigationBlocker(shouldBlock, (blocker: Blocker) => {
    deferNextAction(CargoEditWarningDialogEvents.BEFORE_ACTION,
      CargoEditWarningDialogEvents.SET_CONTINUE_ACTION,
      () => {
      if (typeof blocker.proceed === 'function') {
        try {
          blocker.proceed();
        } catch (e) {
          console.log('Blocker.proceed', e);
        }
      }
    });
  });

  useEffect(() => {
    // close panel if tab (secondary nav) has been changed
    if (request.type && (resultsMode !== request.type)) {
      closeEdit();
    }
  }, [closeEdit, location, request, resultsMode]);

  // console.info('CargoEdit - Data', activeCargo, data, request); // TODO: Remove later

  useEffect(() => {
    if (activeCargo === null) { // Default for new cargo
      setRequest(CargoTrackerAPI.createEmptyRequest({
        // set current user as default Assignee
        assignedUser: obxuser && { userId: obxuser.userId, userName: obxuser.name }
      }));
      mutateCargo({
        type: resultsMode,
        status: StatusEnum.New,
        confidentialityIndicator: PositionConfidentialityEnum.Open
      });
    }
  }, [activeCargo, obxuser, resultsMode]);

  useEffect(() => {
    const validate = async ():Promise<void> => {
      try {
        setIsValidating(true);
        const updatedRequest = {...data, ...request};
        const validatorSchema = request.type === CargoTrackerModeEnum.Spot ? spotCargoValidator : tcCargoValidator;
        const result = await validatorSchema.validateAsync(updatedRequest, {convert: false});

        if (result) {
          setIsValid(true);
        }
        setIsValidating(false);
      } catch(e) {
        // console.info('CargoEdit', e);
        setIsValid(false);
        setIsValidating(false);
      }
    };

    validate();
  }, [data, request]);

  const mutateCargo = (mutation: Partial<CargoUpdateRequest>): void => setRequest(c => ({ ...c, ...mutation }));

  const handleVisibilityChange = (value: PositionConfidentialityEnum):void => {
    mutateCargo({ confidentialityIndicator: value });
    if (value === PositionConfidentialityEnum.VPNC) { // When VPNC setting Assignee to current user
      mutateCargo({assignedUser: {userId: obxuser?.userId ?? '', userName: obxuser?.name ?? ''}});
    }
  };

  const headerText = useMemo(
    () => request?.id ?
      `Edit ${ CargoTrackerModeEnum[request.type ?? CargoTrackerModeEnum.TC] }` :
      `Add ${ CargoTrackerModeEnum[request.type ?? CargoTrackerModeEnum.TC] }`,
    [request?.id, request.type]
  );

  const handleSignalRDelete = useCallback((event: CustomEvent<{ cargoId: string; }>): void => {
    const { cargoId} = event.detail;

    if (activeCargo === cargoId) {
      setIsCurrentCargoDeleted(true);
      setIsDeletedDialogVisible(true);
    }
  }, [activeCargo]);

  useEffect(() => {
    eventBus.on(
      CargoTrackerSignalEventTypes.CARGO_TRACKER_DELETED,
      handleSignalRDelete
    );

    return () => {
      eventBus.remove(
        CargoTrackerSignalEventTypes.CARGO_TRACKER_DELETED,
        handleSignalRDelete
      );
    };
  }, [handleSignalRDelete]);

  useEffect(() => {
    if(!request.id) {
      selectedVisibilityRef.current?.focus();
    }
  }, [request.id]);

  return <>
    <div className="direction--column cargo-edit__container">
      { !isMobile && <header>{headerText}</header> }
      <div className='direction--column position--relative grow-to-fill overflow--hidden' ref={containerRef}>
        <form className="cargo-edit__form" ref={formRef}>

          <div className="form-input__container ce-visibility__container">
            <label htmlFor="ce-visibility">
              Visibility
              {request.userRights?.canEditVisibility === false && <i className="iconoir-lock icon--tiny"></i>}
            </label>
            <div className={clsx('form-input--radio-buttons', request.userRights?.canEditVisibility === false && 'p-disabled')}>
              { visibility.map(t => {
                const { key, value } = t;
                return <div key={`${ key }-${ value }`}>
                  <RadioButton
                    id={`radio-${ key }`}
                    ref={request.userRights?.canEditVisibility !== false && value === request.confidentialityIndicator ? selectedVisibilityRef : null}
                    value={value}
                    name="ce-visibility"
                    tabIndex={0}
                    checked={value === request.confidentialityIndicator}
                    onChange={(e: RadioButtonChangeEvent):void => handleVisibilityChange(e.value)}
                  />
                  <label htmlFor={`radio-${ key }`}>{key}</label>
                </div>;
              })}
            </div>
          </div>

          {request.type === CargoTrackerModeEnum.TC &&
            <CargoEditTimeCharter
              key={`ce-tc-${ request.id }`}
              request={request}
              mutateCargo={mutateCargo}
              showErrors={!isValid && isValidationVisible}
              setIsParsing={setIsParsing}
            />
          }
          {request.type === CargoTrackerModeEnum.Spot &&
            <CargoEditSpot
              key={`ce-spot-${ request.id }`}
              request={request}
              mutateCargo={mutateCargo}
              showErrors={!isValid && isValidationVisible}
              setIsParsing={setIsParsing}
            />
          }

        </form>
        <footer className="cargo-edit__footer">
          { isMobile ?
            <>
              <Button
                className="p-button-filled"
                loading={isMutating}
                disabled={isCurrentCargoDeleted && activeCargo !== null}
                onClick={async ():Promise<void> => handleSave()}>
                Save
              </Button>
              <Button
                className="ce-mobile-cancel p-button-text"
                icon="icon--small icon--primary iconoir-fast-arrow-right icon--right"
                iconPos="right"
                onClick={():Promise<void> => handleCancel(isCurrentCargoDeleted)}>
                Return To List
              </Button>
            </> :
            <>
              <Button
                size='small'
                text
                severity='danger'
                onClick={():Promise<void> => handleCancel(isCurrentCargoDeleted)}
              >
                Cancel
              </Button>
              <Button
                size='small'
                severity="success"
                loading={isMutating}
                disabled={isCurrentCargoDeleted && activeCargo !== null}
                onClick={async ():Promise<void> => handleSave()}
              >
                Save
              </Button>
            </>
          }
        </footer>
        <CargoEditWarningDialog
          containerRef={containerRef}
          handleCancel={handleCancel}
          handleSave={handleSave}
          isMutating={isMutating}
          isValidationVisible={isValidationVisible}
          mode={dialogMode}
        />
      </div>
      <Dialog
        appendTo={containerRef.current}
        position="top"
        className="cargo-edit-delete__dialog p-dialog--margin-less p-dialog--no-header grow-to-fill"
        contentClassName="grow-to-fill"
        visible={isDeletedDialogVisible}
        footer={<div className='grow-to-fill align--right'>
          <Button
            size='small'
            text
            onClick={():void => setIsDeletedDialogVisible(false)}
            severity={'danger'}>OK</Button></div>}
        onHide={():void => setIsDeletedDialogVisible(false)}>
        <h4>This cargo has been deleted</h4>
        Another user has deleted the cargo, so you will no longer be able to make any changes.
      </Dialog>
    </div>
  </>;
};

export default CargoEdit;
