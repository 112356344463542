import { forwardRef, ReactElement, useRef } from 'react';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';

import { ToastSeverity } from 'components/ToastMessage';

import { ApplicationInternalEventTypes } from 'models/shared/Enums';
import eventBus from 'server/EventBus';

interface ConversationAnswerProps {
  content: string;
  messageId?: string;
  isLoading?: boolean;
}

const ConversationAnswer = forwardRef<HTMLDivElement, ConversationAnswerProps>(
  (props, ref): ReactElement => {
    const { content, messageId, isLoading } = props;
    const mdRef = useRef<HTMLSpanElement>(null);
  
    const copyToClipBoard = async (): Promise<void> => {
      const innerHTML = mdRef.current?.innerHTML ?? '';
      const plainText = mdRef.current?.innerText || mdRef.current?.textContent || '';
  
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([innerHTML], { type: 'text/html' }),
        'text/plain': new Blob([plainText], { type: 'text/plain' }),
      });
  
  
      await navigator.clipboard.write([clipboardItem]);
  
      eventBus.dispatch(
        ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
        {
          severity: ToastSeverity.SUCCESS,
          title: 'Answer copied to clipboard',
          message: 'You can now paste this into place you want',
        }
      );
    };
  
    return (
      <div className={clsx('answer', {'answer-loading': isLoading})} ref={ref}>
        <Avatar icon='pi iconoir-sparks' shape='circle' />
  
        {(!isLoading && content) ?
          <span ref={mdRef}><Markdown className='answer-md'>{ content }</Markdown></span> :
          <div className="dots-pulse">{ content }<span>&bull;</span><span>&bull;</span><span>&bull;</span></div>
        }
  
        {messageId && <div className='answer-actions'>
  
          {!isLoading && <Button
            icon='iconoir-multiple-pages icon--small'
            className='answer-actions__button'
            size='small'
            onClick={copyToClipBoard}
          />}
  
          {/* TODO: Like / Dislike / Email Me will be used in following tasks
          <Button
            icon='iconoir-thumbs-up icon--small'
            className='answer-actions__button'
            size='small'
            onClick={() => {}}
          ></Button>
          <Button
            icon='iconoir-thumbs-down icon--small'
            className='answer-actions__button'
            size='small'
            onClick={() => {}}
          ></Button>
          <Button
            icon='iconoir-mail icon--small'
            className='answer-actions__button'
            text
            size='small'
            onClick={() => {}}
          >Email Me</Button>
          */}
        </div>}
      </div>
    );
  });

ConversationAnswer.displayName = 'ConversationAnswer';

export default ConversationAnswer;