import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSignalR } from 'App';
import clsx from 'clsx';
import { Button } from 'primereact/button';
import { DataTableSelectionSingleChangeEvent, DataTableValueArray } from 'primereact/datatable';

import Loader from 'components/Loader';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { useLoadUserSettings, useSaveUserSetting } from 'components/OBXUser/Services/ProfileHooks';
import SecondaryNavigation from 'components/SecondaryNavigation';
import ToastMessage, {
  ToastMessageRef, ToastSeverity,
} from 'components/ToastMessage';
import { WorksheetStores } from 'components/Worksheets/Models/Enums';
import { WorksheetMetaProps } from 'components/Worksheets/Models/WorksheetResponse';
import { useCreateNewWorksheet } from 'components/Worksheets/Services/WorksheetHooks';

import { deferNextAction } from '../CargoTracker/Components/CargoEditWarningDialog';

import { DistListWarningDialogEvents } from './Components/CloseWarningDialog';
import List from './Components/List';
import SidePanel from './Components/SidePanel';
import Single from './Components/Single';
import SingleSearch from './Components/SingleSearch';
import NewSingleMessageButton from './Components/SingleSearch/NewSingleMessageButton';
import SingleSidePanel from './Components/SingleSidePanel';
import { DistListDetailsTab, DistListModeEnum } from './Models/Enums';
import { useGetDistLists } from './Services/DistListService';
import { DistListSignalEventTypes, DistributionListSocket } from './Services/SignalRSocket';

import { replaceItemAt } from 'helpers/Utils/collections';
import { parsePropsToDateTime } from 'helpers/Utils/misc';
import { stripParams } from 'helpers/Utils/string';

import eventBus from 'server/EventBus';

import type { DistListConfig } from 'types/routes-type';
import type {
  DistributionList,
  DistributionListSummary,
  SingleMailEmailResponse
} from './Models/distribution-list-response';

import './DistListPage.scss';

type ResetStateDefaultOpts = {
  resetRoute: boolean;
};

const resetStateDefaultOpts: ResetStateDefaultOpts = {
  resetRoute: true,
};

interface DistListProps {
  items: DistListConfig[];
  resultsMode: DistListModeEnum;
}

const DistListPage = (props: DistListProps): ReactElement => {
  const { items, resultsMode } = props;
  const isDL = resultsMode === DistListModeEnum.dl;
  const isSingle = resultsMode === DistListModeEnum.single;

  const { loadDistlistData, loadDistlistError, loadDistlistIsLoading } = useGetDistLists(isDL);
  const toast = useRef<ToastMessageRef>(null);
  const searchContainerRef = useRef<HTMLElement>(null);
  const [distLists, setDistLists] = useState<DistributionListSummary[] | undefined | void>(undefined);
  const [singleMessages, setSingleMessages] = useState<SingleMailEmailResponse[] | undefined | void>(undefined);

  const [ isRightColumnVisible, setIsRightColumnVisible] = useState<boolean>(false);
  const [selectedDL, setSelectedDL] = useState<DistributionListSummary | null>(null);
  const [selectedSingleId, setSelectedSingleId] = useState<SingleMailEmailResponse['id'] | null>(null);

  const [ isLoading, setIsLoading] = useState<boolean>(false);
  const [ isLoadingSingle, setIsLoadingSingle ] = useState<boolean>(false);
  const [ isNewSingleMessage, setIsNewSingleMessage] = useState<boolean>(false);
  const [ activeDetailTab, setActiveDetailTab ] = useState<number>(0);
  const [ isSingleSearchEmpty, setIsSingleSearchEmpty ] = useState<boolean>(true);

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const { signal } = useSignalR();

  const navigate: NavigateFunction = useNavigate();
  const currentRoute = useLocation();
  const { distListIdParam, emailIdParam, singleIdParam, activeTab } = useParams();

  const { getSetting } = useLoadUserSettings();
  const { trigger } = useSaveUserSetting();
  const { newWorksheet } = useCreateNewWorksheet(WorksheetStores.DistributionList);
  const activeWorksheet = useRef<string>(getSetting(UISettings.DISTRIBUTION_LIST_ACTIVE_WORKSHEET));

  const addNewWorksheet = useCallback(async ():Promise<void> => {
    activeWorksheet.current = '-';
    const data = await newWorksheet({
      store: WorksheetStores.DistributionList,
      hydrator: (): WorksheetMetaProps[] => [],
      name: 'Distribution List Worksheet'
    });

    if (!data || !data.worksheetId) {
      return;
    }

    try {
      trigger({
        setting: UISettings.DISTRIBUTION_LIST_ACTIVE_WORKSHEET,
        data: data.worksheetId
      });
    } catch {
      toast.current?.replace({
        title: 'Issue occurred',
        message: 'Issue when storing active worksheet',
        severity: ToastSeverity.WARN
      });
    }
    // eslint-disable-next-line no-console
    console.info('Distribution List worksheet added:', data.worksheetId);
    activeWorksheet.current = data.worksheetId;
  }, [newWorksheet, trigger]);

  useEffect(() => {
    if (activeWorksheet.current) {
      // eslint-disable-next-line no-console
      console.info('Distribution List active worksheet:', activeWorksheet.current);
    } else {
      addNewWorksheet();
    }
  }, [addNewWorksheet]);

  useEffect(() => {
    setDistLists(loadDistlistData);
  }, [loadDistlistData]);

  const stripRoute = useCallback(():string => {
    let route = stripParams(currentRoute.pathname, distListIdParam); // // Strip DistListID, selectedDL?.id
    route = stripParams(route, emailIdParam); // Strip also email ID
    route = stripParams(route, singleIdParam); // Strip also single ID
    route = stripParams(route, activeTab); // Strip also activeTab
    return route;
  }, [activeTab, currentRoute.pathname, distListIdParam, emailIdParam, singleIdParam]);


  const resetState = useCallback((opts = resetStateDefaultOpts): void => {
    if (opts.resetRoute) {
      const route = stripRoute();
      navigate(route);
    }

    setSelectedDL(null);
    setSelectedSingleId(null);
  }, [navigate, stripRoute]);

  const handleCloseSidePanel = useCallback((): void => {
    resetState();
    setIsRightColumnVisible(false);
    setIsNewSingleMessage(false);
  }, [resetState]);

  useEffect(() => {
    if (distListIdParam && !loadDistlistIsLoading && distLists !== undefined) {
      const foundDistList = distLists.find(list => list.id === distListIdParam);
      if (foundDistList) {
        setSelectedDL(foundDistList);
        setIsRightColumnVisible(true);
      } else {
        toast.current?.replace({
          title: 'Wrong Distribution List ID',
          message: 'Provided Distribution List ID in link was not found',
          severity: ToastSeverity.WARN
        });
      }
    } else if (singleIdParam && !singleMessages && !isSingleSearchEmpty && !selectedSingleId) {
      // If there is ID single param in link but there are search params - clear first search
      eventBus.dispatch(DistListSignalEventTypes.DIST_LIST_SINGLE_CLEAR_SEARCH, null);
    } else if (singleIdParam && !isLoadingSingle && singleMessages !== undefined) {
      const foundSingle = singleMessages.find(list => list.id === singleIdParam);
      if (foundSingle) {
        setActiveDetailTab(curr => (activeTab && activeTab === DistListDetailsTab.recipients) ? 1 : curr);
        setSelectedSingleId(singleIdParam);
        setIsRightColumnVisible(true);
      } else {
        toast.current?.replace({
          title: 'Wrong Distribution Single Message ID',
          message: `Provided Single Message ID in link was not found (${ singleIdParam })`,
          severity: ToastSeverity.WARN
        });
      }
    }
  }, [
    activeTab,
    distListIdParam,
    distLists,
    isSingleSearchEmpty,
    handleCloseSidePanel,
    isLoadingSingle,
    isSingle,
    loadDistlistIsLoading,
    singleIdParam,
    singleMessages,
    selectedSingleId
  ]);

  // On resultMode change, hide right panel
  useEffect(() => {
    resetState({ resetRoute: false });
    setIsRightColumnVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsMode]);

  const addDLButton = useMemo(() => {
    const handleAddDL = (): void => {
      resetState();
      setActiveDetailTab(1);
      setIsRightColumnVisible(true);
    };

    return (
      <Button
        size="small"
        className="distlist-add no-background"
        onClick={async ():Promise<void> => {
          const result = await deferNextAction(DistListWarningDialogEvents.ACTION,
            null,
            () => handleAddDL());
          if (result) {
            return;
          }

          handleAddDL();
        }}
      >
        Add distribution list
      </Button>);
  }, [resetState]);

  const handleSelection = (value?: DistributionList):void => {
    if (value?.id) {
      const route = stripRoute();
      navigate(`${ route }${ value.id }`);
      setSelectedDL(value);
      setIsRightColumnVisible(true);
      setIsNewSingleMessage(false);
    } else {
      handleCloseSidePanel();
    }
  };

  const handleSelectionSingle = (value?: SingleMailEmailResponse): void => {
    if (value?.id) {
      const route = stripRoute();
      navigate(`${ route }${ value.id }`);
      setSelectedSingleId(value.id);
      setIsRightColumnVisible(true);
      setIsNewSingleMessage(false);
    } else {
      handleCloseSidePanel();
    }
  };

  const handleCreateNewMessage = ():void => {
    resetState();
    setIsRightColumnVisible(true);
    setIsNewSingleMessage(true);
  };

  const handleSelectionChange = async (e: DataTableSelectionSingleChangeEvent<DataTableValueArray>): Promise<void> => {
    const result = await deferNextAction(DistListWarningDialogEvents.ACTION,
      null,
      () => handleSelection(e.value as DistributionList));
    if (result) {
      return;
    }

    handleSelection(e.value as DistributionList);
  };

  useEffect(() => {
    setIsLoading(loadDistlistIsLoading || loadDistlistError !== undefined);
  }, [loadDistlistIsLoading, loadDistlistError]);

  useEffect(() => {
    const socket: DistributionListSocket = DistributionListSocket.instance;
    socket.init(signal);
  }, [signal]);

  const handleUpdateData = useCallback((event: CustomEvent<DistributionList>): void => {
    if (distLists === undefined) {
      return;
    }

    const index: number = distLists.findIndex(d => d.id === event.detail.id);
    const parsedData = parsePropsToDateTime(event.detail, ['lastEmailDate']);
    const newData = (index !== -1 && index !== undefined) ?
      replaceItemAt(distLists, parsedData, index) : // Update existing
      [ // Add new item
        ...distLists,
        parsedData
      ];
    setDistLists(newData);
  }, [distLists]);

  useEffect(() => {
    eventBus.on(DistListSignalEventTypes.DIST_LIST_UPDATED, handleUpdateData);

    return ():void => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_UPDATED, handleUpdateData);
    };
  }, [handleUpdateData]);

  return (
    <>
      {(!isMobile || !((isDL && selectedDL) || (isSingle && selectedSingleId))) && // Hide navigation on Mobile if DL or Single ITEM is selected
        <nav className='distlist-navigation'>
          <SecondaryNavigation items={items} />
        </nav>}
      {isDL && !isMobile &&
        <header className='align--right distlist-header'>
          {addDLButton}
        </header>}
      {isSingle &&
        <header className={clsx('distlist-header', (isNewSingleMessage || selectedSingleId) && isMobile && 'hidden')} ref={searchContainerRef}>
          <SingleSearch
            handleCreateNewMessage={handleCreateNewMessage}
            singleMessages={singleMessages}
            setSingleMessages={setSingleMessages}
            setIsLoadingSingle={setIsLoadingSingle}
            searchContainerRef={searchContainerRef}
            activeWorksheet={activeWorksheet.current}
            isSearchEmpty={isSingleSearchEmpty}
            setIsSearchEmpty={setIsSingleSearchEmpty}
            handleCloseSidePanel={handleCloseSidePanel}
            isRightColumnVisible={isRightColumnVisible}
          />
        </header>}
      <main
        className={clsx(
          'distlist-container',
          ((isDL && distLists !== undefined && distLists?.length > 0) ||
           (isSingle && singleMessages !== undefined && (singleMessages.length > 0 || singleMessages.length === 0 && !isSingleSearchEmpty)))
          && 'distlist-container-table',
          { 'drawer--active': isRightColumnVisible },
          'grow-to-fill')}
        {...(isRightColumnVisible && {
          'data-cols': '5,7',
          'data-drawer-style': 'slide',
          'data-drawer-position': 'alongside-right',
        })}
      >
        {isLoading && (
          <section className="distlist-empty grow-to-fill">
            <Loader className="no-background" />
          </section>
        )}
        {!isLoading && <>
          {((isDL && distLists !== undefined && distLists.length === 0) ||
            (isSingle && singleMessages !== undefined && singleMessages.length === 0 && isSingleSearchEmpty)) && (
            <section className="distlist-empty grow-to-fill">
              <div>
                <i className="iconoir-folder-plus no-background"></i>
                <h2 className="no-background">
                  {isDL ?
                    'It looks like no list has been added yet' :
                    'It looks like no message has been created yet'}
                </h2>
                {isDL ?
                  addDLButton :
                  <NewSingleMessageButton
                    handleCreateNewMessage={handleCreateNewMessage}
                  />
                }
              </div>
            </section>
          )}
          {isDL && distLists !== undefined && distLists?.length > 0  &&
          <List
            distLists={distLists}
            selectedDL={selectedDL}
            handleSelection={handleSelection}
            handleSelectionChange={handleSelectionChange}
          />}
          {isSingle && singleMessages !== undefined && (singleMessages.length > 0 || singleMessages.length === 0 && !isSingleSearchEmpty) &&
          <Single
            singleMessages={singleMessages}
            selectedSingleId={selectedSingleId}
            handleSelection={handleSelectionSingle}
            handleSelectionChange={handleSelectionChange}
            isLoadingData={isLoadingSingle}
          />}
        </>}
        {isRightColumnVisible && isDL && (
          <SidePanel
            activeDL={selectedDL}
            activeDetailTab={activeDetailTab}
            setActiveDetailTab={setActiveDetailTab}
            handleSelection={handleSelection}
            handleClose={handleCloseSidePanel}
            toast={toast}
          />
        )}
        {isRightColumnVisible && isSingle && (
          <SingleSidePanel
            activeSingle={selectedSingleId}
            activeDetailTab={activeDetailTab}
            setActiveDetailTab={setActiveDetailTab}
            handleClose={handleCloseSidePanel}
            toast={toast}
            isNew={isNewSingleMessage}
          />
        )}
      </main>
      {(isMobile && !isRightColumnVisible) &&
        <footer className="align--right distlist-footer">
          { isDL ?
            addDLButton :
            <NewSingleMessageButton
              handleCreateNewMessage={handleCreateNewMessage}
            />
          }
        </footer>}
      <ToastMessage ref={toast} className="distlist-toast" />
    </>
  );
};

export default DistListPage;
