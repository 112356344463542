import { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './DropdownMenuItem.module.scss';

type DropdownMenuItemProps = {
  icon: string;
  label?: string;
  isChecked?: boolean;
};

const DropdownMenuItem = ({
  icon,
  label = '',
  isChecked = false,
}: DropdownMenuItemProps): ReactNode => (
  <a
    href='#'
    className={clsx('p-menuitem-link grow-to-fill', styles.menuItemLink)}
  >
    <i className={clsx(icon, 'icon--small')} />
    <div className='grow-to-fill'>{label}</div>
    <i
      className={clsx(
        {
          'iconoir-check': isChecked,
        },
        'icon--small'
      )}
    />
  </a>
);

export { DropdownMenuItem };
export default DropdownMenuItem;
