import { Dispatch, ReactElement, SetStateAction, useEffect,useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, Location, useLocation } from 'react-router-dom';
import { User } from '@auth0/auth0-spa-js';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

import { UISettings } from 'components/OBXUser/Model/Enums';
import { useLoadUserSettings, useLoggedInUser, useSaveUserSetting } from 'components/OBXUser/Services/ProfileHooks';

import BentoMenu from './Components/BentoMenu/BentoMenu';
import Profile from './Components/Profile/Profile';
import { getAvailableRoutes, getTopMenuRoutes } from './Models/TopMenuRoutes';
import { Route } from './Models/Types';

import { notNil } from 'helpers/Utils/misc';

import './TopMenu.scss';

interface ITopMenuProps {
  user: User | undefined;
  toggleState: (force?: boolean) => void;
  lowerLevelEnvironment: boolean;
  topMenuPinned: boolean;
  setTopMenuPinned: Dispatch<SetStateAction<boolean>>;
  signalRStatus?: boolean;
  heading?: string;
}

const TopMenu = (props: ITopMenuProps):ReactElement => {
  const { signalRStatus, user, toggleState, heading, topMenuPinned, setTopMenuPinned, lowerLevelEnvironment } = props;

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const { getSetting } = useLoadUserSettings();
  const { trigger: saveUserSettings } = useSaveUserSetting();
  const { obxuser } = useLoggedInUser();
  const currentRoute: Location = useLocation();

  const bentoMenuOverlayRef = useRef<OverlayPanel>(null);
  const profileOverlayRef = useRef<OverlayPanel>(null);

  useEffect(() => {
    const userSettingPinned = getSetting(UISettings.TOP_MENU_PINNED);
    if (notNil(userSettingPinned) && userSettingPinned !== topMenuPinned && !isTabletOrMobile) {
      setTopMenuPinned(userSettingPinned);
    }
    // Run only on init
    // eslint-disable-next-line
  }, []);

  const togglePinned = (): void => {
    setTopMenuPinned(!topMenuPinned);
    saveUserSettings({
      setting: UISettings.TOP_MENU_PINNED,
      data: !topMenuPinned
    });

  };

  useEffect(() => {
    if (isTabletOrMobile) {
      setTopMenuPinned(true);
    }
    // Makes issues with underlay on mobile version
    // eslint-disable-next-line
  }, [isTabletOrMobile]);

  const modules: Route[] = getTopMenuRoutes(getSetting, obxuser?.assignedSecurityRights);

  // Modules, that will be available in Bento Menu as buttons and to pick for "default module"
  const availableModules = getAvailableRoutes(modules, lowerLevelEnvironment, obxuser?.assignedSecurityRights);

  const pathnameSplit = currentRoute.pathname.split('/');
  const currentModule: Route | undefined = modules.find(item => [0, 1].includes(item.route.indexOf(pathnameSplit[1])));

  // If there is NO CLDD set -> set first isSelectable to user settings
  useEffect(() => {
    const firstSelectable = obxuser?.cldds.find(cldd => cldd.isSelectable)?.code;
    if (!getSetting(UISettings.ACTIVE_CLDD) && !!firstSelectable) {
      saveUserSettings({
        setting: UISettings.ACTIVE_CLDD,
        data: firstSelectable // Select first which is "isSelectable"
      });
    }
  }, [saveUserSettings, getSetting, obxuser?.cldds]);

  const handleMouseLeave = ():void => {
    if (bentoMenuOverlayRef.current?.isVisible() && !isTabletOrMobile && !topMenuPinned) {
      bentoMenuOverlayRef.current?.hide();
    }
    if (profileOverlayRef.current?.isVisible() && !isTabletOrMobile && !topMenuPinned) {
      profileOverlayRef.current?.hide();
    }
  };

  return (
    <nav className='top-menu__container' onMouseLeave={handleMouseLeave}>
      <div className='top-menu__content'>
        <BentoMenu
          modules={availableModules}
          bentoMenuOverlayRef={bentoMenuOverlayRef}
          assignedSecurityRights={obxuser?.assignedSecurityRights || []}
          lowerLevelEnvironment={lowerLevelEnvironment}
        />

        <div className='title'>
          <h1>{currentModule?.label ?? heading ?? 'OB'}</h1>
        </div>

        {currentModule?.name !== 'market-assistant' && <Link to='/market-assistant' className='p-button assistant'>
          <i className='iconoir-sparks no-background'></i>
          Ask Market Assistant
        </Link>}

        <Button
          className={topMenuPinned ? 'unpin' : 'pin'}
          icon={`iconoir-${ topMenuPinned ? 'pin-solid' : 'pin' } icon--small`}
          onClick={togglePinned}
        />

        <div className='profile'>
          {user &&
            <Profile
              user={user}
              signalRStatus={signalRStatus}
              toggleState={toggleState}
              profileOverlayRef={profileOverlayRef}
            />}
        </div>

        <div className='obx-logo'>
          <img src='/obx-logo.png' alt='OBX' />
        </div>

        {!topMenuPinned && (
          <Button
            className='top-menu__hide-button'
            icon='iconoir-nav-arrow-up icon--tiny'
            aria-label='Hide Top Menu'
          />
        )}
      </div>
      {!topMenuPinned && (
        <Button
          className='top-menu__show-button'
          icon='iconoir-nav-arrow-down icon--tiny'
          aria-label='Show Top Menu'
        />
      )}
    </nav>
  );
};

export default TopMenu;