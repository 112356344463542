import Joi from 'joi';

import { PRICE_UNITS, QUANTITY_UNITS, TRADE_TYPES } from './Consts';

import type { TradeLeg } from './BlotterResponse';

const VALIDATION_MESSAGES = {
  'string.equal': 'Buyer and Seller company can not be the same',
  'string.differentUnit': 'Units must be the same',
  'string.empty': 'Required field',
  'any.required': 'Required field',
  'number.base': 'Required field'
};

const getCompanyValidator = (counterpartyKey: string): Joi.StringSchema<string> => Joi.string().required().custom((value, helpers) => {
  const { ancestors } = helpers.state;
  const company = ancestors[1]?.[counterpartyKey]?.company; // we know that leg object is under index === 1

  if (company && company === value) {
    return helpers.error('string.equal');
  }
  return value;
})

const getQuantityUnitValidator = (): Joi.StringSchema<string> =>
  Joi.string()
    .required()
    // .valid(...QUANTITY_UNITS) // NOTE: DO NOT USE! `custom` rule is not called when using `valid` 😔
    .custom((value, helpers) => {
      const { ancestors } = helpers.state;
      const legs = ancestors[2] as TradeLeg[]; // array with legs is under index === 2

      if (legs.some(leg => leg.quantity.unit !== value)) {
        return helpers.error('string.differentUnit');
      }

      // add validation for valid options here instead of inside `valid` rule, see comment above
      if (!QUANTITY_UNITS.includes(value)) {
        return helpers.error('any.only');
      }

      return value;
    })
    .messages({ 'any.only': 'Selected option is invalid' });

const getPriceUnitValidator = (): Joi.StringSchema<string> =>
  Joi.string()
    // .valid(...PRICE_UNITS) // NOTE: DO NOT USE!`custom` rule is not called when using `valid` 😔
    .required()
    .custom((value, helpers) => {
      const { ancestors } = helpers.state;
      const legs = ancestors[2] as TradeLeg[]; // array with legs is under index === 2

      if (legs.some(leg => leg.price.unit !== value)) {
        return helpers.error('string.differentUnit');
      }

      // add validation for valid options here instead of inside `valid` rule, see comment above
      if (!PRICE_UNITS.includes(value)) {
        return helpers.error('any.only');
      }

      return value;
    });

const brokerValidator = Joi.object({
  // company: companyValidator,
  contactName: Joi.string().required(),
  tradingAccount: Joi.string().required(),
  paysBrokerage: Joi.bool().required(),
  rate: Joi.when('paysBrokerage', {
    is: true,
    then: Joi.number().required(),
    otherwise: Joi.optional()
  }),
  obBroker: Joi.object({
    userId: Joi.when('/isImported', {
      is: true,
      then: Joi.string().optional(), // imported item has no `userId` defined
      otherwise: Joi.string().required()
    }),
    userName: Joi.string().required()
  }).required()
});

const tradeValidator = Joi.object({
  instrument: Joi.string().required(),
  dateTime: Joi.object().required(), // how to properly validate luxon.DateTime?
  type: Joi.string().valid(...TRADE_TYPES).required(),
  nextDayPriced: Joi.boolean(),
  legs: Joi.array().items(Joi.object({
    number: Joi.number().positive().required(),
    price: Joi.object({
      amount: Joi.number().required(),
      unit: getPriceUnitValidator()
    }),
    quantity: Joi.object({
      amount: Joi.number().required(),
      unit: getQuantityUnitValidator()
    }),
    buyer: brokerValidator.append({
      company: getCompanyValidator('seller')
    }),
    seller: brokerValidator.append({
      company: getCompanyValidator('buyer')
    })
  })).required(),
  clearing: Joi.object({
    cleared: Joi.boolean().allow(null),
    house: Joi.string().when('/clearing.cleared', {
      is: true,
      then: Joi.string().required(),
      otherwise: Joi.string().when('/clearing.id', {
        is: '',
        then: Joi.string().optional().allow(''),
        otherwise: Joi.string().required(),
      }),
    }),
    id: Joi.string().when('/clearing.cleared', {
      is: true,
      then: Joi.string().required(),
      otherwise: Joi.string().when('/clearing.house', {
        is: '',
        then: Joi.string().optional().allow(''),
        otherwise: Joi.string().required(),
      }),
    }),
  }),
  comments: Joi.string().optional().allow('')
}).unknown(true).messages(VALIDATION_MESSAGES);

const tradeValidatorCompanyOnly = Joi.object({
  buyer: Joi.object({ company: getCompanyValidator('seller').optional().allow('') }).unknown(true),
  seller: Joi.object({ company: getCompanyValidator('buyer').optional().allow('') }).unknown(true),
}).unknown(true).messages(VALIDATION_MESSAGES);

export const getValidator = (validateAll: boolean): Joi.ObjectSchema<any> => {
  return validateAll ? tradeValidator : tradeValidatorCompanyOnly;
};