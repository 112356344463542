import { ReactElement } from 'react';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';

import styles from './NoLongerAvailable.module.scss';

type NoLongerAvailableProps = {
  title: string;
  subtitle: string;
  addNewWorksheet: () => void | Promise<void>;
  buttonText?: string;
}

const NoLongerAvailable = (props: NoLongerAvailableProps): ReactElement => {
  const { title, subtitle, addNewWorksheet, buttonText } = props;

  return <section className={clsx(styles.section, 'grow-to-fill overflow--hidden')}>
    <img className={styles.image} alt='' src='/img/icons/surveillance-page-no-longer-available.svg'></img>
    <div className={styles.action}>
      <h2 className={styles.title}>
        This {title} is no longer available
      </h2>
      <h5 className={styles.subTitle}>
        {subtitle}
      </h5>
      <Button size='small' onClick={addNewWorksheet}>{buttonText || `add new ${ title }`}</Button>
    </div>
  </section>;
};

export default NoLongerAvailable;