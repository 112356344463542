import { ChangeEvent,useState } from 'react';
import { clsx } from 'clsx';
import { InputText } from 'primereact/inputtext';

import styles from './LimitedLengthInput.module.scss';

export enum InputContexts {
	Cell = 'Cell',
	Tab = 'Tab'
}

interface ILimitedLenghtInputParams<T> {
	currentValue: string;
	callback: (value: string | boolean, payload?: T) => void;
	maxlength?: number;
	payload?: T;
	context?: InputContexts;
	id?: string;
	className?: string;
	showError?: boolean;
	callbackOnChange?: boolean;
	showCounter?: boolean;
	preventInputOverflow?: boolean;
	autoFocus?: boolean;
	tabIndex?: number;
}

export const LimitedLenghtInput = <T, >(params: ILimitedLenghtInputParams<T>): JSX.Element => {

  const { maxlength = 30, currentValue, callback, id, className, callbackOnChange, showError = false, payload = {} as T, context = InputContexts.Tab,
    showCounter = true, preventInputOverflow = false, autoFocus = true, tabIndex = 0 } = params;

	const [ length, setLength ] = useState<number>(currentValue.length);
	const [ value, setValue ] = useState<string>(currentValue);
	const [ errorState, setErrorState ] = useState<boolean>(false);

  return <>
		<div className={clsx(
		styles.container, 
		errorState && styles.errorState,
		context === InputContexts.Tab ? styles.asTabEditor : styles.asCellEditor,
		className,
		{'p-invalid p-inputgroup': showError && errorState})}
    >
		<InputText
			id={id}
			value={value}
			autoFocus={autoFocus}
			tabIndex={tabIndex}
			className='p-inputtext-sm'
			onFocus={(e) => e.target.select()}
			onInput={(e: ChangeEvent<HTMLInputElement>) => {
				if (preventInputOverflow && e.target.value.length > maxlength) {
					return;
				}
				setValue(e.target.value);
				setLength((c) => {
					setErrorState(e.target.value.length > maxlength);
					return e.target.value.length;
				});
				callbackOnChange && callback(e.target.value, payload);
			}}
			onKeyDown={({key}) => {
				switch(key) {
					case "Escape":
						callback(false)
						break;
					case "Enter":
						if (!errorState) { callback(value, payload); }
						break;
				}
			}}
			onBlur={(e) => {
				if (!errorState) {
					//	everything good so we can trigger the callback
					callback(value, payload);
				}
			}}
		/>
		{showCounter && <span> { length } / { maxlength }</span>}
	</div>
	{showError && errorState && <small className="message-invalid">{'Too long field'}</small>}
	</>;
}

export default LimitedLenghtInput;
