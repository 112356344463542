import { Dispatch, ReactElement, RefObject, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';

import DateTimeRange from 'components/DateTimeRange';
import { WorksheetSignalMessageEventTypes, WorksheetStores } from 'components/Worksheets/Models/Enums';
import { stringifyAdditionalProps } from 'components/Worksheets/Models/Parsers';
import {
  useLoadWorksheet,
  useMutateWorksheet,
  WorksheetMutationTypes
} from 'components/Worksheets/Services/WorksheetHooks';

import { apiSearchSingleEmailRequest, SingleEmailDate } from '../../Models/apiRequest';
import {
  isSearchRequestEmpty,
  singleSearchWorksheetParsers
} from '../../Models/Parsers';
import { useSearchSingleMessages } from '../../Services/DistListService';
import { DistListSignalEventTypes } from '../../Services/SignalRSocket';

import NewSingleMessageButton from './NewSingleMessageButton';
import SingleSearchEntity from './SingleSearchEntity';

import { insertItemAt, replaceItemAt } from 'helpers/Utils/collections';
import { laycanStyleRange } from 'helpers/Utils/formatters';
import { parsePropsToDateTime, wait } from 'helpers/Utils/misc';

import eventBus from 'server/EventBus';

import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import type { SearchField } from 'components/EntitySearch/Models/SearchEntities';
import type { WorksheetMetaProps } from 'components/Worksheets/Models/WorksheetResponse';
import type { SingleMailEmailResponse } from 'modules/DistList/Models/distribution-list-response';
import type { apiSingleMailEmailResponse } from '../../Models/apiResponse';

import './SingleSearch.scss';

export const DEFAULT_SEARCH_ITEMS: apiSearchSingleEmailRequest = {
  searchRequestFields: [],
  date: undefined,
  pageSize: 1000,
  pageNumber: 1,
};

interface SingleSearchProps {
  handleCreateNewMessage: () => void;
  setSingleMessages: Dispatch<SetStateAction<void | SingleMailEmailResponse[] | undefined>>
  setIsLoadingSingle:  Dispatch<SetStateAction<boolean>>
  searchContainerRef: RefObject<HTMLElement>;
  isSearchEmpty: boolean;
  setIsSearchEmpty: Dispatch<SetStateAction<boolean>>;
  activeWorksheet: string;
  handleCloseSidePanel: () => void;
  isRightColumnVisible: boolean;
  singleMessages?: SingleMailEmailResponse[] | void;
}

const SingleSearch = (props: SingleSearchProps): ReactElement => {
  const { handleCreateNewMessage, singleMessages, handleCloseSidePanel, isRightColumnVisible, setSingleMessages, setIsLoadingSingle, searchContainerRef, isSearchEmpty, setIsSearchEmpty, activeWorksheet } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const [ isWorksheetLoaded, setIsWorksheetLoaded ] = useState<boolean>(false);
  const [ isWorksheetMutated, setIsWorksheetMutated ] = useState<boolean>(false);
  const [ isClearDisabled, setIsClearDisabled ] = useState<boolean>(false);
  const [ isClearOngoing, setIsClearOngoing ] = useState<boolean>(false);
  const [ isParsingData, setIsParsingData ] = useState<boolean>(false);
  const [ searchItems, setSearchItems ] = useState<apiSearchSingleEmailRequest>(DEFAULT_SEARCH_ITEMS);

  // Worksheet data load
  const { data,
    isLoading: isLoadingWorksheet,
    isValidating: isValidatingWorksheet,
    invalidateCache: invalidateCacheWorksheet,
  } = useLoadWorksheet(
    WorksheetStores.DistributionList,
    activeWorksheet,
    singleSearchWorksheetParsers
  );

  // Worksheet mutate loader
  const { worksheet, mutateWorksheet, mutateAdditionalProps, mutateTokens, isMutating } = useMutateWorksheet(
    WorksheetStores.DistributionList,
    activeWorksheet
  );

  const {
    searchResults,
    searchIsLoading,
    searchMutate,
    searchValidating
  } = useSearchSingleMessages((isWorksheetLoaded && !isValidatingWorksheet) ? searchItems : null);

  // Mutate worksheet with data
  useEffect(() => {
    if (!data || data.store !== WorksheetStores.DistributionList) {
      return;
    }
    mutateWorksheet({type: WorksheetMutationTypes.Full, payload: data});
    setIsWorksheetMutated(true);
    eventBus.dispatch(
      WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
      { worksheetId: data.worksheetId, name: data.name }
    );
  }, [data, mutateWorksheet, activeWorksheet]);

  const mutateSearchItems = useCallback((mutation: Partial<apiSearchSingleEmailRequest>): void => {
    setSearchItems(c => ({ ...c, ...mutation }));
  }, []);

  useEffect(() => {
    setIsSearchEmpty(isSearchRequestEmpty(searchItems));
  }, [searchItems, setIsSearchEmpty]);

  // Load stored searchItems from worksheet
  useEffect(() => {
    // If there is no worksheet or data has been already restored -> exit
    if (!worksheet || !worksheet.fields) {
      return;
    }

    // Directly after saving to worksheet value is JSON string so checking and parsing this
    const props: SearchField[] = singleSearchWorksheetParsers.fieldsParser(worksheet.fields);

    // parse additional properties
    const additionals: WorksheetMetaProps[] = singleSearchWorksheetParsers.propsParser(worksheet.additionalSearchProperties);
    const date = additionals.find(d => d.key === 'date') as { key: string; value: SingleEmailDate; } | undefined;

    // Mutate searchItems with loaded Tokens
    mutateSearchItems({
      searchRequestFields: props as SearchField[] ,
      date: date?.value,
    });
    setIsWorksheetLoaded(true);

  }, [isLoadingWorksheet, isWorksheetMutated, isMobile, worksheet, isValidatingWorksheet, mutateSearchItems]);

  useEffect(() => {
    setIsLoadingSingle(
      searchIsLoading ||
      isLoadingWorksheet ||
      searchValidating ||
      isValidatingWorksheet ||
      !isWorksheetLoaded ||
      isMutating ||
      isClearOngoing);
  }, [isClearOngoing, isLoadingWorksheet, isMutating, isValidatingWorksheet, isWorksheetLoaded, searchIsLoading, searchValidating, setIsLoadingSingle]);

  useEffect(() => {
    setSingleMessages(searchResults);
  }, [searchResults, setSingleMessages]);

  // Handle Tokens change
  const handleMutateTokens = useCallback((fields: SearchField[]): void => {
    mutateTokens(fields as SearchField[]);
  }, [mutateTokens]);

  const parseProps = useCallback((el: apiSingleMailEmailResponse): SingleMailEmailResponse =>
    parsePropsToDateTime({ ...el, recipients: undefined }, ['messageDate']), []);

  const handleUpdateSingle = useCallback((event: CustomEvent<apiSingleMailEmailResponse>): void => {
    if (!singleMessages) {
      return;
    }

    // When there are some search params, invalidate search
    if (!isSearchRequestEmpty(searchItems)) {
      searchMutate();
      return;
    }

    const foundSingleIndex = singleMessages.findIndex(list => list.id === event.detail.id);
    if (foundSingleIndex === -1) {
      setSingleMessages(
        insertItemAt(singleMessages, 0, parseProps(event.detail))
      );
    } else {
      setSingleMessages(
        replaceItemAt(singleMessages, parseProps(event.detail), foundSingleIndex)
      );
    }
  }, [parseProps, searchItems, searchMutate, setSingleMessages, singleMessages]);

  const onSearchRequestUpdated = async (
    updatedFields?: SearchField[]
  ): Promise<void> => {
    if (updatedFields) {
      mutateSearchItems({ searchRequestFields: updatedFields });
      handleMutateTokens(updatedFields);
      // Invalidate Worksheet Cache as when switching to another module and going back, cache would return previous worksheet data
      invalidateCacheWorksheet();
      // On search items change -> close side panel
      isRightColumnVisible && handleCloseSidePanel();
    }
  };

  const handleDateParsed = (date: ParssedDateTimeResult | undefined): void => {
    const value: SingleEmailDate | undefined = date ? {
      fromDate: date.fromString,
      original: laycanStyleRange(date.from, date.to),
      toDate: date.toString
    } : undefined;

    if (value?.original !== searchItems.date?.original) {
      mutateSearchItems({ date: value });
      mutateAdditionalProps(stringifyAdditionalProps([
        ...worksheet.additionalSearchProperties.filter(item => item.key !== 'date'),
        { key: 'date', value: value },
      ]));
      // Invalidate Worksheet Cache as when switching to another module and going back, cache would return previous worksheet data
      invalidateCacheWorksheet();
      // On search items change -> close side panel
      isRightColumnVisible && handleCloseSidePanel();
    }
    setIsParsingData(false);
  };

  const handleClear = useCallback(async (): Promise<void> => {
    setIsClearOngoing(true);
    await mutateTokens([]);
    await wait(300); // ensure that BE saved tokens in the WS already - when one is saved after another, additional props are not saved properly
    mutateAdditionalProps([]);
    setSearchItems(DEFAULT_SEARCH_ITEMS);
    setIsClearOngoing(false);
  }, [mutateAdditionalProps, mutateTokens]);

  const loadingOngoing: boolean = searchIsLoading || isParsingData || searchValidating || isLoadingWorksheet || isValidatingWorksheet || !isWorksheetLoaded || isMutating || isParsingData;

  useEffect(() => {
    eventBus.on(DistListSignalEventTypes.DIST_LIST_SINGLE_UPDATED, handleUpdateSingle);
    eventBus.on(DistListSignalEventTypes.DIST_LIST_SINGLE_CLEAR_SEARCH, handleClear);

    return ():void => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_SINGLE_UPDATED, handleUpdateSingle);
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_SINGLE_CLEAR_SEARCH, handleClear);
    };
  }, [handleClear, handleUpdateSingle]);

  const Elements: ReactElement = (
    <>
      <SingleSearchEntity
        onRequestChanged={onSearchRequestUpdated}
        searchItems={searchItems}
        searchContainerRef={searchContainerRef}
      />
      <DateTimeRange
        placeholder='Date, time'
        defaultValue={searchItems.date?.original ?? ''}
        onEmptyValue={() => handleDateParsed(undefined)}
        onDateParseError={():void => setIsParsingData(false)}
        onDateParsed={handleDateParsed}
        onParsingStart={():void => setIsParsingData(true)}
        onFocus={():void => setIsClearDisabled(true)} // disable clear on input focus as there is issue when loosing input focus and clicking "clear" - #1622 - other option would be to handleClear on mouseDown and prevent other inputs saving content
        onBlur={():void => setIsClearDisabled(false)}
        required={false}
      />
      <Button
        text
        size="small"
        disabled={isClearDisabled || loadingOngoing || isSearchEmpty || isClearOngoing}
        onClick={handleClear}
      >
        Clear
      </Button>
    </>
  );

  return (
    <>
      {isMobile ?
        (<Accordion className='single-search-mobile__accordion'>
          <AccordionTab className='single-search-mobile__accordion-tab'
            headerClassName='single-search-mobile__accordion-header'
            header='Search'>
            <div className='single-search-mobile__container'>
              {Elements}
            </div>
          </AccordionTab>
        </Accordion>) :
        <>
          {Elements}
          <NewSingleMessageButton
            handleCreateNewMessage={handleCreateNewMessage}
          />
        </>}
    </>);
};

export default SingleSearch;