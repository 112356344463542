import { WorksheetSignalMessageEventTypes } from 'components/Worksheets/Models/Enums';

import { getValueCollection } from 'helpers/Utils/enum';

import { SignalRApi } from 'server/signalR-api';

export class MarketAssistSocket {
  private static _instance: MarketAssistSocket;

  static get instance() {
    return this._instance || (this._instance = new this());
  }

  init = async (sr: SignalRApi): Promise<void> => {
    const events = [
      ...getValueCollection(WorksheetSignalMessageEventTypes, false),
    ];

    events.map(event => sr.dispatchEventOnMessage(event.key));
  };
}
