import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import DateTimePicker, {
  DateTimePickerRef,
} from 'components/DateTimePicker/DateTimePicker';

import {
  ConvertStringApi,
  ParssedDateTimeResult,
} from './Services/ConvertString';

interface DateTimeRangeProps {
  defaultValue: string | (() => string) | undefined;
  onDateParseError?: (value: string) => void;
  onEmptyValue?: (value: string) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  showErrorMessage?: boolean;
  label?: string | undefined;
  onParsingStart?: () => void;
  validateAfterTouch?: boolean;
  onDateParsed?: (arg: ParssedDateTimeResult) => void;
  placeholder?: string;
  retainOriginal?: boolean;
  showNowButton?: boolean;
  keepLocal?: boolean;
  limitFutureDate?: boolean;
  dateTimePicker?: boolean;
}

export type ExternalHandles = {
  reset: () => void;
};

const DateTimeRange = forwardRef<ExternalHandles, DateTimeRangeProps>(
  (props: DateTimeRangeProps, ref): ReactNode => {
    const {
      defaultValue,
      onDateParsed,
      onEmptyValue,
      onFocus,
      onBlur,
      className,
      required = true,
      disabled,
      showErrorMessage = true,
      label,
      onParsingStart,
      validateAfterTouch = false,
      onDateParseError = onEmptyValue,
      placeholder,
      retainOriginal = false,
      showNowButton = false,
      keepLocal = false,
      limitFutureDate,
      dateTimePicker = false,
    } = props;

    const [value, setValue] = useState(defaultValue);
    const [parsedDate, setParsedDate] = useState<ParssedDateTimeResult | null>(
      null
    );
    const [error, setError] = useState<string | null>(null);
    const [touched, setTouched] = useState<boolean>(false);
    const [isParsing, setIsParsing] = useState<boolean>(false);

    const dateTimePickerRef = useRef<DateTimePickerRef>(null);

    useImperativeHandle(ref, () => ({
      reset: (): void => {
        setValue(defaultValue);
        setError(null);
        setTouched(false);
      },
    }));

    const handleUpdate = useCallback(
      async (dateTime: string = '') => {
        if (dateTime && onDateParsed) {
          setIsParsing(true);
          onParsingStart && onParsingStart();
          ConvertStringApi.getParsedDate(dateTime, keepLocal, limitFutureDate)
            .then(data => {
              if (error) {
                setError(null);
              }
              setIsParsing(false);
              onDateParsed && onDateParsed(data?.results);
              setParsedDate(data?.results);

              if (retainOriginal) {
                setValue(data.results.original);
              }
            })
            .catch(error => {
              setIsParsing(false);
              setError(error.response?.data?.error?.errorObject ?? 'Error');
              onDateParseError && onDateParseError(dateTime);
            });
        } else if (`${ dateTime }`.trim() === '') {
          if (!required) {
            setError(null);
          }
          onEmptyValue && onEmptyValue('');
        }
      },
      [
        onDateParsed,
        onParsingStart,
        error,
        retainOriginal,
        onDateParseError,
        required,
        onEmptyValue,
        keepLocal,
        limitFutureDate,
      ]
    );

    useEffect(() => {
      if (!defaultValue) {
        setParsedDate(null);
      }
    }, [defaultValue]);

    useEffect(() => {
      // Do not update input with default value when there is an error or it's parsing
      if (error === null && !isParsing && !retainOriginal) {
        setValue(defaultValue ?? '');
      }

      if (`${ defaultValue }`.trim() === '' && required) {
        (validateAfterTouch && touched) ||
        (!validateAfterTouch && showErrorMessage)
          ? setError('Required field')
          : setError(null);
      }
    }, [
      defaultValue,
      error,
      isParsing,
      required,
      retainOriginal,
      showErrorMessage,
      touched,
      validateAfterTouch,
    ]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
      setValue(e.target.value);
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
      e.target.select();
      onFocus && onFocus(e);
    };

    const handleNowButtonClick = (): void => {
      setError(null);

      if (typeof onDateParsed === 'function') {
        const now = DateTime.utc().toString();
        onDateParsed(
          ConvertStringApi.parseDateTime({ from: now, original: now, to: now })
        );
      }
    };

    const onCalendarShow = useCallback(
      (e: SyntheticEvent<HTMLButtonElement>) => {
        if (value) {
          ConvertStringApi.getParsedDate(value, keepLocal, limitFutureDate)
            .then(data => setParsedDate(data?.results))
            .catch(() => {});
        }

        dateTimePickerRef.current?.show && dateTimePickerRef.current.show(e);
      },
      [value, keepLocal, limitFutureDate]
    );
    const onCalendarValueChanged = (
      value:
        | Partial<Pick<ParssedDateTimeResult, 'from' | 'to'>>
        | null
        | undefined
    ): void => {
      if (value) {
        const fromDate = value.from?.toFormat('dd LLL yyyy HH:mm');
        const toDate = value.to?.toFormat('dd LLL yyyy HH:mm');
        handleUpdate(`${ fromDate || '' }${ toDate ? ` - ${ toDate }` : '' }`);
      }
    };

    return (
      <div className='form-input__container'>
        {label && <label htmlFor='datetimerange-input'>{label}</label>}
        <span className='p-inputgroup direction--column'>
          <div className='grow-to-fill'>
            <div className='p-inputgroup'>
              <InputText
                id='datetimerange-input'
                name='datetimerange-input'
                pt={{
                  root: {
                    autoCapitalize: 'off',
                    // @ts-ignore
                    autoComplete: 'off',
                  },
                }}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleChange}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                  handleUpdate(value);
                  setTouched(true);
                  onBlur && onBlur(e);
                }}
                onFocus={handleFocus}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    (e.target as HTMLInputElement).blur();
                  }
                }}
                className={clsx(className, {
                  'p-invalid':
                    (required &&
                      error !== null &&
                      (showErrorMessage || touched)) ||
                    (!required && error !== null),
                })}
              />
              {dateTimePicker && (
                <Button
                  icon='iconoir-calendar icon--tiny'
                  size='small'
                  text
                  onClick={onCalendarShow}
                />
              )}
            </div>
            {showNowButton && (
              <Button
                type='button'
                size='small'
                text
                className='p-inputgroup-addon addon-color--full-black'
                onClick={handleNowButtonClick}
              >
                Now
              </Button>
            )}
          </div>
          {showErrorMessage && error && (
            <small className='message-invalid'>
              {error || 'Invalid input'}
            </small>
          )}
        </span>
        <DateTimePicker
          ref={dateTimePickerRef}
          value={parsedDate}
          onValueChange={onCalendarValueChanged}
        />
      </div>
    );
  }
);

DateTimeRange.displayName = 'DateTimeRange';

export default DateTimeRange;
