import axios from "axios";
import { DateTime } from "luxon";

import { getByString } from "helpers/Utils/enum";
import { asEncoded } from "helpers/Utils/string";

import { AvailableRateGridsResponse } from "modules/Rates/Grids/Models/RateGridResponse";
import {
  AvailableGridsResponse,
  ApiModsDataResponse,
  IEAModSourceData,
  IEAModSources,
  AvailableModGridResponse,
  JodiSources,
} from "modules/Rates/Commodities/Models/IEAMods";
import {
  HistoricalSearchRequest,
  HistoricalChartingValues,
} from "modules/Rates/Commodities/Models/Historical";

import ErrorToastService from "components/Errors/ErrorToast/Services";

export class CommoditiesDataAPI {
  static getHistoricalData = (
    feed: "iea" | "jodi",
    source: IEAModSources | JodiSources,
    request: HistoricalSearchRequest
  ) =>
    axios
      .request<HistoricalChartingValues, { data: HistoricalChartingValues[] }>({
        url: `rates/${feed}/${source}`,
        method: "POST",
        data: request,
      })
      .then((result) => result.data);

  /**
   * retrieves a list of the avilable IEA MOd data sources a user is able to view
   *
   */
  static getAvailableFeeds = (feed: "iea" | "jodi") =>
    axios
      .request<null, AvailableGridsResponse>({
        url: `/rates/datasets/${feed}`,
        method: "GET",
      })
      .then(async (r) => {
        const { data } = r;

        const sorted =
          (data ?? []).sort((a, b) => {
            if (!a.sortOrder || !b.sortOrder) return 0;

            return a.sortOrder > b.sortOrder ? 1 : -1;
          }) ?? [];

        return sorted.map((inst: AvailableRateGridsResponse) => {
          const { dimensions, name, ...rest } = inst;
          //	return the object with the dimensions converted to an array of strings
          //	respresenting the available search dimensions for this data source
          return {
            ...rest,
            name,
            source: getByString<typeof IEAModSources>(IEAModSources, name),
            dimensions: Object.entries(dimensions ?? {})
              .filter(([, val]) => val)
              .map(([key, val]) => key),
          };
        }) as AvailableModGridResponse[];
      })
      .catch((e) => {
        ErrorToastService.handleError(e, [500, 503]);

        throw e;
      });

  static getLatestModValues = async (
    feed: "iea" | "jodi",
    source: IEAModSources | JodiSources,
    query?: string
  ) => {
    let url: string = `rates/${feed}/${source}${query ? `/${query}` : ""}`;

    return axios
      .request<null, ApiModsDataResponse, IEAModSourceData>({
        url,
        method: "GET",
      })
      .then((r) => {
        const { items, columns, source, frequency } = r.data ?? {};

        return {
          columns,
          source,
          frequency,
          rows: items?.map((m) => {
            const { columns, lastUpdated, ...rest } = m;
            //	We need to turn the column key/value instances into properties
            //	on the parent row.
            const props = columns.reduce((acc, cur) => {
              return { ...acc, [cur.key]: cur.value };
            }, {});

            //	return everything remembering to convert the ISO formatted lastUpdated
            //	property into a luxon DateTime instance
            return {
              ...rest,
              ...props,
              lastUpdated: DateTime.fromISO(lastUpdated),
              hash: asEncoded(`${m.location}-${m.shortName}`),
            };
          }),
        } as IEAModSourceData;
      })
      .catch((e) => {
        console.log("we got an error", e);
        ErrorToastService.handleError(
          e,
          [404, 500, 503],
          `There seems to be an error retrieving this data. Please try another product or source.\n\n\nSupport have been notified of this issue.`
        );

        /**
         * Whilst the back-end works to better handle instances of missing
         * data that cause 500 errors, we can't just throw the error here
         * as it will buble up the application meaning the error screen is
         * displayed to the user.
         **/
        throw false;
        // throw e;
      });
  };

  static exportHistoricalData = async (
    feed: "iea" | "jodi",
    source: IEAModSources | JodiSources,
    data: HistoricalSearchRequest
  ) => {
    return axios({
      url: `/rates/${feed}/${source}/export`,
      method: "POST",
      responseType: "blob",
      data,
    }).then(({ data }) => data);
  };
}
