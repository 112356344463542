import React, { Context, createContext } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import axios from 'axios';

import ErrorPage from './components/Errors/ErrorPage';
import { SecurityRights } from './components/OBXUser/Model/SecurityRightsEnum';
import { RibbonTypes } from './components/Ribbons/Models/RibbonTypes';
import { ConfigParams, LoaderConfig } from './types/routes-type';
import App from './App';
import Auth0Wrapper from './auth0-wrapper';
import reportWebVitals from './reportWebVitals';
import { ROUTES_CONFIG } from './routes';

import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const configPromise = fetch('/config.json');

export const ConfigContext: Context<ConfigParams> = createContext({});

configPromise
  .then(res => res.json())
  .then((config: ConfigParams) => {

    // eslint-disable-next-line
    const win: any = window;
    win.signalREndpoint = config.signalREndpoint;

    document.documentElement.setAttribute('data-user-agent', navigator.userAgent);

    axios.interceptors.request.use(request => {
      request.baseURL = config.apiBase;
      return request;
    });

    config = {
      ...config,
      lowerLevelEnvironment: config?.lowerLevelEnvironment === 'true' || config.lowerLevelEnvironment === true
    };

    const appProps = { config };

    const routes = createBrowserRouter([
      {
        path: '/',
        loader: (): LoaderConfig => ({
          ribbons: [RibbonTypes.NoInternetConnection],
          accessCode: SecurityRights._Unprotected
        }),
        element: <Auth0Wrapper config={config}><App {...appProps} /></Auth0Wrapper>,
        errorElement: <ErrorPage/>,
        children: ROUTES_CONFIG // So define now routes in routes.tsx as well as top menu items
      }
    ]);

    root.render(
      <React.StrictMode>
        <ConfigContext.Provider value={config}>
          <RouterProvider router={routes}/>
        </ConfigContext.Provider>
      </React.StrictMode>
    );


  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

