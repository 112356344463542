import { type ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';

import EditableField from './Components/EditableField';

import EditableContent from 'modules/Workflows/Components/EditableContent';
import type { apiWorkflowTemplateProperty } from 'modules/Workflows/Models';
import {
  COMMISSION_RATE_FIELD,
  COMMISSION_RATE_VALUE_FIELD,
} from 'modules/Workflows/Models/CommissionRate';
import { TemplateControlTypes } from 'modules/Workflows/Models/Enums';
import { useUpdateProperties } from 'modules/Workflows/Services/hooks';

import styles from './EditableInfoField.module.scss';

interface IEditableInfoFieldProps {
  id: string;
  property: apiWorkflowTemplateProperty;
  onUpdated?: (value?: string) => void;
}

const EditableInfoField = ({
  id,
  property,
  onUpdated,
}: IEditableInfoFieldProps): ReactNode => {
  const isNumberMandatory =
    property.control?.type === TemplateControlTypes.NumberInput &&
    property.control?.mandatory;

  const [editedValue, setEditedValue] = useState<string | undefined>(
    isNumberMandatory && !property.value ? '0' : property.value
  );
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );

  const { updateProperties } = useUpdateProperties();

  useEffect(() => {
    setEditedValue(isNumberMandatory && !property.value ? '0' : property.value);
  }, [isNumberMandatory, property]);

  if (!property.control) {
    return <div>{property.value?.toString() || ''}</div>;
  }

  const validate = (value: string = ''): boolean => {
    if (property.validation) {
      const validation = new RegExp(property.validation.rule);

      if (!validation.test(value)) {
        setValidationMessage(property.validation.message);
        return false;
      }
    }

    if (property.control?.mandatory && !value) {
      setValidationMessage('Field is mandatory');
      return false;
    }

    setValidationMessage(null);
    return true;
  };

  const onEditModeChange = (editMode: boolean): void => {
    const newValue = editedValue;
    const isValid = validate(newValue);

    if (
      !editMode &&
      isValid &&
      property.value !== newValue &&
      (newValue?.trim() !== '' || newValue === '')
    ) {
      const rules = property.control?.rules
        ? Array.isArray(property.control?.rules)
          ? property.control?.rules
          : [property.control?.rules]
        : [];
      const dependentPropertyRef =
        newValue &&
        rules.find(
          r =>
            !r.values.includes(newValue) || !r.values.includes(property.value)
        )?.ref;

      const dependentProperty = [];
      if (dependentPropertyRef) {
        dependentProperty.push({
          key: dependentPropertyRef,
          value:
            dependentPropertyRef === COMMISSION_RATE_VALUE_FIELD ? '0' : '',
          control: { type: TemplateControlTypes.TextInput },
        });
      } else if (property.key === COMMISSION_RATE_VALUE_FIELD) {
        dependentProperty.push({
          key: COMMISSION_RATE_FIELD,
          value: 'Custom',
          control: { type: TemplateControlTypes.Dropdown },
        });
      }

      updateProperties({
        id,
        properties: [
          { ...property, value: newValue || '' },
          ...dependentProperty,
        ],
      });
    } else {
      setEditedValue(
        isNumberMandatory && !property.value ? '0' : property.value
      );
    }
  };

  const onChange = (value: string): void => {
    setEditedValue(
      property.control?.type === TemplateControlTypes.NumberInput
        ? value.trim()
        : value
    );
    validate(value);
  };

  return (
    <EditableContent
      className={styles.editableFieldContainer}
      value={editedValue || ''}
      save={() =>
        Promise.resolve(Boolean(onUpdated && onUpdated(editedValue)) || true)
      }
      onModeChange={onEditModeChange}
      onBlur={
        property.control.type === TemplateControlTypes.DateInput
          ? (e): void => e.preventDefault()
          : undefined
      }
    >
      <EditableField
        key={`editable-field-${ property.key }`}
        className={clsx({
          'p-invalid': validationMessage,
        })}
        value={editedValue}
        control={property.control}
        onChange={onChange}
      />
      {validationMessage?.length && (
        <small className='p-invalid'>{validationMessage}</small>
      )}
    </EditableContent>
  );
};

export default EditableInfoField;
export { EditableInfoField };
