import { ReactElement } from 'react';
import { ColumnBodyOptions } from 'primereact/column';

import NotAvailable from 'components/NotAvailable';

import { notNil } from 'helpers/Utils/misc';

import type F from 'types/generic-type';

export const SingleLineArrayMore = (data: F<string | number>, config: ColumnBodyOptions, amount: number): ReactElement => {

  const [ field, count ] = config.field.split(',');

  if (!(notNil(data[field]) && Array.isArray(data[field]))) {
    return <NotAvailable label='Empty' />;
  }

  return <span>
    {(data[count] as number) <= amount
      ? data[field].join(', ')
      : `${ (data[field].slice(0, amount) as []).join(', ') } (+${ (data[count] as number) - amount } more)`
    }
  </span>;
};