import { ChangeEvent, type ReactNode } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import DateTimeRange from 'components/DateTimeRange';

import type { WorkflowTemplateControlProperty } from 'modules/Workflows/Models';
import { TemplateControlTypes } from 'modules/Workflows/Models/Enums';

type EditableFieldProp = {
  control: WorkflowTemplateControlProperty;
  value?: string;
  className?: string;
  onBlur?: (control: WorkflowTemplateControlProperty, value: string) => void;
  onChange?: (value: string) => void;
};

const EditableField = ({
  control,
  value,
  className,
  onBlur = (): void => {},
  onChange = (): void => {},
}: EditableFieldProp): ReactNode => {
  if (!control) {
    return;
  }

  switch (control.type) {
  case TemplateControlTypes.DateInput:
    return (
      <DateTimeRange
        onDateParsed={result => {
          const val = result.from.toFormat(
            control.formatter ?? 'dd MMM yyyy'
          );
          onChange(val);
        }}
        showErrorMessage={false}
        defaultValue={value ?? ''}
      />
    );

  case TemplateControlTypes.Dropdown: {
    const { options } = control;

    return (
      <Dropdown
        className='grow-to-fill direction--row'
        autoFocus
        options={options}
        optionLabel='label'
        placeholder='Select…'
        value={value}
        onChange={e => {
          const val = e.value;

          onChange(val);
        }}
      />
    );
  }

  case TemplateControlTypes.NumberInput:
    return (
      <div className='p-inputgroup grow-to-fill direction--row'>
        <InputText
          className='align--right'
          autoFocus
          keyfilter='pnum'
          type='number'
          value={value}
          placeholder='Enter Value'
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            const val: string = `${ e.target.value ?? 0 }`;

            onChange(val);
          }}
        />
        {control.suffix && (
          <span className='p-inputgroup-addon'>{control.suffix}</span>
        )}
      </div>
    );

  case TemplateControlTypes.TextField:
    return (
      <InputTextarea
        className={className}
        autoFocus
        rows={3}
        onChange={e => {
          const val: string = e.currentTarget.value;

          onChange(val);
        }}
        onBlur={e => {
          const val = e.currentTarget.value;

          onBlur(control, val);
        }}
        value={value ?? ''}
      />
    );

  case TemplateControlTypes.TextInput:
  default:
    return (
      <InputText
        className={className}
        autoFocus
        onChange={e => {
          const val: string = e.currentTarget.value;

          onChange(val);
        }}
        onBlur={e => {
          const val: string = e.currentTarget.value;

          onBlur(control, val);
        }}
        value={value ?? ''}
      />
    );
  }
};

export default EditableField;
export { EditableField };
