import type { ColumnSortEvent } from 'primereact/column';
import type { FF } from 'types/generic-type';

export const sortByMultipleItemsInArray = <T,>(event: ColumnSortEvent, sortBy: string, iterations?: number): T => {
  const { data, order } = event;
  const reps = iterations ?? data.length;

  return data.sort((a: FF<string | number>, b: FF<string | number>) => {
    let result = 0;

    for (let i = 0; i < reps; i++) {
      if (typeof a[sortBy][i] === 'number' && typeof b[sortBy][i] === 'number') {
        result = ((a[sortBy][i] as number) - (b[sortBy][i] as number)) * (order as number);
      } else {
        result = `${a[sortBy][i]}`.localeCompare(`${b[sortBy][i]}`) * (order as number);
      }

      if (result !== 0) {
        break;
      }
    }

    return result;
  });
};