import axios, { AxiosResponse, AxiosError } from 'axios';
import { ColumnSortMetaData } from 'primereact/column';
import { useSWRConfig, State, Key } from 'swr';
import useSWRMutation from 'swr/mutation';
import { DateTime } from 'luxon';

import ErrorToastService from 'components/Errors/ErrorToast/Services/ErrorToastService';
import { wait } from 'helpers/Utils/misc';
import { WORKSHEET_MULTISORT_META } from '../AllWorksheets';

import { SortDirection, type WorksheetStores } from '../Models/Enums';
import type {
  AccessGrantedParams,
  AccessGrantedResponse,
  AccessRequestParams,
  AccessRequestResponse,
  WorksheetAccessPayload,
} from '../Models/ShareRequest';
import type {
  OpenWorksheet,
  WorksheetMetaProps,
  WorksheetResponse,
} from '../Models/WorksheetResponse';
import type { SearchField } from 'components/EntitySearch/Models/SearchEntities';

export interface IParserFunctions {
	propsParser: (props: WorksheetMetaProps[]) => WorksheetMetaProps[],
  fieldsParser: (tokens: SearchField[]) => SearchField[],
	hydrator: () => WorksheetMetaProps[]
}

interface ICompressEntitiesParams {
	worksheetId: string,
	store: WorksheetStores,
	ids: string[],
	compress: boolean
}

type WorksheetPayload = {
	store: WorksheetStores;
	sheet: string | undefined;
	data: WorksheetMutationPayload;
}

export type RenameWorksheetPayload = {
	worksheetId: string;
	store: WorksheetStores;
	name: string;
};

export type DeleteWorksheetPayload = {
	store: WorksheetStores;
	worksheetId: string;
}

export type CreateWorksheetPayload = {
	store: WorksheetStores;
	name?: string;
	hydrator: () => WorksheetMetaProps[];
}

export type WorksheetMutationPayload = WorksheetResponse | SearchField[] | WorksheetMetaProps[] | string;

export const useSaveTokens = (sheet: string) => {
// .split("-")[0]
	const { cache } = useSWRConfig();
	const { trigger, isMutating, data } = useSWRMutation<WorksheetResponse | undefined, AxiosError, Key, WorksheetPayload>(
		`worksheet-${sheet}`,
		WorksheetsAPI.saveTokens,
		{
			onSuccess: (d) => {
				// console.log("saved tokens are", d)
				cache.set(`worksheet-${sheet}`, d as State)
			},
			revalidate: false,
		}
	)

	return { saveTokens: trigger, isMutating, data }

}

export const useSaveProps = (sheet: string) => {
// .split("-")[0]
	const { cache } = useSWRConfig();
	const { trigger, isMutating } = useSWRMutation<WorksheetResponse, AxiosError, Key, WorksheetPayload>(
		`worksheet-${sheet}`,
		WorksheetsAPI.saveAdditionalProps,
		{
			onSuccess: (d) => {
				console.log("saved props is", d)
				console.log('local cache is', cache.get(`worksheet-${sheet}`))
				// mutate(`worksheet-${sheet}`, )
				cache.set(`worksheet-${sheet}`, d as State)
			},
			revalidate: false,
		}
	)

	return { saveProps: trigger, isMutating }

}

export class WorksheetsAPI {

  static DEFAULT_SPEED: number = 12.5;

  static getAllWorksheets = (
    store: WorksheetStores,
    currentlyOpen: OpenWorksheet[] | undefined,
		resultsFilter: (
			item: WorksheetResponse,
			index?: number,
			array?: WorksheetResponse[]
		) => boolean = () => true,
		sort?: SortDirection,
		resultsMap?: (item: WorksheetResponse) => WorksheetResponse,
  ) => {
  return axios
    .request<WorksheetResponse[]>({
			url: `Worksheets/Worksheets/${ store }`,
			method: 'GET',
			params: sort ? { creationDateSortDirection: SortDirection.Descending } : {},
		})
    .then(result => {
      const { data: sheets } = result;

      const ids: string[] = ((Array.isArray(currentlyOpen) && currentlyOpen) || []).map(m => m.id);

			//  return items with additional mutation if the the sheet is currently open
			let sheetsParsed: WorksheetResponse[] = sheets
				.filter(resultsFilter)
				.map((s: WorksheetResponse) =>
					({...s, currentlyOpen: ids.includes(s.worksheetId ?? '')}));

			if (resultsMap) {
				sheetsParsed = sheetsParsed.map(resultsMap);
			}

			if (!sort) {
				sheetsParsed = sheetsParsed.sort((a: WorksheetResponse,b: WorksheetResponse) =>
					this.groupCompare(a, b, WORKSHEET_MULTISORT_META)
				);
			}

      return sheetsParsed;
    })
  }

	/**
	* @deprecated Do not use. Not maintained on the BE side, because "it brings more bugs than advantages".
	*/
  static getLast = async (store: WorksheetStores, parsers: IParserFunctions) => {
    console.log("calling GET LAST")

		const { propsParser, fieldsParser } = parsers;
		const lastOpened: AxiosResponse<WorksheetResponse> = await axios.request({url: `Worksheets/${store}`, method: 'GET'});

		const { fields, additionalSearchProperties } = lastOpened.data;

		return {
			...lastOpened.data,
			additionalSearchProperties: propsParser(additionalSearchProperties),
			fields: fieldsParser(fields),
			store
		}
  }

	static getWorksheetById = (store: WorksheetStores, sheet: string, parsers?: IParserFunctions | null, touch: boolean = false) => axios.request({
		url: `Worksheets/${store}/${sheet}/${touch.toString()}`,
		method: 'GET' })
	.then(result => {

		const { additionalSearchProperties, fields } = result.data;

    if (parsers) {
      const { propsParser, fieldsParser } = parsers;
      return {
        ...result.data,
        additionalSearchProperties: propsParser(additionalSearchProperties),
        fields: fieldsParser(fields),
				store,
				lastModifiedParsed: DateTime.fromISO(`${ result.data.lastModified }`).toUTC(),
      }
    } else {
      return {
				...result.data,
				store,
				lastModifiedParsed: DateTime.fromISO(`${ result.data.lastModified }`).toUTC(),
			}
    }
	})

	static saveTokens = (url: string, params: { arg: WorksheetPayload }): Promise<WorksheetResponse | undefined> => {

		const { store, sheet, data } = params.arg;

		return axios.patch(
			`Worksheets/${store}/${sheet}/tokens`,
			Array.isArray(data) ? data.map((i: any) => ({...i, metaData: WorksheetsAPI.hydrateMetaDataSets(i)})) : data)
		.then( result => result.data )
	}

	static saveAdditionalProps = (url: string, params: any) => {
		let { store, sheet, data } = params.arg;

		data = data
			// sanitise the data in case eta are still DateTime instances
			.map((d: any) => {
				if (
					['etaFrom', 'etaTo'].includes(d.key) &&
					DateTime.isDateTime(d.value)
				) {
					return { ...d, value: d.value.toISODate() };
				} else {
					return { ...d };
				}
			});


		return axios
			.patch(
				`Worksheets/${ store }/${ sheet }/ReplaceAllAdditionalSearchProperties`,
				data
			)
			.then(result => result.data)
			.catch(e => {
				throw e;
				//	TODO - HANDLE this being called if there is no sheet name
			});
	};

	static createNewWorksheet = async (url: string, params: { arg: CreateWorksheetPayload }): Promise<WorksheetResponse | undefined> => {
		const { store, name = "Untitled Worksheet", hydrator } = params.arg;

		const newSheet: AxiosResponse<WorksheetResponse> = await axios.put(`Worksheets/${store}/${name}`)

		const { worksheetId: sheet } = newSheet.data;
		const data = hydrator();

		//	pause a moment because trying to save instanly triggers 500 errors
		//	as the additional props cant immediately be saved to the worksheet
		await wait(500);

		try {
			const hydratedSheet = await WorksheetsAPI.saveAdditionalProps("", {
				arg: {
					store, sheet, data
				}
			})

			return hydratedSheet;
		} catch (e) {
			throw e;
		}
	}

	static deleteWorksheet = (url: string, params: {arg: DeleteWorksheetPayload}) => {

		const { store, worksheetId } = params.arg;

		return axios.delete(`Worksheets/${store}/${worksheetId}`)
		.then( result => result.data );
	}

  static shareWorksheet = async function ShareWorksheet<T>(
    url: string,
    params: {
      arg: WorksheetAccessPayload<T>;
    }
  ) {
    const { store, id, requestBody } = params.arg;

    // return true;
    return axios
      .patch<WorksheetResponse>(
        `Worksheets/${ store }/${ id }/MarkAsShared`,
        requestBody && { ...requestBody }
      )
      .then(async result => result.data)
      .catch(error => {
        ErrorToastService.handleError(error, [500, 503]);
        return null;
      });
  };

  static sendAccessRequest = async <T = AccessRequestParams>(params: {
    arg: WorksheetAccessPayload<T>;
  }): Promise<AccessRequestResponse> =>
    axios
      .request({
        url: `Worksheets/${ params.arg.store }/${ params.arg.id }/RequestAccess`,
        method: 'POST',
        data: params.arg.requestBody,
      })
      .then(response => response.data);

  static sendAccessGranted = async <T = AccessGrantedParams>(params: {
    arg: WorksheetAccessPayload<T>;
  }): Promise<AccessGrantedResponse> =>
    axios
      .request({
        url: `Worksheets/${ params.arg.store }/${ params.arg.id }/GrantAccess`,
        method: 'POST',
        data: params.arg.requestBody,
      })
      .then(response => response.data);

  static compressEntities = (url: string, params: {arg: ICompressEntitiesParams}) => {
    const { worksheetId, store, ids, compress } = params.arg;

    console.log("args", params.arg);

    return axios({
      url: `Worksheets/${store}/${worksheetId}/compress`,
      method: compress ? 'PUT' : 'DELETE',
      data: ids
    })
    .then(r => r.data)
  }

	static renameWorksheet = (url: string, params: { arg: RenameWorksheetPayload }) => {
		const { worksheetId, name, store } = params.arg;

		return axios.patch(`Worksheets/${store}/${worksheetId}/name/${name}`)
		.then(r => r.data);
	}

  private static hydrateMetaDataSets = (tokens: SearchField) => Object.entries(tokens).filter(m => !m.includes("metaData")).map(m => {
			const [key, value] = m
			return {key, value}
	});


	// Sort worksheet response by defined multiSortMeta fields
	private static groupCompare = (a: WorksheetResponse, b: WorksheetResponse, multiSortMeta?: ColumnSortMetaData[] | null): number => {
		if (!multiSortMeta) {
			return 0;
		}

		const fieldA = multiSortMeta[0].field as keyof WorksheetResponse;
		const fieldB = multiSortMeta[1].field as keyof WorksheetResponse;
		const orderA = multiSortMeta[0].order as number;
		const orderB = multiSortMeta[1].order as number;

		return  `${ a[fieldA] }`.localeCompare(`${ b[fieldA] }`) * orderA ||
			`${ a[fieldB] }`.localeCompare(`${ b[fieldB] }`) * orderB;
	};

}