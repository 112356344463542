import { useState, useEffect } from 'react';
import { useNavigation, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';

import { clsx } from 'clsx';

import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';

import { SecurityRights } from 'components/OBXUser/Model/SecurityRightsEnum';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';

import { ActiveDetailsTab, ResultsModeEnum } from './Models/Enums';

import SanctionsSearch from './Components/SanctionsSearch';
import SanctionedVesselDataTable from './Components/SanctionedVesselDataTable';
import SanctionedEntityDataTable from './Components/SanctionedEntityDataTable';
import SanctionedIndividualDataTable from './Components/SanctionedIndividualDataTable';
import SanctionDetails from './Components/SanctionDetails';
import SanctionedVesselCharting from './Components/SanctionedVesselCharting';
import SanctionedEntityCharting from './Components/SanctionedEntityCharting';
import SanctionedIndividualOtherInfo from './Components/SanctionedIndividualOtherInfo';
import SanctionedIndividualDetails from './Components/SanctionedIndividualDetails';
import SecondaryNavigation from 'components/SecondaryNavigation';
import SanctionedItemSearchHistory from './Components/SanctionedItemSearchHistory';
import ButtonDownloads from './Components/ButtonDownloads';

import MarkAsCheckedButton from './Templates/MarkAsChecked';

import { SanctionsSocket } from './Services/SignalRSocket';
import { useSignalR } from 'App';

import type { SearchParams } from './Models';
import type { SanctionsConfig } from 'types/routes-type';
import type {
	IndividualSanctionResponse,
	LegalEntitySanctionResponse,
	VesselSanctionResponse
} from './Models/SanctionedItems';

import './SanctionsPage.scss';

interface sanctionsPageProps {
    items: SanctionsConfig[]
	resultsMode: ResultsModeEnum;
}

function SanctionsPage(props: sanctionsPageProps) {

	const { items, resultsMode } = props;

	const { search } = useParams();
	const navigation = useNavigation();
	const { signal } = useSignalR();
	const [searchParams, setSearchParams] = useState<SearchParams>({ searchTerm: search ?? "*", dateRange: null });
	const [activInfoTab, setActiveInfoTab] = useState<ActiveDetailsTab>(ActiveDetailsTab.Visualisation);
	const [selectedItem, setSelectedItem] = useState<VesselSanctionResponse | LegalEntitySanctionResponse | IndividualSanctionResponse | null>(null);
	const [displayLoader, setDisplayLoader] = useState<boolean>(false);

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });
	const { obxuser } = useLoggedInUser();

	const isVesselSanctions = resultsMode === ResultsModeEnum.VesselSanctions;
	const isEntitySanctions = resultsMode === ResultsModeEnum.LegalEntitySanctions;
	const isIndividualSanctions = resultsMode === ResultsModeEnum.IndividualSanctions;
	const detailsPaneVisible = !!(selectedItem || isTabletOrMobile || !isIndividualSanctions);

	const mutateSearchParams = (mutation: Partial<SearchParams>): void => {
		setSearchParams(curr => ({ ...curr, ...mutation }));
	}

	useEffect(() => {
		const socket: SanctionsSocket = SanctionsSocket.instance;
		socket.init(signal);
	}, [signal]);

    useEffect(() => {
        // don't switch tabs if there is no item selected,
        // that causes viewport to be scrolled to 'higlighted' tab button
        // and makes side panel partially visible.
        // https://dev.azure.com/dingrogu/OBXchange/_workitems/edit/3796
        if (!selectedItem) {
            setActiveInfoTab(ActiveDetailsTab.Visualisation);
            return;
        };

        // Individuals have no 'visualisation' tab item
        if (isIndividualSanctions && activInfoTab === ActiveDetailsTab.Visualisation) {
            setActiveInfoTab(ActiveDetailsTab.Details);
        } else if (!isIndividualSanctions && activInfoTab === ActiveDetailsTab.Other) {
            setActiveInfoTab(ActiveDetailsTab.Visualisation);
        }
        // eslint-disable-next-line
    }, [isIndividualSanctions, selectedItem]);

    useEffect(() => {
        //  Handle an item being selected but the details tab NOT currently being active
		if (selectedItem && activInfoTab !== ActiveDetailsTab.Details) {
            setActiveInfoTab(ActiveDetailsTab.Details);
        }
        // eslint-disable-next-line
    }, [selectedItem]);

    const gestures = useSwipeable({
		onSwipedRight: () => {
            setSelectedItem(null);
        },
	});

	// reset selectedItem on route change immediately to avoid using
	// the wrong selectedItem on the browser's Back button click
	// https://dev.azure.com/oilbrokerage/OBXchange/_workitems/edit/3739
	if (navigation.state === 'loading') {
		selectedItem && setSelectedItem(null);
	}

    return <>
		<header className='sanctions__header'>
			<SanctionsSearch
				currentSearchMode={resultsMode}
				currentSearchParams={searchParams}
				displayLoader={displayLoader}
				mutateSearchParams={mutateSearchParams}
			/>
		</header>
		<nav className="tabbed-navigation__container">
			<SecondaryNavigation
				items={items}
				// selectedItem stays set when tab is changed. Because of that, improper item is passed (and displayed) in Details section.
				onBeforeNavigation={() => {
					setSelectedItem(null)
					return Promise.resolve();
				}}
			/>
		</nav>
		<main
			className={clsx(
				'sanctions__page grow-to-fill',
				{ 'drawer--active': isTabletOrMobile && selectedItem }
			)}
			{...(detailsPaneVisible && {
				"data-cols": "9,3",
				"data-drawer-style": "slide",
				"data-drawer-position": "alongside-right",
			})}
		>
			<section className="overflow--hidden">
				<div className="grow-to-fill">
					{ isVesselSanctions &&
						<SanctionedVesselDataTable
							key={resultsMode}
							setSelectedItem={setSelectedItem}
							searchParams={searchParams}
							selectedItem={selectedItem as VesselSanctionResponse}
							setDisplayLoader={setDisplayLoader}
						/>
					}
					{ isEntitySanctions &&
						<SanctionedEntityDataTable
							key={resultsMode}
							setSelectedItem={setSelectedItem}
							searchParams={searchParams}
							selectedItem={selectedItem as LegalEntitySanctionResponse}
							setDisplayLoader={setDisplayLoader}
							isMobile={isTabletOrMobile}
						/>
					}
					{ isIndividualSanctions &&
						<SanctionedIndividualDataTable
							key={resultsMode}
							setSelectedItem={setSelectedItem}
							searchParams={searchParams}
							selectedItem={selectedItem as IndividualSanctionResponse}
							setDisplayLoader={setDisplayLoader}
						/>
					}
				</div>
			</section>
			<aside
				{...gestures}
				className={clsx('sanction-details__details-pane', { 'hidden': !detailsPaneVisible })}
			>
                <Button
                    className={clsx({ 'hidden': !isTabletOrMobile })}
                    icon='iconoir-nav-arrow-left icon--small'
					text
                    size='small'
					onClick={() => setSelectedItem(null)}
				>
					Return to Search Results
				</Button>

                <TabView
                    activeIndex={activInfoTab}
                    onTabChange={(e) => setActiveInfoTab(e.index)}
                    renderActiveOnly={true}
                >
                    {!isIndividualSanctions &&
                        <TabPanel disabled={!selectedItem} header='Visualisation'>
                            {isVesselSanctions ?
                                <SanctionedVesselCharting
                                    searchParams={searchParams}
                                />
                                :
                                <SanctionedEntityCharting
                                    searchParams={searchParams}
                                />
                            }
                        </TabPanel>
                    }
                    <TabPanel disabled={!selectedItem} header='Details'>
                        {isIndividualSanctions ?
                            <SanctionedIndividualDetails
                                selectedItem={selectedItem as IndividualSanctionResponse}
                            />
                            :
                            <SanctionDetails
                                selectedItem={selectedItem as VesselSanctionResponse | LegalEntitySanctionResponse}
								resultsMode={resultsMode}
                            />
                        }
                    </TabPanel>
                    {isIndividualSanctions &&
                        <TabPanel disabled={!selectedItem} header='Other Information'>
                            <SanctionedIndividualOtherInfo
                                selectedItem={selectedItem as IndividualSanctionResponse}
                            />
                        </TabPanel>
                    }
                    {obxuser?.assignedSecurityRights.includes(SecurityRights.Sanctions_SearchHistoryTab) &&
                        <TabPanel disabled={!selectedItem} header='Search History'>
                            <SanctionedItemSearchHistory
                                selectedItem={selectedItem}
								resultsMode={resultsMode}
                            />
                        </TabPanel>
                    }
                </TabView>
                {isIndividualSanctions && !isTabletOrMobile &&
                    <Button
                        className="close-button"
                        icon="iconoir-xmark icon--tiny"
                        onClick={() => setSelectedItem(null)}
                        text
                    />
                }
				{selectedItem && activInfoTab === ActiveDetailsTab.Details &&
					<footer className='sanction-details__footer'>
						<MarkAsCheckedButton data={selectedItem} label='Mark as checked' resultsMode={resultsMode} />
					</footer>
				}
			</aside>
		</main>
		{!selectedItem && <footer className='sanctions__footer'>
			{isTabletOrMobile && <ButtonDownloads />}
		</footer>}
    </>
}

export default SanctionsPage;