import {
  useState,
  useEffect,
  useRef,
  CSSProperties,
  SetStateAction,
  Dispatch,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useMediaQuery } from 'react-responsive';

import clsx from 'clsx';
import {
  DataTable,
  DataTableCellSelection,
  DataTableSelectionCellChangeEvent,
} from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import {
  GridColumnConfiguration,
  GridConfigurationType,
  ConfigurationTabs,
  GridConfigPanelEvents,
} from 'components/GridColumnConfiguration';
import {
  GridConfiguration,
  GridColumn,
} from 'components/OBXUser/Model/ProfileResult';
import { useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import BorealisBar from 'components/BorealisBar';
import Ribbon from 'components/Ribbons/Templates/Ribbon';

import { eventBus } from 'server/EventBus';
import { moveItem } from 'helpers/Utils/collections';

import {
  ArtisMarketStatus,
  ArtisPricesSignalRMessages,
} from 'modules/ArtisCharting/Models/Enums';
import {
  useProducts,
  useArtisPrices,
} from 'modules/ArtisCharting/Services/hooks';
import { ArtisPriceCell } from 'modules/ArtisCharting/Templates/PriceCell';
import { ArtisPriceHeader } from 'modules/ArtisCharting/Templates/PriceHeader';
import { EmptyState } from 'modules/ArtisCharting/Templates/EmptyState';

import {
  ProductColumnSelectItem,
  ProductColumnValueItem,
  ProductVisabilityFooter,
} from 'modules/ArtisCharting/Templates/ProductColumnSelectItem';

import type {
  ProductTenor,
  ActiveChartPrice,
  Prices,
  SignalRArtisProductPriceUpdate,
  SignalRPriceChangeSubscriptionUpdated,
  SignalRArtisMarketStatus,
} from 'modules/ArtisCharting/Models/ArtisPrices';
import type {
  ArtisPackage,
  ArtisProduct,
} from 'modules/ArtisCharting/Models/Packages';

import styles from './PriceGrid.module.scss';

type ComponentParams = {
  artispackage: ArtisPackage | null;
  setActivePrice: Dispatch<SetStateAction<ActiveChartPrice | null>>;
  onShowForwardChart: (product: ArtisProduct) => void;
};

type AdditionalProps = {
  tenors: string;
  order: { id: string; index: number }[];
};

type Groupingconfig = {
  rowGroupMode: 'subheader';
  groupRowsBy: string;
  rowGroupFooterTemplate: null;
  rowGroupHeaderTemplate: (p: ProductTenor) => JSX.Element;
};

export type PriceGridHandles = {
  deselect: () => void;
  getPrices: () => Prices | undefined;
};

export const PriceGrid = forwardRef<PriceGridHandles, ComponentParams>(
  (params: ComponentParams, ref) => {
    const { artispackage, setActivePrice, onShowForwardChart } = params;

    useImperativeHandle(ref, () => ({
      deselect: () => setSelectedCell(null),
      getPrices: () => prices,
    }));

    const dd = useRef<Dropdown>(null);

    const { products, isLoading: isProductsLoading } =
      useProducts(artispackage);
    const { getGridConfig } = useLoadUserSettings();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

    const [activeColumns, setActiveColumns] = useState<ArtisProduct[]>();
    const [allRenderedColumns, setAllRenderedColumns] =
      useState<GridColumn[]>();
    const [selectedColumn, setSelectedColumn] = useState<GridColumn>();
    const [numDisplayColumns, setNumDisplayedColumns] = useState<number>(0);
    const [selectedCell, setSelectedCell] = useState<DataTableCellSelection<
      ProductTenor[]
    > | null>(null);
    const [marketClosedRibbonVisible, setMarketClosedRibbonVisible] =
      useState<boolean>(false);

    const {
      prices,
      isLoading,
      changePricesCache,
      changePricesCacheOnSubscriptionChange,
    } = useArtisPrices(artispackage?.source, activeColumns);

    const [config, setConfig] = useState<GridConfiguration<AdditionalProps>>();
    const [groupingSettings, setGroupingSettings] =
      useState<Groupingconfig | null>(null);

    const configToProducts = (
      cols: string[],
      prods: ArtisProduct[]
    ): ArtisProduct[] => {
      if (!Array.isArray(cols)) {
        return [];
      }

      const fnc = (acc: ArtisProduct[], col: string): ArtisProduct[] => {
        const product = prods.find(p => p.id === col);

        /** we can only return a column if its found */
        return product ? [...acc, product] : acc;
      };

      return cols.reduce<ArtisProduct[]>(fnc, []);
    };

    useEffect(() => {
      const onProductPriceUpdate = (
        event: CustomEvent<SignalRArtisProductPriceUpdate>
      ) => {
        const [first] = event.detail.tenors;
        if (activeColumns?.map(p => p.id).includes(first.productId)) {
          /** SignalR message updates should mutate local SWR cache */
          changePricesCache(event.detail);
        }
      };

      const onPriceChangeSubscriptionUpdated = (
        event: CustomEvent<SignalRPriceChangeSubscriptionUpdated>
      ): void => {
        changePricesCacheOnSubscriptionChange(event.detail);
      };

      const onMarketStatusChange = (
        event: CustomEvent<SignalRArtisMarketStatus>
      ): void => {
        setMarketClosedRibbonVisible(
          event.detail.status !== ArtisMarketStatus.Open
        );
      };

      eventBus.on(
        ArtisPricesSignalRMessages.PRODUCT_PRICE_UPDATE,
        onProductPriceUpdate
      );

      eventBus.on(
        ArtisPricesSignalRMessages.PRICE_CHANGE_SUBSCRIPTION_UPDATED,
        onPriceChangeSubscriptionUpdated
      );

      eventBus.on(
        ArtisPricesSignalRMessages.MARKET_STATUS,
        onMarketStatusChange
      );

      return () => {
        eventBus.remove(
          ArtisPricesSignalRMessages.PRODUCT_PRICE_UPDATE,
          onProductPriceUpdate
        );
        eventBus.remove(
          ArtisPricesSignalRMessages.PRICE_CHANGE_SUBSCRIPTION_UPDATED,
          onPriceChangeSubscriptionUpdated
        );
        eventBus.remove(
          ArtisPricesSignalRMessages.MARKET_STATUS,
          onMarketStatusChange
        );
      };
      // eslint-disable-next-line
    }, [activeColumns]);

    useEffect(() => {
      if (!artispackage) return;

      setConfig(getGridConfig(`artis-${artispackage.source}`));

      /** if the config wants groupable row tenors... */
      setGroupingSettings(
        artispackage.groupable
          ? {
              rowGroupMode: 'subheader',
              groupRowsBy: 'type',
              rowGroupFooterTemplate: null,
              rowGroupHeaderTemplate: (d: ProductTenor) => <>{d.type ?? ''}</>,
            }
          : null
      );

      // eslint-disable-next-line
    }, [artispackage]);

    useEffect(() => {
      if (!products || !config) return;

      const sortedProducts = products.toSorted((a, b) => {
        const i1 =
          config.additional?.order?.findIndex(c => c.id === a.name) ?? -1;
        const i2 =
          config.additional?.order?.findIndex(c => c.id === b.name) ?? -1;

        return i1 - i2;
      });

      setActiveColumns(configToProducts(config.columns, sortedProducts));
      setAllRenderedColumns(sortedProducts);
    }, [products, config]);

    useEffect(() => {
      /** We'll only ever want this to run on mobile is there
				isn't a column already selected
		*/
      if (!activeColumns) return;

      if (isTabletOrMobile) {
        /**
         * When there is no currently active column we can default to the
         * first item in the activeColumns collection
         */
        setSelectedColumn(curr =>
          curr?.id && activeColumns.map(({ id }) => id).includes(curr.id)
            ? curr
            : activeColumns[0]
        );

        setNumDisplayedColumns(1);
        return;
      }

      setNumDisplayedColumns(activeColumns.length);

      if (!selectedCell) return;

      const { field } = selectedCell;
      const index = activeColumns.findIndex(c => c.id === field);

      setSelectedCell(curr => {
        if (!curr || index < 0) return null;
        /**
         * The index will change if a product is added/removed that is
         * BEFORE the currently selected item in the available products
         */
        // if new cellIndex and old cellIndex are equal return current value to avoid falling
        // in rendering loop ('Maximum update depth exceeded...')
        return curr.cellIndex === index + 1
          ? curr
          : { ...curr, cellIndex: index + 1 };
      });
    }, [activeColumns, isTabletOrMobile, selectedCell]);

    const onMoveToLeft = (product: ArtisProduct): void => {
      if (allRenderedColumns) {
        const index = allRenderedColumns.findIndex(i => i.id === product.id);
        const prevCheckedIndex = allRenderedColumns.findLastIndex(
          (i, idx) => idx < index && config?.columns.includes(i.name)
        );
        const newColumns = moveItem(
          allRenderedColumns,
          index,
          prevCheckedIndex,
          { placeBefore: false }
        );

        eventBus.dispatch(
          GridConfigPanelEvents.COLUMNS_ORDER_CHANGED,
          newColumns
        );
      }
    };

    const onMoveToRight = (product: ArtisProduct): void => {
      if (allRenderedColumns) {
        const index = allRenderedColumns.findIndex(i => i.id === product.id);
        const nextCheckedIndex = allRenderedColumns.findIndex(
          (i, idx) => idx > index && config?.columns.includes(i.name)
        );
        const newColumns = moveItem(
          allRenderedColumns,
          index,
          nextCheckedIndex,
          { placeBefore: false }
        );

        eventBus.dispatch(
          GridConfigPanelEvents.COLUMNS_ORDER_CHANGED,
          newColumns
        );
      }
    };

    const handleCellSelection = (
      e: DataTableCellSelection<ProductTenor[]> | null
    ) => {
      setSelectedCell(e);

      if (!e) {
        setActivePrice(e);
        return;
      }

      const { field: product } = e.column.props;
      const { tenorCode, index, tenorName } = e.rowData;
      const { /* id,  */ label, windows } = products?.find(
        p => p.id === product
      )!;

      setActivePrice({
        tenorCode,
        tenorName,
        artispackage,
        product,
        name: label,
        index,
        windows,
      });
    };

    /**
     * Only allow a cell to be selectable IF there is data
     * displayed inside
     */
    const isCellSelectable = (event: any) => {
      if (!event.data) return;

      const { field } = event.data;
      const { keys } = event.data.rowData ?? {};

      return keys?.includes(field);
    };

    const onDropDownFooterButtonClick = () => {
      if (!dd.current) return;

      dd.current.hide();
    };

    if (isProductsLoading) {
      return (
        <>
          <div className='no-background direction--column'>
            <div className={styles.mockHeader}>Configuring Products…</div>
            <div className='no-background position--relative'>
              <BorealisBar />
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        {artispackage && activeColumns && (
          <>
            {isTabletOrMobile && activeColumns?.length !== 0 && (
              <div className='margin--horizontal--small'>
                <Dropdown
                  ref={dd}
                  value={selectedColumn}
                  className='grow-to-fill'
                  options={activeColumns}
                  optionLabel='label'
                  itemTemplate={ProductColumnSelectItem}
                  valueTemplate={ProductColumnValueItem}
                  onChange={(ev: DropdownChangeEvent) => {
                    setSelectedColumn(ev.value);
                    //	reset any current active price/cell selection
                    handleCellSelection(null);
                  }}
                  panelFooterTemplate={
                    <ProductVisabilityFooter
                      callback={onDropDownFooterButtonClick}
                    />
                  }
                />
              </div>
            )}
            {marketClosedRibbonVisible && (
              <Ribbon
                containerClassName={styles.ribbonContainer}
                className={styles.ribbon}
                closable={false}
                icon={<></>}
                severity='error'
                text={
                  <>
                    <span>Markets currently closed:</span>
                    <span>Realtime price updates active Mon-Fri 8am-8pm</span>
                  </>
                }
              />
            )}
            <div className={styles.container}>
              <DataTable
                value={
                  activeColumns.length > 0
                    ? prices?.results.filter(r =>
                        isTabletOrMobile && selectedColumn
                          ? r.prices.hasOwnProperty(selectedColumn.id as string)
                          : r
                      )
                    : []
                }
                loading={isLoading}
                scrollable
                scrollHeight='flex'
                {...groupingSettings}
                emptyMessage={
                  <EmptyState
                    artispackage={artispackage.label}
                    type={prices?.error}
                  />
                }
                className={clsx(
                  'grow-to-fill row--no-hover-state grouping--no-footer overflow--hidden',
                  styles.grid
                )}
                tableStyle={
                  {
                    '--columns': `${numDisplayColumns}`,
                    '--maxCharacters': `${(prices?.maxlength ?? 8) + 4}ch`,
                  } as CSSProperties
                }
                cellSelection={true}
                selectionMode='single'
                selection={selectedCell!}
                isDataSelectable={isCellSelectable}
                onSelectionChange={(
                  e: DataTableSelectionCellChangeEvent<ProductTenor[]>
                ) => handleCellSelection(e.value)}
              >
                {activeColumns?.length !== 0 && (
                  <Column header='' field='tenorName.display' frozen={true} />
                )}
                {activeColumns
                  ?.filter(c =>
                    isTabletOrMobile ? c.id === selectedColumn?.id : c
                  )
                  .map((c, i, arr) => (
                    <Column
                      key={`price-${c.name}`}
                      header={() =>
                        ArtisPriceHeader({
                          moveToLeftDisabled: i === 0,
                          moveToRightDisabled: i === arr.length - 1,
                          product: c,
                          onShowForwardChart,
                          onMoveToLeft,
                          onMoveToRight,
                        })
                      }
                      headerClassName={styles.header}
                      field={c.name}
                      body={(d, c) => (d ? ArtisPriceCell(d, c) : <></>)}
                      bodyClassName='no--padding position--relative'
                    />
                  ))}
                {!isTabletOrMobile && (
                  <Column
                    body={<></>}
                    header={
                      <>
                        <Button
                          size='small'
                          text
                          icon='iconoir-grid-plus icon--small'
                          onClick={() => {
                            eventBus.dispatch(
                              GridConfigPanelEvents.PANEL_VISIBILITY_CHANGE,
                              { panel: GridConfigurationType.Column }
                            );
                          }}
                          className={clsx('no-background')}
                        >
                          Add Product
                        </Button>
                      </>
                    }
                  />
                )}
              </DataTable>
              {config && products && (
                <GridColumnConfiguration
                  config={config}
                  setConfig={setConfig}
                  heading='Select Products'
                  icon='iconoir-grid-plus'
                  propkey='id'
                  description='Select which products from this package you want to display'
                  searchFilter={products.length > 3}
                  allColumns={allRenderedColumns}
                />
              )}
            </div>
            {prices && (
              <footer className={clsx(styles.footer, 'no-background')}>
                Prices last updated{' '}
                {prices.asOf.master.toFormat('tt LLL dd yyyy')} (UTC)
              </footer>
            )}
            {!isTabletOrMobile && (
              <ConfigurationTabs
                className={clsx(
                  marketClosedRibbonVisible && styles.marketClosed
                )}
                type={GridConfigurationType.Column}
              />
            )}
          </>
        )}
      </>
    );
  }
);

export default PriceGrid;
