import axios from 'axios';
import useSWR, { MutatorCallback } from 'swr';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import { apiConversationMessageRequest, apiInitiateConversationRequest } from '../Models/apiRequest';
import {
  apiConversationMessageResponse,
  apiConversationResponse,
  apiInitialConversationMessageResponse
} from '../Models/apiResponse';
import {
  MarketAssistConversationMessageResponse,
  MarketAssistConversationResponse, MarketAssistInitialConversationMessageResponse
} from '../Models/MarketAssistResponse';

import { notNil, parsePropsToDateTime } from 'helpers/Utils/misc';

import type { ResponseWithError } from 'models/shared/error';

export const useGetConversation = (id?: string | null): {
  conversationData: MarketAssistConversationResponse | null | undefined,
  conversationError: ResponseWithError | undefined,
  conversationIsLoading: boolean,
  conversationMutate: MutatorCallback<MarketAssistConversationResponse | null>,
} => {

  const shouldFetch = notNil(id) && id !== '';

  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch ? `Conversation/${ id }` : null,
    () => MarketAssistApiService.getConversation(id ?? undefined),
    { revalidateOnFocus: false }
  );

  return {
    conversationData: data,
    conversationError: error,
    conversationIsLoading: isLoading,
    conversationMutate: mutate,
  };
};

export class MarketAssistApiService
{
  static askInConversation = (params: apiConversationMessageRequest, id: string): Promise<MarketAssistConversationMessageResponse | null> =>
    axios<apiConversationMessageResponse>({
      url: `openai/conversation/${ id }`,
      data: params,
      method: 'POST'
    })
      .then(r => parsePropsToDateTime<MarketAssistConversationMessageResponse>(r.data, ['dateUtc']))
      .catch(() => null);

  static getConversation = (id?: string): Promise<MarketAssistConversationResponse | null> =>
    axios<apiConversationResponse>({
      url: `openai/conversation/${ id }`,
      method: 'GET'
    })
      .then(r => ({
        ...r.data,
        messages: r.data.messages?.map(
          message =>
            parsePropsToDateTime<MarketAssistConversationMessageResponse>(message, ['dateUtc']))
      }))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 403]);

        throw e; // Error handling will be inside MarketAssist.tsx
      });

  static startConversation = (params: apiInitiateConversationRequest): Promise<MarketAssistInitialConversationMessageResponse | null> =>
    axios<apiInitialConversationMessageResponse>({
      url: 'openai/conversation',
      data: params,
      method: 'POST'
    })
      .then(r => ({
        ...r.data,
        message: parsePropsToDateTime<MarketAssistConversationMessageResponse>(r.data.message, ['dateUtc'])
      }))
      .catch(() => null);
}