import type { ColumnSortEvent } from 'primereact/column';
import type F from 'types/generic-type';

type RecordOrStringArr = F<({ [key: string]: string; } | string)[]>;

export const sortByCustomFieldInArray = (event: ColumnSortEvent, sortByField: string, key = 'value'): RecordOrStringArr[] => {

    const { data, order } = event;

    return data.sort((a: RecordOrStringArr, b: RecordOrStringArr): number => {
        const first = typeof a[sortByField]?.[0] === 'string' ? a[sortByField][0] : (a[sortByField]?.[0]?.[key] ?? '');
        const second = typeof b[sortByField]?.[0] === 'string' ? b[sortByField][0] : (b[sortByField]?.[0]?.[key] ?? '');

        // empty strings goes to the end of the list
        if (!first) {
            return 1;
        } else if (!second) {
            return -1;
        }

        return first.localeCompare(second) * (order as number);
    });
};