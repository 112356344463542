import { additionalSearchPropParser, fieldsParser, hydrator } from 'components/Worksheets/Models/Parsers';

import type { BlotterSearchField } from './SearchRequest';
import type { BlotterEntitySearchFieldsEnum } from './Enums';
import type { TradeMultipleQuantities, TradeMultipleQuantitiesStringified } from './BlotterResponse';

export const tradeSearchWorksheetParsers = {
  propsParser: additionalSearchPropParser,
  fieldsParser,
  hydrator,
};

export const searchRequestItemFactory = <T, >(fields: BlotterSearchField[], type: BlotterEntitySearchFieldsEnum): T[] => {
	return fields.filter(({searchField}) => searchField === type) as T[];
}

export const stringifyQuantityAmounts = (quantity: TradeMultipleQuantities): TradeMultipleQuantitiesStringified => {
  const { amounts } = quantity;

  if (!amounts) {
    return { ...quantity, amounts: '' };
  }

  if (Array.isArray(amounts) && amounts.some((amount, _index, arr) => amount !== arr[0])) {
    return { ...quantity, amounts: amounts.join('\/') };
  }

  return { ...quantity, amounts: `${amounts[0]}` };
};