import { ReactElement, RefObject } from 'react';

import { MessageRole } from '../../Models/Enums';
import { MarketAssistConversationMessageResponse } from '../../Models/MarketAssistResponse';

import ConversationAnswer from './ConversationAnswer';

import './Conversation.scss';

interface ConversationProps {
  messages: MarketAssistConversationMessageResponse[];
  messagesEndRef: RefObject<HTMLDivElement>;
  isLoadingAnswer: boolean;
  conversationIsLoading: boolean;
}

const Conversation = (props: ConversationProps): ReactElement => {
  const { messages, messagesEndRef, isLoadingAnswer, conversationIsLoading } = props;

  // Set ref to last message in conversation (last index)
  return (
    <div className='ma-conversation'>
      <div className='ma-conversation-content'>
        {messages.map((message, index) =>
          message.role === MessageRole.User ?
            <div
              ref={index === messages.length - 1 ? messagesEndRef : undefined}
              className='question'
              key={`question-${ message.id || crypto.randomUUID() }`}
            >
              {message.content}
            </div> :
            <ConversationAnswer
              ref={index === messages.length - 1 ? messagesEndRef : undefined}
              content={message.content}
              messageId={message.id}
              key={`answer-${ message.id }`}
            />
        )}
        {(isLoadingAnswer || conversationIsLoading) && <ConversationAnswer
          content={isLoadingAnswer ? 'Analyzing' : 'Loading history'}
          key={'loading'}
          isLoading={true}
        />}
      </div>
    </div>
  );
};

export default Conversation;