import { ReactNode, RefObject, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { Accordion, AccordionTab } from 'primereact/accordion';

import NotAvailable from 'components/NotAvailable';
import { ToastMessageRef } from 'components/ToastMessage';

import { SurveillanceModeEnum } from '../../Models/Enums';
import {
  EscalationResult,
  ResultsResponse,
} from '../../Models/ReportsResponse';
import Results from '../Results';
import { RightPanel } from '../RightPanel';
import { PanelState, type SelectedContentMessage } from '../RightPanel/Models';
import { isMessagesEqual } from '../RightPanel/Models/Helpers';

import './SupervisorView.scss';

interface SupervisorViewProps {
  toastRef?: RefObject<ToastMessageRef>;
  escalated?: EscalationResult | null;
}

const SupervisorView = ({
  toastRef,
  escalated,
}: SupervisorViewProps): ReactNode => {
  const [resultSelected, setResultSelected] = useState<ResultsResponse | null>(
    null
  );
  const [detailsPanelState, setDetailsPanelState] = useState<PanelState>(
    PanelState.collapsed
  );
  const [contentPanelState, setContentPanelState] = useState<PanelState>(
    PanelState.collapsed
  );
  const [selectedMessages, setSelectedMessages] = useState<ResultsResponse[]>(
    []
  );
  const [selectedContentMessages, setSelectedContentMessages] = useState<
    SelectedContentMessage[]
  >([]);

  useEffect(() => {
    setSelectedContentMessages(items => [
      ...items.filter(
        i =>
          selectedMessages.find(m => isMessagesEqual(i, m)) ||
          (i.parent &&
            selectedMessages.find(
              m => i.parent && isMessagesEqual(i.parent, m)
            ))
      ),
    ]);
  }, [selectedMessages]);

  return (
    <div className='supervisor-view-container module__canvas'>
      {Boolean(escalated) && (
        <Accordion className='supervisor-view-container__note-panel'>
          <AccordionTab
            header={escalated?.subject || <NotAvailable label='Empty' />}
            className='direction--column'
          >
            <dt>Note</dt>
            <dd>{escalated?.note || <NotAvailable label='Empty' />}</dd>
          </AccordionTab>
        </Accordion>
      )}
      <main
        className={clsx('grow-to-fill', {
          'drawer--active': Boolean(resultSelected),
        })}
        data-cols={
          Boolean(resultSelected) &&
          (contentPanelState === PanelState.expanded ||
          detailsPanelState === PanelState.expanded
            ? '4,8'
            : '9,3')
        }
        data-drawer-style={Boolean(resultSelected) && 'slide'}
        data-drawer-position={Boolean(resultSelected) && 'alongside-right'}
      >
        <section className='grow-to-fill overflow--hidden'>
          <Results
            selectedMessages={selectedMessages}
            setSelectedMessages={setSelectedMessages}
            resultSelected={resultSelected}
            isLoadingWorksheet={false}
            setResultSelected={setResultSelected}
            isCompressedView={false}
            selectedSiblingMessages={selectedContentMessages.filter(m =>
              Boolean(m.parent)
            )}
            activeWorksheetId={escalated?.worksheetId}
            toastRef={toastRef}
            resultsMode={SurveillanceModeEnum.Escalated}
            isFooterVisible={false}
            preloadedResults={escalated?.items}
          />
        </section>
        {resultSelected && (
          <RightPanel
            activeWorksheetId={escalated?.worksheetId}
            record={resultSelected}
            setRecordSelected={setResultSelected}
            selectedMessages={selectedMessages}
            setSelectedMessages={setSelectedMessages}
            selectedContentMessages={selectedContentMessages}
            setSelectedContentMessages={setSelectedContentMessages}
            contentPanelState={contentPanelState}
            setContentPanelState={setContentPanelState}
            detailsPanelState={detailsPanelState}
            setDetailsPanelState={setDetailsPanelState}
            resultsMode={SurveillanceModeEnum.Escalated}
          />
        )}
      </main>
    </div>
  );
};

export default SupervisorView;
