import axios from 'axios';
import Joi from 'joi';
import useSWR, { MutatorCallback, State, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import { DEFAULT_SEARCH_ITEMS } from '../Components/SingleSearch';
import { isSearchRequestEmpty } from '../Models/Parsers';

import { replaceItemAt } from 'helpers/Utils/collections';
import { parsePropsToDateTime } from 'helpers/Utils/misc';

import type { ResponseError } from 'models/shared/error';
import type {
  apiDistributionListUpdateRequest,
  apiSearchSecureEmailRequest,
  apiSearchSingleEmailRequest
} from '../Models/apiRequest';
import type {
  apiDistributionListResponse,
  apiDistributionListSummary,
  apiSecureMailRecipientsResponse,
  apiSingleMailEmailDetails,
  apiSingleMailEmailResponse
} from '../Models/apiResponse';
import type {
  CreateSingleParams,
  DistributionListCreateRequest,
  GetRecipientsParams,
  ResendParams
} from '../Models/distribution-list-create-request';
import type {
  CreateSingleResponse,
  DistributionList,
  DistributionListMessagesSearchResponse,
  DistributionListResponseSingle,
  DistributionListSummary,
  SingleMailEmailResponse,
} from '../Models/distribution-list-response';
import type { AxiosError, AxiosResponse } from 'axios';

export const DIST_LISTS_CACHE: string = 'DistributionLists';
export const SINGLE_MESSAGES_CACHE: string = 'SingleMessages';

const searchValidator = Joi.object({
  distributionListId: Joi.string().min(1),
  subjectSearch: Joi.string().min(1)
}).or('subjectSearch', 'distributionListId');

export const useGetDistLists = (shouldFetch: boolean): {
  loadDistlistData: DistributionListSummary[] | undefined,
    loadDistlistError: Error | undefined,
    loadDistlistIsLoading: boolean } => {

  const { data, error, isLoading} = useSWR(
    shouldFetch ? DIST_LISTS_CACHE : null,
    () => DistListApiService.GetDistLists(),
    { revalidateOnFocus: false }
  );

  return { loadDistlistData : data, loadDistlistError : error, loadDistlistIsLoading:  isLoading};
};

export const useSearchMessages = (dlsr: apiSearchSecureEmailRequest):
  { searchResults: DistributionListMessagesSearchResponse[] | undefined,
    searchError: ResponseError,
    searchIsLoading: boolean,
    searchMutate: MutatorCallback<DistributionListMessagesSearchResponse[]> } => {

  const shouldFetch = searchValidator.validate(dlsr);

  const { data, error, isLoading, mutate } = useSWR(
    !shouldFetch.error ? dlsr : null,
    DistListApiService.SearchMessages,
    { revalidateOnFocus: false }
  );

  return {
    searchResults: data,
    searchError: error,
    searchIsLoading: isLoading,
    searchMutate: mutate,
  };
};

export const useSearchSingleMessages = (dlsser: apiSearchSingleEmailRequest | null):
  {
    searchResults: SingleMailEmailResponse[] | undefined,
    searchError: ResponseError,
    searchIsLoading: boolean,
    searchMutate: MutatorCallback<SingleMailEmailResponse[]>,
    searchValidating: boolean } => {

  let arg: apiSearchSingleEmailRequest | null | string = dlsser;
  let csrData: apiSearchSingleEmailRequest = dlsser ?? DEFAULT_SEARCH_ITEMS;

  if (arg !== null && isSearchRequestEmpty(arg)) {
    arg = SINGLE_MESSAGES_CACHE;
    csrData = DEFAULT_SEARCH_ITEMS;
  }


  const { data, error, isLoading, mutate, isValidating } = useSWR(
    arg,
    () => DistListApiService.SearchSingleMessages(csrData),
    { revalidateOnFocus: false }
  );

  return {
    searchResults: data,
    searchError: error,
    searchIsLoading: isLoading,
    searchMutate: mutate,
    searchValidating: isValidating,
  };
};

export const useGetSingleMessageDetails = (emailId?: string | null): {
  details: apiSingleMailEmailDetails | undefined;
  detailsError: ResponseError;
  detailsIsLoading: boolean;
  detailsIsValidating: boolean;
} => {

  const { data, error, isLoading, isValidating } = useSWR(
    emailId ? `get-single-message-details-${emailId}` : null,
    () => DistListApiService.GetSingleMessageDetails(emailId!),
    { revalidateOnFocus: false }
  );

  return {
    details: data,
    detailsError: error,
    detailsIsLoading: isLoading,
    detailsIsValidating: isValidating
  };
};

export const useGetDistListById = (id?: string): {
  data: DistributionList | undefined,
  error: Error | undefined,
  isLoading: boolean } => {
  const shouldFetch = id !== undefined;
  const { data, error, isLoading} = useSWR(
    shouldFetch ? `DistributionLists/${ id }` : null,
    () => DistListApiService.GetDistListById(id),
    { revalidateOnFocus: false }
  );

  return { data, error, isLoading};
};

export const useCreateUpdateDistList = (): {
  data: DistributionListResponseSingle | undefined;
  trigger: (payload: apiDistributionListUpdateRequest) => Promise<DistributionList>;
  isMutating: boolean;
} => {
  const { mutate } = useSWR(DIST_LISTS_CACHE);
  const { cache } = useSWRConfig();

  const { trigger, data, isMutating } = useSWRMutation(
    DIST_LISTS_CACHE,
    DistListApiService.CreateUpdateDistList,
    {
      onSuccess: c => {
        //	Mutate the cache with new or updated data
        const { data } = cache.get(DIST_LISTS_CACHE) as State<DistributionList[]>;

        if (!data || !c) {
          return;
        }

        const index: number = data.findIndex(i => i.id === c.id);
        const parsedItem = parsePropsToDateTime<DistributionList>(c, ['lastEmailDate', 'lastModified']);

        if (index === -1) { // New
          mutate([parsedItem, ...data], { revalidate: false });
        } else { // Updated
          mutate(replaceItemAt(data, parsedItem, index), { revalidate: false });
        }
      }
    }
  );

  return { trigger, data, isMutating };
};

export const useGetRecipients = (): {
  trigger: (arg: GetRecipientsParams) => Promise<apiSecureMailRecipientsResponse>;
  error: AxiosError;
  isMutating: boolean; } => {

  const { trigger, error, isMutating } = useSWRMutation(
    'dist-list-emial-reciepients',
    DistListApiService.GetEmailRecipients,
    { revalidate: false }
  );

  return { trigger, error, isMutating };
};

export class DistListApiService
{
  static CreateUpdateDistList = (url: string, params: { arg: apiDistributionListUpdateRequest; }): Promise<DistributionList> => {
    const data = params.arg;
    const method: string = 'PUT';

    return axios.request<apiDistributionListResponse, AxiosResponse<DistributionList>>({
      url: 'DistributionLists',
      method: method,
      data: data
    })
      .then(r => parsePropsToDateTime<DistributionList>(r.data, ['lastEmailDate', 'lastModified']))
      .catch(e => {
        console.error('Error', e);
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
        throw (e);
      });
  };

  // Not used for now, for future use if we want to save only recipients
  static UpdateDistListRecipients = (url: string, params: { arg: { id: string, recipients: string[] }}): Promise<DistributionList | void> => {
    const data = params.arg;
    const method: string = 'PATCH';

    return axios.request({
      url: `DistributionLists/${ data.id }/Recipients`,
      method: method,
      data: data.recipients
    })
      .then(r => r?.data as DistributionList)
      .catch(e => {
        console.error('Error', e);
        ErrorToastService.handleError(e, [400, 403, 500, 503]);
        return;
      });
  };

  static GetDistLists = (): Promise<DistributionListSummary[]> =>
    axios.request<apiDistributionListSummary, AxiosResponse<apiDistributionListSummary[]>>({
      url: 'DistributionLists',
      method: 'GET'
    })
      .then(r => r.data.map(el => parsePropsToDateTime<DistributionListSummary>(el, ['lastEmailDate'])))
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);
        throw e;
      });

  static GetDistListById = (id?: string): Promise<DistributionList> => axios.request<any, AxiosResponse<DistributionList>>({
    url: `DistributionLists/${ id }`,
    method: 'GET'
  })
    .then(r => r.data)
    .catch(e => {
      ErrorToastService.handleError(e, [500, 503]);
      throw e;
    });

  static SearchMessages = (data: apiSearchSecureEmailRequest) => axios.request<any, AxiosResponse<DistributionListMessagesSearchResponse[]>>({
    url: `mail/distributionlists/${ data.distributionListId }/email/search`,
    data,
    method: 'POST'
  })
    .then(r => r.data.map(el => parsePropsToDateTime<DistributionListMessagesSearchResponse>(el, ['messageDate'])))
    .catch(e => {
      ErrorToastService.handleError(e, [400, 500, 503]);

      throw e;
    });

  static Resend = (params: ResendParams): Promise<ResendParams | null> =>
    axios<ResendParams>({
      url: 'mail/email/resend',
      data: params,
      method: 'POST'
    })
      .then(() => params)
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);
        return null;
      });

  static CreateSingle = (params: CreateSingleParams): Promise<CreateSingleResponse | null> =>
    axios<CreateSingleResponse>({
      url: 'mail/email',
      data: params,
      method: 'POST'
    })
      .then(r => parsePropsToDateTime<CreateSingleResponse>(r.data, ['messageDate']))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);
        return null;
      });

  static SearchSingleMessages = (data: apiSearchSingleEmailRequest): Promise<SingleMailEmailResponse[]> =>
    axios.request<null, AxiosResponse<apiSingleMailEmailResponse[]>>({
      url: 'mail/email/search',
      data,
      method: 'POST'
    })
      .then(r => r.data.map(el => parsePropsToDateTime<SingleMailEmailResponse>(el, ['messageDate'])))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);

        throw e;
      });

  static GetSingleMessageDetails = (emailId: string): Promise<apiSingleMailEmailDetails> =>
    axios.get<apiSingleMailEmailDetails>(`mail/email/${emailId}`)
      .then(r => parsePropsToDateTime<apiSingleMailEmailDetails>(r.data, ['messageDate']))
      .catch(e => {
        ErrorToastService.handleError(e, [400, 500, 503]);

        throw e;
      });

  static GetEmailRecipients = async (url: string, params: { arg: GetRecipientsParams; }): Promise<apiSecureMailRecipientsResponse> => {
    const { distributionListId, id } = params.arg;
    return axios.request<GetRecipientsParams, AxiosResponse<apiSecureMailRecipientsResponse[]>>({
      url: `mail/distributionlists/${ distributionListId }/email/${ id }`,
      method: 'GET'
    })
      .then(results => parsePropsToDateTime<apiSecureMailRecipientsResponse>(results.data, ['messageDate']))
      .catch(e => {
        throw e;
      });
  };

  static EmptyRequest = (): DistributionListCreateRequest => ({
    id: undefined,
    name: '',
    linkedCldd: '',
    recipients: [],
    comments: ''
  });
}