import useSWR, { Key, State, mutate, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import axios, { AxiosResponse, type AxiosError } from 'axios';
import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';

import { parsePropsToDateTime } from 'helpers/Utils/misc';

import { DEFAULT_PRICE, QUANTITY_UNITS } from '../Models/Consts';
import { TradeType } from '../Models/Enums';

import { apiSearchRequest, apiSearchResponse } from '../Models';

import type {
  ExportTradesPayload,
  TradeDetailsRequest,
  TradeDetailsResponse,
  TradeMultipleQuantities,
  TradesDataResponse,
  TradeSide,
} from '../Models/BlotterResponse';

export class BlotterDataApi {
  static getTradesData = async (arg: apiSearchRequest) => {
    return axios
      .request<apiSearchRequest, AxiosResponse<apiSearchResponse>>({
        method: 'POST',
        url: '/blotter/trades/search',
        data: arg,
      })
      .then(({ data }) => ({
        ...data,
        results: data.results?.map(d =>
          parsePropsToDateTime<TradesDataResponse>(d, ['dateTime'])
        ),
      }))
      .catch(e => {
        // TODO: handle if needed
        throw e;
      });
  };

  static getTradeDetails = async (tradeId: string) => {
    return axios
      .get<any, AxiosResponse<TradeDetailsResponse>>(
        `/blotter/trades/${tradeId}`
      )
      .then(res =>
        parsePropsToDateTime<TradeDetailsResponse>(res.data, ['dateTime'])
      )
      .catch(e => {
        // TODO: handle if needed
        throw e;
      });
  };

  static saveTrade = async (
    url: string,
    params: { arg: TradeDetailsRequest }
  ): Promise<TradeDetailsResponse> => {
    return axios
      .request<TradeDetailsRequest, AxiosResponse<TradeDetailsResponse>>({
        method: params.arg.id ? 'PUT' : 'POST',
        data: params.arg,
        url: '/blotter/trades',
      })
      .then(res => parsePropsToDateTime(res.data, ['dateTime']))
      .catch(e => {
        throw e;
      });
  };

  static deleteTrade = async (
    url: string,
    params: { arg: string }
  ): Promise<string> => {
    return axios
      .request<TradeDetailsRequest, string>({
        method: 'DELETE',
        url: `/blotter/trades/${params.arg}`,
      })
      .then(() => params.arg)
      .catch(e => {
        throw e;
      });
  };

  static exportTrades = async (
    url: string,
    params: { arg: ExportTradesPayload }
  ) => {
    return axios
      .request<ExportTradesPayload, { data: Blob }>({
        method: 'POST',
        url: 'blotter/trades/export',
        data: params.arg,
        responseType: 'blob',
      })
      .then(({ data }) => {
        fileDownload(
          data,
          `Trades ${DateTime.now().toISODate()}.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        return data;
      })
      .catch(e => {
        throw e;
      });
  };

  static mapItemToTradeGridData = (data: TradeDetailsResponse): TradesDataResponse => {
    const { clearing, dateTime, id, instrument, isImported, legs } = data;
    const [{ buyer, seller, quantity, price }] = legs;
    let amounts: TradeMultipleQuantities['amounts'];

    if (legs.length === 1) {
      amounts = typeof quantity.amount === 'number' ? [quantity.amount] : null;
    } else {
      amounts = legs.map(l => l.quantity.amount!);
    }

    return {
      id,
      instrument,
      type: legs.length === 1 ? TradeType.Outright : TradeType.Spread,
      dateTime: dateTime as DateTime,
      buyerCompany: buyer.company,
      buyerContactName: buyer.contactName,
      buyerObBroker: buyer.obBroker,
      sellerCompany: seller.company,
      sellerContactName: seller.contactName,
      sellerObBroker: seller.obBroker,
      quantity: { unit: quantity.unit, amounts },
      price,
      clearingId: clearing.id ?? '',
      isImported
    };
  }

  private static get counterparty(): TradeSide {
    return {
      company: '',
      contactName: '',
      obBroker: {
        userId: '',
        userName: '',
      },
      paysBrokerage: false,
      tradingAccount: '',
    };
  }

  static createEmptyTrade = (): TradeDetailsRequest => ({
    id: null,
    instrument: '',
    type: TradeType.Outright,
    legs: [{
      number: 1,
      buyer: this.counterparty,
      seller: this.counterparty,
      price: DEFAULT_PRICE,
      quantity: {
        amount: null,
        unit: QUANTITY_UNITS[0]
      },
    }],
    dateTime: null,
    clearing: {
      cleared: false,
      house: 'ICEB', // TODO: for now hardcode this one
      id: '',
    },
    comments: '',
    isImported: false,
    nextDayPriced: false,
  });
}
