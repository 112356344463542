import { ReactNode, useEffect, useRef, useState } from 'react';
import { ColumnBodyOptions } from 'primereact/column';

import {
  InputContexts,
  LimitedLenghtInput,
} from 'components/LimitedLenghtInput';

import {
  WorksheetSignalMessageEventTypes,
  WorksheetStores,
} from '../Models/Enums';
import { WorksheetResponse } from '../Models/WorksheetResponse';
import { useRenameWorksheet } from '../Services/WorksheetHooks';

import { useDoubleClick } from 'helpers/Hooks/DoubleClick';

import eventBus from 'server/EventBus';

import styles from './WorksheetTemplates.module.scss';

interface IWorksheetCellParam {
  data: WorksheetResponse;
  config: ColumnBodyOptions;
  handler: (id: string, name?: string) => void;
  store: WorksheetStores;
  getSubtitle?: (data: WorksheetResponse) => ReactNode;
}

export const WorkSheetName = (props: IWorksheetCellParam): JSX.Element => {
  const { data, handler, store, getSubtitle } = props;

  const { name: currentname, worksheetId } = data;

  const container = useRef(null);
  const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
  const { renameSheet } = useRenameWorksheet(store);

  useDoubleClick({
    ref: container,
    onDoubleClick: e => setIsEditingMode(true),
    onSingleClick: e => !isEditingMode && handler(data.worksheetId, data.name),
  });

  const processNameChange = async (newname: string | boolean) => {
    //	we only want to trigger a renaming of the worksheet IF its
    //	acutally changed
    if (currentname && newname && currentname !== newname) {
      await renameSheet({ worksheetId, store, name: newname as string });
    }

    setIsEditingMode(false);
  };

  useEffect(() => {
    const onWorksheetRename = (
      payload: CustomEvent<Partial<WorksheetResponse>>
    ): void => {
      if (worksheetId === payload.detail.worksheetId) {
        setIsEditingMode(true);
      }
    };

    eventBus.on(
      WorksheetSignalMessageEventTypes.WORKSHEET_START_NAME_CHANGE,
      onWorksheetRename
    );

    return () => {
      eventBus.remove(
        WorksheetSignalMessageEventTypes.WORKSHEET_START_NAME_CHANGE,
        onWorksheetRename
      );
    };
  }, [worksheetId]);

  return <div className={styles.cell} ref={container}>
    { isEditingMode
      ? <LimitedLenghtInput
        currentValue={ currentname }
        callback={ processNameChange }
        context={ InputContexts.Cell }
        showCounter={true}
        preventInputOverflow={true}
        showError={false}
      />
      :
      <>
        <span>{ currentname }</span>
        {getSubtitle && <span>{ getSubtitle(data) }</span>}
      </>
    }

  </div>
}
