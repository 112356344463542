import { Dispatch, SetStateAction } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { CargoTrackerResponse } from '../../Models/CargoTrackerResponse';
import { CargoTrackerModeEnum } from 'modules/CargoTracker/Models/Enums';

import CargoSearch from './CargoSearch';

interface CargoSearchMobileProps {
  setSearchData: Dispatch<SetStateAction<CargoTrackerResponse[] | undefined>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  activeWorksheet: string;
  addCargoAction: () => void;
  rightColumnState: boolean;
  resultsMode: CargoTrackerModeEnum;
}

const CargoSearchMobile = (props: CargoSearchMobileProps): JSX.Element => {
  const {
    setSearchData,
    setIsLoading,
    activeWorksheet,
    addCargoAction,
    rightColumnState,
    resultsMode,
  } = props;

  return (
    <Accordion
      activeIndex={0}
      className='cargo-tracker-search-mobile__accordion'
    >
      <AccordionTab
        className='cargo-tracker-search-mobile__accordion-tab'
        headerClassName='cargo-tracker-search-mobile__accordion-header'
        header='Search'
      >
        <div className='cargo-tracker-search-mobile__container'>
          <CargoSearch
            key='search-mobile'
            setSearchData={setSearchData}
            setIsLoading={setIsLoading}
            activeWorksheet={activeWorksheet}
            addCargoAction={addCargoAction}
            rightColumnState={rightColumnState}
            resultsMode={resultsMode}
          />
        </div>
      </AccordionTab>
    </Accordion>
  );
};

export default CargoSearchMobile;
