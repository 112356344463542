import axios from 'axios';
import { DateTime, Duration } from 'luxon';
import useSWR from 'swr';

import ErrorToastService from 'components/Errors/ErrorToast/Services/ErrorToastService';

export interface ParssedDateTimeResult {
  original: string;
  from: DateTime;
  to: DateTime;
  fromString: string;
  toString: string;
  duration: Duration;
}

export const useDateStringConvert = (value: string | null | undefined) => {
  const { data, error } = useSWR(value, ConvertStringApi.getParsedDate);

  return { data, error };
};

export class ConvertStringApi {
  static getParsedDate = (
    value: string,
    keepLocal?: boolean,
    limitFutureDate?: boolean,
  ): Promise<{ results: ParssedDateTimeResult }> => {
    const punctuationless = value
      .replace(/[^\w\s\-+.:<>]/g, '')
      .replace(/\s+/g, ' ');

    return axios
      .request({
        url: 'search/parse/date',
        data: { value: punctuationless, limitFutureDate },
        method: 'POST',
      })
      .then(r => ({
        results: ConvertStringApi.parseDateTime(r.data, keepLocal),
      }))
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);

        throw e;
      });
  };

  static parseDateTime = (
    date: { original: string; from: string; to: string },
    keepLocal?: boolean
  ): ParssedDateTimeResult => {
    const { from, to, original } = date;

    const fromOffset = keepLocal ? DateTime.fromISO(from).offset : 0; // offset between local and UTC time in minutes
    const toOffset = keepLocal ? DateTime.fromISO(to).offset : 0; // offset between local and UTC time in minutes
    // subtract offset because parsed date is in the UTC and we want to have it as local time (in `keepLocal` is true)
    const f: DateTime = DateTime.fromISO(from, { zone: 'utc' }).minus({
      minutes: fromOffset,
    });
    const t: DateTime = DateTime.fromISO(to, { zone: 'utc' }).minus({
      minutes: toOffset,
    });

    return {
      original,
      fromString: f.toString(),
      toString: t.toString(),
      from: f,
      to: t,
      duration: t
        .plus({ minutes: 1 })
        .diff(f, [
          'years',
          'quarters',
          'months',
          'days',
          'hours',
          'minutes',
          'seconds',
        ]),
    };
  };
}
